var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dataalertscards"
  }, [_vm.fetchRecentAlertsError ? _c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchRecentAlertsError]
    }
  }) : _vm.fetchingRecentAlerts ? _c('v-row', {
    staticClass: "ma-0"
  }, _vm._l(4, function (index) {
    return _c('v-col', {
      key: `dataalertscards--loading--${index}`,
      staticClass: "pa-0 dataalertscards--cell px-2"
    }, [_c('v-skeleton-loader', {
      staticClass: "elevation-0 dataalertscards--loader",
      attrs: {
        "type": "list-item, list-item-avatar-three-line, card-heading",
        "height": "144"
      }
    })], 1);
  }), 1) : _c('v-row', {
    staticClass: "ma-0"
  }, _vm._l(_vm.recentAlerts, function (alert, i) {
    return _c('v-col', {
      key: `dataalertscards--item-key-${i}`,
      staticClass: "pa-0 dataalertscards--cell px-2",
      attrs: {
        "cols": "3"
      }
    }, [_c('r-alert-card', {
      staticClass: "dataalertscards--card",
      attrs: {
        "type": alert.type,
        "date": alert.created,
        "data": alert.alertData,
        "alert-id": alert._id,
        "show-hide-icon": true
      }
    })], 1);
  }), 1), _vm.noData ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-listing-results')], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }