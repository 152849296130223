<template>
  <v-card
    class="rsearcherroralertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="rsearcherroralertcard--header d-flex py-1">
        <v-icon class="rsearcherroralertcard--icon">
          mdi-alert-circle
        </v-icon>
        <span class="rsearcherroralertcard--title text-truncate text-h6 ml-2">
          {{ $t('alerts.searchError.title') }}
        </span>
      </div>

      <div class="rsearcherroralertcard--text d-flex">
        <span>
          {{ $t('alerts.searchError.message', { searchName }) }}
        </span>
      </div>

      <div class="rsearcherroralertcard--link mt-2 d-flex">
        <a @click="onViewResultClick">{{ $t('alerts.viewResults') }}</a>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />
      <v-divider class="my-3" />

      <div class="rsearcherroralertcard--footer d-flex justify-space-between">
        <span class="date-title">{{ $t('alerts.date') }}</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RSearchErrorAlertCard',
  components: { MarkAsSeen },
  props: {
    date: {
      type: String,
      required: true,
    },
    searchId: {
      type: String,
      required: false,
    },
    searchName: {
      type: String,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onViewResultClick() {
      this.$router.push(`/data/search/${this.searchId}/results`)
    },
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.rsearcherroralertcard--text {
  color: var(--r-carbon-color);
}
.rsearcherroralertcard--link a {
  text-decoration: none;
}

.rsearcherroralertcard--title,
.rsearcherroralertcard--icon {
  color: var(--r-error-color);
}

.rsearcherroralertcard--icon {
  font-size: 1.5rem !important;
}

</style>
