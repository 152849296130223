var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('r-modal', {
    staticClass: "createbcrlink",
    attrs: {
      "title": _vm.$t('bcrLinks.create.title')
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('r-error-message', {
          staticClass: "mb-3",
          attrs: {
            "errors": _vm.errors
          }
        }), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('bcrLinks.create.message', {
          dataType: _vm.dataType
        })) + " ")])]), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('bcrLinks.create.postLinkMessage')) + " ")])])];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function () {
        return [_c('r-button', {
          staticClass: "close-btn mr-1",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.cancel')
          },
          on: {
            "click": _vm.onClickClose
          }
        }), _c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "label": _vm.$t('global.actions.connect'),
            "loading": _vm.isCreateLoading
          },
          on: {
            "click": _vm.onClickConnect
          }
        })];
      },
      proxy: true
    }], null, false, 3815750326)
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }