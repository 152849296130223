const SELECTORS = {
  ROW: '.rtable--row',
  CELL: '.rtable--cell',
}

function getFirstRowIndex({
  bodyNode,
  rowHeight,
  scrollableAreaHeight,
  scrollableCanvasHeight,
  numRows,
  maxVisibleRows,
  firstRowIndex,
  numAdditionalRows,
}) {
  const { scrollTop } = bodyNode

  let newIndex
  if (scrollTop > scrollableCanvasHeight - scrollableAreaHeight) {
    newIndex = numRows - maxVisibleRows
  } else {
    newIndex = Math.round(scrollTop / rowHeight)
  }

  if (Math.abs(firstRowIndex - newIndex) > (numAdditionalRows / 2)) {
    return newIndex
  }

  return firstRowIndex
}

function getSizes({
  wrapperNode,
  headerNode,
  bodyNode,
  footerNode,
  numRows,
  batchSelectRowHeight,
}) {
  const headerCellNodes = headerNode.querySelectorAll(SELECTORS.CELL)
  const bodyRowNodes = bodyNode.querySelectorAll(SELECTORS.ROW)

  const wrapperHeight = wrapperNode.getBoundingClientRect().height
  const headerHeight = headerNode.getBoundingClientRect().height
  const footerHeight = footerNode?.getBoundingClientRect()?.height || 0
  const firstRowHeight = bodyRowNodes[0].getBoundingClientRect().height

  const scrollableAreaHeight = wrapperHeight - headerHeight - footerHeight - batchSelectRowHeight
  const maxVisibleRows = Math.ceil((scrollableAreaHeight) / firstRowHeight)
  const scrollableCanvasHeight = firstRowHeight * numRows
  const headerCellWidths = [...headerCellNodes].map(
    (node) => Math.ceil(node.getBoundingClientRect().width),
  )

  return {
    rowHeight: firstRowHeight,
    scrollableAreaHeight,
    maxVisibleRows,
    scrollableCanvasHeight,
    headerCellWidths,
  }
}

function resetStyle({
  wrapperNode,
  bodyNode,
  headerNode,
  headerCellStyles,
  height,
}) {
  const headerCellNodes = headerNode.querySelectorAll(SELECTORS.CELL)
  const bodyRowNodes = bodyNode.querySelectorAll(SELECTORS.ROW)

  let wrapperHeight = height
  if (Number.isFinite(wrapperHeight)) {
    wrapperHeight = `${wrapperHeight}px`
  }

  /* eslint-disable no-param-reassign */
  wrapperNode.style.height = wrapperHeight
  wrapperNode.style.width = '9999999px'
  bodyNode.style.top = 0
  bodyNode.style.height = 'auto'
  bodyNode.style.width = 'auto'

  headerCellNodes.forEach((node, i) => {
    if (!headerCellStyles[i]) {
      return
    }
    const { width } = headerCellStyles[i]
    node.style.width = width || 'auto'
  })
  bodyRowNodes.forEach((rowNode) => {
    rowNode.style.width = 'auto'
    const rowCellNodes = rowNode.querySelectorAll(SELECTORS.CELL)
    rowCellNodes.forEach((node) => {
      node.style.width = 'auto'
    })
  })
  /* eslint-enable no-param-reassign */

  wrapperNode.classList.remove('virtualised')
}

function setCellSizes({
  headerNode,
  bodyNode,
  headerCellWidths,
  totalWidth,
  rowHeight,
}) {
  const headerCellNodes = headerNode.querySelectorAll(SELECTORS.CELL)
  const bodyRowNodes = bodyNode.querySelectorAll(SELECTORS.ROW)

  /* eslint-disable no-param-reassign */
  headerCellNodes.forEach((node, i) => {
    node.style.width = `${headerCellWidths[i]}px`
  })
  bodyRowNodes.forEach((rowNode) => {
    rowNode.style.width = `${totalWidth}px`
    rowNode.style.height = `${rowHeight}px`
    const rowCellNodes = rowNode.querySelectorAll(SELECTORS.CELL)
    rowCellNodes.forEach((node, i) => {
      node.style.width = `${headerCellWidths[i]}px`
    })
  })
  /* eslint-enable no-param-reassign */
}

function setContainerSize({
  tableNode,
  wrapperNode,
  headerNode,
  bodyNode,
  totalWidth,
  scrollableAreaHeight,
  height,
}) {
  const headerHeight = headerNode.getBoundingClientRect().height
  /* eslint-disable no-param-reassign */
  wrapperNode.style.height = `${height}px`
  wrapperNode.style.width = `${totalWidth}px`
  tableNode.style.height = `${headerHeight + scrollableAreaHeight}px`
  headerNode.style.width = `${totalWidth}px`
  bodyNode.style.height = `${scrollableAreaHeight}px`
  bodyNode.style.width = `${totalWidth}px`
  /* eslint-enable no-param-reassign */
}

function setVisibleRowsPosition({
  firstRowIndex,
  numAdditionalRows,
  rowHeight,
  renderedRowNodes,
}) {
  const numAdditionalRowsAbove = Math.min(firstRowIndex, numAdditionalRows)
  renderedRowNodes.forEach((node, i) => {
    // eslint-disable-next-line no-param-reassign
    node.style.top = `${((firstRowIndex + i - numAdditionalRowsAbove) * rowHeight)}px`
  })
}

exports.getFirstRowIndex = getFirstRowIndex
exports.getSizes = getSizes
exports.resetStyle = resetStyle
exports.setCellSizes = setCellSizes
exports.setContainerSize = setContainerSize
exports.setVisibleRowsPosition = setVisibleRowsPosition
