import { render, staticRenderFns } from "./RSwitch.vue?vue&type=template&id=39faf0f2&scoped=true&"
import script from "./RSwitch.vue?vue&type=script&lang=js&"
export * from "./RSwitch.vue?vue&type=script&lang=js&"
import style0 from "./RSwitch.vue?vue&type=style&index=0&id=39faf0f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39faf0f2",
  null
  
)

export default component.exports