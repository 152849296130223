const readableTimeSince = ({ $moment, date }) => {
  if (!date) {
    return ''
  }

  const dateStr = $moment(date).fromNow(true)

  return dateStr
    .replace(/\syears?/, ' y')
    .replace(/\smonths?/, ' m')
    .replace(/\sdays?/, ' d')
    .replace(/\shours?/, ' h')
    .replace(/\sminutes?/, ' m')
    .replace(/\sseconds?/, ' s')
    .replace('a few s', ' < 1m')
    .replace(/an\s?h/, '1 h')
    .replace(/a\s?d/, '1 d')
    .replace(/a\s?m/, '1 m')
    .replace(/a\s?y/, '1 y')
}

export default readableTimeSince
