<template>
  <div class="failedbcrlink">
    <v-alert
      type="error"
      dense
      class="px-2"
    >
      <span>{{ $t('bcrLinks.statuses.failed.title') }}</span>
    </v-alert>

    <p>
      <span>
        {{ $t('bcrLinks.statuses.failed.message', { dataType: formattedDataType, name }) }}
      </span>
    </p>

    <p>
      <span> {{ $t('bcrLinks.statuses.failed.retry') }} </span>
    </p>
  </div>
</template>

<script>

import { DATA_TYPES } from '@/utils/constants'

export default {
  name: 'FailedBCRLink',
  props: {
    name: {
      type: String,
      required: true,
    },
    formattedDataType: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
      validator(value) {
        return [DATA_TYPES.URLS, DATA_TYPES.PRODUCTS].includes(value)
      },
    },
  },
}
</script>
