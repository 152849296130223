<template>
  <div class="productstable">
    <r-error-message
      v-if="fetchProductsError"
      class="productstable--errormessage"
      :errors="[fetchProductsError, fetchingDownloadReviewsError]"
    />
    <r-table
      key="productstable"
      class="productstable--table"
      :columns="tableColumns"
      :rows="products"
      :total-rows="totalProducts"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingProducts"
      @change:page="({ value }) => setPage({ page: value })"
      @change:page-size="onChangePageSize"
      @change:page-size-all="onChangePageSizeAll"
      @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
      @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      @change:batch-select="(data) => $emit('change:batch-select', data)"
      @change:batch-select-all="(data) => $emit('change:batch-select-all')"
    >
      <template v-slot:item.name="{ item }">
        <span class="productstable--name text-truncate">{{ item.name }}</span>
      </template>
      <template v-slot:item.averageRating="{ item }">
        <r-rating
          class="productstable--rating"
          :value="item.averageRating"
        />
      </template>
      <template v-slot:item.categories="{ item }">
        <span v-if="item.categories.length > 0" />
        <r-badge
          v-for="category in item.categories.slice(0, 1)"
          :key="category.name"
          :name="category.name"
          :color="category.color"
        />
      </template>
      <template v-slot:item.tags="{ item }">
        <span v-if="item.tags.length > 0" />
        <r-badge
          v-for="tag in item.tags.slice(0, 1)"
          :key="tag.name"
          :name="tag.name"
          :color="tag.color"
        />
      </template>
      <template v-slot:item.numReviews="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ item.numReviews }}</span>
        </div>
      </template>
      <template v-slot:item.lastReviewed="{ item }">
        <span v-if="item.lastReviewed">{{ formatDate(item.lastReviewed) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.lastOpened="{ item }">
        <span v-if="item.lastOpened">{{ formatDate(item.lastOpened) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.actions="{item}">
        <row-actions
          tab="Products"
          :item="item"
          @toggle:delete-modal="onToggleDeleteModal"
          @remove:brands="onRemoveBrands"
          @add:brands="onAddBrands"
          @remove:categories="onRemoveCategories"
          @add:categories="onAddCategories"
          @remove:tags="onRemoveTags"
          @add:tags="onAddTags"
          @open:create-brand-modal="onClickCreateNewBrand"
          @open:create-category-modal="onClickCreateNewCategory"
          @open:create-tag-modal="onClickCreateNewTag"
          @create:bcr-link="onClickBCRLink"
          @click:download-reviews="onClickDownloadReviews"
        />
      </template>
    </r-table>

    <delete-products
      v-if="showDeleteModal"
      :product-ids="idsOfProductToBeDeleted"
      :selected-product="selectedProduct"
      @delete:cancel="onDeleteCancellation"
      @delete:success="onDeletion"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import debounce from '@/utils/debounce'
import {
  DATA_TYPES,
  BATCH_OPERATIONS_MAP,
  RPOP_UP_TOP_MARGIN,
} from '@/utils/constants'
import RBadge from '@/components/library/atoms/RBadge'
import RRating from '@/components/library/atoms/RRating'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import RowActions from '@/components/app/data/RowActions'
import DeleteProducts from '@/components/app/data/delete/DeleteProducts'

export default {
  name: 'ProductsTable',
  components: {
    RTable,
    RErrorMessage,
    RBadge,
    RRating,
    RowActions,
    DeleteProducts,
  },
  props: {
    search: String,
  },
  data: () => ({
    tableColumns: [
      {
        key: 'name',
        label: 'Product Name',
        hideable: true,
        sortable: true,
        width: 200,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: true,
        sortable: true,
        width: 130,
      },
      {
        key: 'numUrls',
        label: 'URLs',
        hideable: true,
        sortable: true,
        width: 80,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'lastReviewed',
        label: 'Last reviewed',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'lastOpened',
        label: 'Last Opened',
        hideable: true,
        sortable: true,
        hidden: true,
        width: 120,
      },
      {
        key: 'actions',
        label: 'Actions',
        hideable: false,
        width: 185,
      },
    ],
    debouncedFetchData: null,
    rowActionPosition: null,
    showDeleteModal: false,
    idsOfProductToBeDeleted: [],
    selectedProduct: '',
  }),
  computed: {
    ...mapState('products', [
      'products',
      'fetchingProducts',
      'fetchProductsError',
      'totalProducts',
      'page',
      'pageSize',
      'sort',
      'sortDir',
      'fetchingEditTags',
      'fetchingDownloadReviewsError',
      'fetchingProductsUpdate',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('filters', [
      'filterKey',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ page: 1 })
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    selectedProjectId() {
      this.$data.debouncedFetchData()
    },
    filterKey() {
      this.$data.debouncedFetchData()
    },
    fetchingProductsUpdate(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.$data.debouncedFetchData()
      }
    },
    appliedFilters() {
      this.setPage({ page: 1 })
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  methods: {
    ...mapActions('products', [
      'fetchProducts',
      'setPage',
      'setPageSize',
      'setSort',
      'editProductsTags',
    ]),
    ...mapActions('brands', [
      'editBrandsProducts',
    ]),
    ...mapActions('categories', [
      'editCategoriesProducts',
    ]),
    fetchData() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        selectedProjectId,
        appliedFilters,
      } = this

      let payload = {
        page, pageSize, ...appliedFilters, projectId: selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchProducts(payload)
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalProducts })
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    async editTagsOfProduct({ op, productId, tagIds }) {
      const payload = {
        op,
        tagIds,
        productIds: [productId],
      }
      await this.editProductsTags({ ...payload })
      await this.fetchData()
    },
    onRemoveTags({ value: { id: productId, tagIds } }) {
      this.editTagsOfProduct({
        op: BATCH_OPERATIONS_MAP.REMOVE,
        productId,
        tagIds,
      })
    },
    onAddTags({ value: { id: productId, tagIds } }) {
      this.editTagsOfProduct({
        op: BATCH_OPERATIONS_MAP.ADD,
        productId,
        tagIds,
      })
    },
    onRemoveBrands({ value: { id: productId, brandIds } }) {
      this.editProductBrands({
        op: BATCH_OPERATIONS_MAP.REMOVE,
        productId,
        brandIds,
      })
    },
    onAddBrands({ value: { id: productId, brandIds } }) {
      this.editProductBrands({
        op: BATCH_OPERATIONS_MAP.ADD,
        productId,
        brandIds,
      })
    },
    async editProductBrands({ op, productId, brandIds }) {
      const payload = {
        op,
        brandIds,
        productIds: [productId],
      }

      await this.editBrandsProducts({ ...payload })
      await this.fetchData()
    },
    onToggleDeleteModal(payload) {
      const { item } = payload
      this.idsOfProductToBeDeleted = [item._id]
      this.selectedProduct = item.name
      this.showDeleteModal = true
    },
    onDeleteCancellation() {
      this.showDeleteModal = false
    },
    async onDeletion() {
      this.showDeleteModal = false
      this.fetchData()
    },
    onRemoveCategories({ value: { id: productId, categoryIds } }) {
      const payload = {
        op: BATCH_OPERATIONS_MAP.REMOVE,
        categoryIds,
        productIds: [productId],
      }
      this.editCategoriesProducts(payload)
    },
    onAddCategories({ value: { id: productId, categoryIds } }) {
      const payload = {
        op: BATCH_OPERATIONS_MAP.ADD,
        categoryIds,
        productIds: [productId],
      }
      this.editCategoriesProducts(payload)
    },
    getRowItemPosition(event) {
      const POP_UP_HEIGHT = 400
      const bb = this.$el.getBoundingClientRect()
      const [item] = event.currentTarget.getClientRects()
      if (item.y + POP_UP_HEIGHT > window.innerHeight) {
        this.$emit('set-popup-position', { isUp: true })
        return {
          x: event.clientX - bb.left,
          y: event.clientY
              - bb.top - POP_UP_HEIGHT
              - item?.height ?? 0, // workaround unit
          // test fails getClientRects  return empty array
        }
      }
      this.$emit('set-popup-position', { isUp: false })
      return {
        x: event.clientX - bb.left,
        y: event.clientY - bb.top + RPOP_UP_TOP_MARGIN + 2 * item?.height ?? 0, // workaround unit
        // test fails getClientRects  return empty array
      }
    },
    onClickCreateNewBrand() {
      this.$emit('open:create-brand-modal')
    },
    onClickCreateNewTag() {
      this.$emit('open:create-tag-modal')
    },
    onClickCreateNewCategory() {
      this.$emit('open:create-category-modal')
    },
    onClickDownloadReviews({ value }) {
      const { id } = value

      return this.$emit('click:download-reviews', {
        value: { id, dataType: DATA_TYPES.PRODUCTS },
      })
    },
    onClickBCRLink({ item }) {
      const { _id, bcrLinkJobIds } = item
      const value = { itemId: _id, dataType: DATA_TYPES.PRODUCTS }

      if (!bcrLinkJobIds?.length) {
        return this.$emit('open:create-bcr-link', { value })
      }

      return this.$emit('open:bcr-link-status', { value })
    },
  },
}
</script>

<style scoped>
.productstable--table {
  width: 100%;
  min-height: 600px;
}
.productstable--name {
  display: block;
  width: 168px; /* 200 minus padding of 32 */
}
</style>
