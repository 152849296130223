<template>
  <div>
    <r-modal
      v-if="show"
      class="createbcrquery"
      :title="$t('bcrLinks.createQuery.title')"
    >
      <template v-slot:content>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-5"
        >
          <r-error-message
            class="mb-3"
            :errors="errors"
          />

          <v-row
            v-if="fetchingSuggestedBCRQuery"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>

          <div
            v-else
            class="createbcrquery--content"
          >
            <v-row>
              <v-col cols="12">
                <label>{{ $t('bcrLinks.createQuery.fields.project.label') }}</label>
                <r-select
                  :loading="fetchingProjects"
                  :items="projectItems"
                  :multiple="false"
                  :allow-empty="false"
                  @change="onChangeSelectedBCRProject"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <r-text-field
                  :label="$t('bcrLinks.createQuery.fields.queryName.label')"
                  :value="queryName"
                  :rules="[rules.required]"
                  @change="onChangeQueryName"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <r-text-field
                  :label="$t('bcrLinks.createQuery.fields.queryDefinition.label')"
                  :value="queryDefinition"
                  :rules="[rules.required]"
                  type="text-area"
                  @change="onChangeQueryDefinition"
                />
              </v-col>
            </v-row>
          </div>
        </v-form>
      </template>
      <template v-slot:customActions>
        <div
          class="d-flex justify-end align-center"
        >
          <r-button
            v-if="!fetchingSuggestedBCRQuery"
            class="mr-2"
            :outlined="true"
            :label="$t('global.actions.cancel')"
            @click="closeModal"
          />
          <r-button
            v-if="!fetchingSuggestedBCRQuery"
            :label="$t('global.actions.save')"
            :loading="fetchingCreateBCRQuery"
            @click="onClickCreateQuery"
          />
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showSuccessMessage"
      class="createbcrquery-alert-modal"
      :title="$t('bcrLinks.createQuery.title')"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          {{
            $t('urlsTable.batchActions.createBCRQuery.successMessage', {
              queryName,
              selectedBCRProjectName
            })
          }}
        </div>
        <div class="d-flex justify-center align-center">
          {{ $t('urlsTable.batchActions.createBCRQuery.successPostLinkMessage') }}
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.backToData')"
          @click="closeSuccessMessage"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import RTextField from '@/components/library/molecules/RTextField'
import RButton from '@/components/library/atoms/RButton'
import RSelect from '@/components/library/molecules/RSelect'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { mapActions, mapState } from 'vuex'
import { DATA_TYPES } from '@/utils/constants'

export default {
  name: 'CreateBCRQuery',
  components: {
    RErrorMessage,
    RModal,
    RButton,
    RSelect,
    RTextField,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    dataType: {
      type: String,
      required: false,
      validator(value) {
        return [DATA_TYPES.URLS, DATA_TYPES.PRODUCTS].includes(value)
      },
    },
    selectedItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSuccessMessage: false,
      valid: false,
      selectedBCRProjectId: '',
      selectedBCRProjectName: '',
      queryName: '',
      queryDefinition: '',
      rules: {
        required: (v) => !!v || this.$i18n.t('global.validations.messages.required'),
      },
    }
  },
  computed: {
    ...mapState('bcr', [
      'projects',
      'fetchingProjects',
      'fetchingProjectsError',
    ]),
    ...mapState('urls', {
      fetchingUrlSuggestedBCRQuery: 'fetchingSuggestedBCRQuery',
      suggestedUrlBCRQuery: 'suggestedBCRQuery',
      fetchingSuggestedUrlBCRQueryError: 'fetchingSuggestedBCRQueryError',
      fetchingCreateUrlBCRQuery: 'fetchingCreateBCRQuery',
      fetchingCreateUrlBCRQueryError: 'fetchingCreateBCRQueryError',
    }),
    ...mapState('products', {
      fetchingProductSuggestedBCRQuery: 'fetchingSuggestedBCRQuery',
      suggestedProductBCRQuery: 'suggestedBCRQuery',
      fetchingSuggestedProductBCRQueryError: 'fetchingSuggestedBCRQueryError',
      fetchingCreateProductBCRQuery: 'fetchingCreateBCRQuery',
      fetchingCreateProductBCRQueryError: 'fetchingCreateBCRQueryError',
    }),
    projectItems() {
      return this.projects.map(({ id, name }, index) => {
        const isDefaultSelected = index === 0

        if (isDefaultSelected) {
          this.selectedBCRProjectId = id
        }

        return {
          selected: isDefaultSelected,
          value: id,
          label: name,
        }
      })
    },
    errors() {
      return [
        this.fetchingSuggestedUrlBCRQueryError,
        this.fetchingCreateUrlBCRQueryError,
        this.fetchingSuggestedProductBCRQueryError,
        this.fetchingCreateProductBCRQueryError,
      ]
    },
    fetchingCreateBCRQuery() {
      const LOADING_STATE_BY_DATA_TYPE = {
        [DATA_TYPES.PRODUCTS]: this.fetchingCreateProductBCRQuery,
        [DATA_TYPES.URLS]: this.fetchingCreateUrlBCRQuery,
      }

      return LOADING_STATE_BY_DATA_TYPE[this.dataType]
    },
    fetchingCreateBCRQueryError() {
      const ERROR_MAP = {
        [DATA_TYPES.PRODUCTS]: this.fetchingCreateProductBCRQueryError,
        [DATA_TYPES.URLS]: this.fetchingCreateUrlBCRQueryError,
      }

      return ERROR_MAP[this.dataType] || ''
    },
    fetchingSuggestedBCRQuery() {
      const LOADING_STATE_BY_DATA_TYPE = {
        [DATA_TYPES.PRODUCTS]: this.fetchingProductSuggestedBCRQuery,
        [DATA_TYPES.URLS]: this.fetchingUrlSuggestedBCRQuery,
      }

      return LOADING_STATE_BY_DATA_TYPE[this.dataType] || ''
    },
    suggestedBCRQuery() {
      const STATE_BY_DATA_TYPE = {
        [DATA_TYPES.PRODUCTS]: this.suggestedProductBCRQuery,
        [DATA_TYPES.URLS]: this.suggestedUrlBCRQuery,
      }

      return STATE_BY_DATA_TYPE[this.dataType] || ''
    },
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        return
      }

      const payload = this.selectedItems
      this.fetchProjects()

      const FUNC_MAP = {
        [DATA_TYPES.PRODUCTS]: this.fetchSuggestedProductBCRQuery,
        [DATA_TYPES.URLS]: this.fetchSuggestedUrlBCRQuery,
      }

      FUNC_MAP[this.dataType](payload).then(() => {
        this.queryDefinition = this.suggestedBCRQuery
      })
    },
  },
  methods: {
    ...mapActions('bcr', [
      'fetchProjects',
    ]),
    ...mapActions('urls', {
      fetchSuggestedUrlBCRQuery: 'fetchSuggestedBCRQuery',
      fetchCreateUrlBCRQuery: 'fetchCreateBCRQuery',
    }),
    ...mapActions('products', {
      fetchSuggestedProductBCRQuery: 'fetchSuggestedBCRQuery',
      fetchCreateProductBCRQuery: 'fetchCreateBCRQuery',
    }),
    showSuccessMessageModal() {
      // eslint-disable-next-line max-len
      const selectedProject = this.projectItems.find(({ value }) => value === this.selectedBCRProjectId)

      this.selectedBCRProjectName = selectedProject.label
      this.showSuccessMessage = true
    },
    createUrlBCRQuery(payload) {
      this.fetchCreateUrlBCRQuery(payload).then(() => {
        if (!this.fetchingCreateBCRQueryError) {
          this.$emit('close')
          this.showSuccessMessageModal()
        }
      })
    },
    createProductBCRQuery(payload) {
      this.fetchCreateProductBCRQuery(payload).then(() => {
        if (!this.fetchingCreateBCRQueryError) {
          this.$emit('close')
          this.showSuccessMessageModal()
        }
      })
    },
    onChangeSelectedBCRProject({ value }) {
      this.selectedBCRProjectId = value
    },
    onChangeQueryName({ value }) {
      this.queryName = value
    },
    onChangeQueryDefinition({ value }) {
      this.queryDefinition = value
    },
    onClickCreateQuery(e) {
      e.preventDefault()

      if (!this.$refs.form.validate()) {
        return
      }

      const payload = {
        ...this.selectedItems,
        bcrProjectId: this.selectedBCRProjectId,
        queryName: this.queryName,
        queryDefinition: this.queryDefinition,
      }

      const ACTIONS_BY_DATA_TYPE = {
        [DATA_TYPES.PRODUCTS]: this.createProductBCRQuery,
        [DATA_TYPES.URLS]: this.createUrlBCRQuery,
      }

      ACTIONS_BY_DATA_TYPE[this.dataType](payload)
    },
    closeModal() {
      this.resetState()
      this.$emit('close')
    },
    closeSuccessMessage() {
      this.resetState()
      this.showSuccessMessage = false
    },
    resetState() {
      this.queryName = ''
      this.queryDefinition = ''
      this.selectedBCRProjectName = ''
      this.selectedBCRProjectId = ''
    },
  },
}
</script>
