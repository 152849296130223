<template>
  <div class="runningbcrlink">
    <v-alert
      type="success"
      dense
      class="px-2"
    >
      <span>{{ $t('bcrLinks.statuses.running.title', { dataType: formattedDataType }) }}</span>
    </v-alert>

    <p>
      <span>{{ $t('bcrLinks.statuses.running.message', { name }) }}</span>
    </p>

    <p>
      <span>
        {{
          $t('bcrLinks.statuses.running.numLinkedReviewsMessage', { numLinkedReviews, dataType })
        }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'RunningBCRLink',
  props: {
    name: {
      type: String,
      required: true,
    },
    formattedDataType: {
      type: String,
      required: true,
    },
    numLinkedReviews: {
      type: Number,
      required: true,
    },
  },
}
</script>
