<template>
  <div class="brandstable">
    <r-error-message
      v-if="fetchBrandsError"
      class="brandstable--errormessage"
      :errors="[fetchBrandsError]"
    />
    <r-table
      key="brandstable"
      class="brandstable--table"
      :columns="tableColumns"
      :rows="brands"
      :total-rows="totalBrands"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingBrands"
      @change:page="({ value }) => setPage({ page: value })"
      @change:page-size="onChangePageSize"
      @change:page-size-all="onChangePageSizeAll"
      @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
      @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      @change:batch-select="(data) => $emit('change:batch-select', data)"
      @change:batch-select-all="() => $emit('change:batch-select-all')"
    >
      <template v-slot:item.name="{ item }">
        <r-badge
          :name="item.name"
          :color="item.color"
        />
      </template>
      <template v-slot:item.averageRating="{ item }">
        <r-rating
          class="productstable--rating"
          :value="item.averageRating"
        />
      </template>
      <template v-slot:item.numReviews="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ item.numReviews }}</span>
          <v-icon class="ml-1">
            mdi-download
          </v-icon>
        </div>
      </template>
      <template v-slot:item.lastReviewed="{ item }">
        <span v-if="item.lastReviewed">{{ formatDate(item.lastReviewed) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.lastOpened="{ item }">
        <span v-if="item.lastOpened">{{ formatDate(item.lastOpened) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <row-actions
          tab="Brands"
          :item="item"
          @toggle:delete-modal="onToggleDeleteConfirmationModal"
        />
      </template>
    </r-table>
    <delete-confirmation
      :show-modal="showDeleteConfirmationModal"
      :error="deleteBrandsError"
      :fetching="deletingBrands"
      :custom-header="$t( 'commonTable.batchActions.delete.title')"
      :custom-action-label="$t('commonTable.batchActions.delete.deleteFromDataButton')"
      :custom-description="selectedProjectId ?
        $t('commonTable.batchActions.delete.descriptionProject', { dataTypeName } )
        : $t('commonTable.batchActions.delete.description', { dataTypeName } )"
      :custom-alternate-action-label="!selectedProjectId ? undefined : $t(
        'commonTable.batchActions.delete.deleteFromProjectButton'
      )"
      @delete:cancel="onToggleDeleteConfirmationModal"
      @delete:confirm="onDelete"
    />

    <r-modal
      v-if="showDeleteAlertModal"
      class="urlstable--deleteconfirmationmodal"
      :title="(!deleteBrandsError
        ? $t( 'commonTable.batchActions.delete.title')
        : $t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName })
      )"
      :action-label="$t('global.actions.confirm')"
      @submit="showDeleteAlertModal = false"
    >
      <template v-slot:content>
        <div
          v-if="deleteBrandsError"
          class="text-center"
        >
          {{ deleteBrandsError }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{
            $t(`commonTable.batchActions.delete.${
              !selectedProjectId ?
                'deleteFromAllDataSuccessMessage' :
                'deleteFromProjectSuccessMessage'
            }`, {
              dataTypeCount,
              dataTypeName,
            })
          }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RRating from '@/components/library/atoms/RRating'
import RBadge from '@/components/library/atoms/RBadge'
import debounce from '@/utils/debounce'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import DeleteConfirmation from '@/components/app/data/add/DeleteConfirmation'
import RowActions from '@/components/app/data/RowActions'
import RModal from '@/components/library/organisms/RModal.vue'

export default {
  name: 'BrandsTable',
  components: {
    RModal,
    RTable,
    RBadge,
    RRating,
    RErrorMessage,
    DeleteConfirmation,
    RowActions,
  },
  props: {
    search: String,
  },
  data: () => ({
    tableColumns: [
      {
        key: 'name',
        label: 'Brand',
        hideable: true,
        sortable: true,
        width: 250,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: true,
        sortable: true,
        width: 130,
      },
      {
        key: 'numUrls',
        label: 'URLs',
        hideable: true,
        sortable: true,
        width: 80,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'lastReviewed',
        label: 'Last reviewed',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'lastOpened',
        label: 'Last Opened',
        hideable: true,
        sortable: true,
        hidden: true,
        width: 120,
      },
      {
        key: 'actions',
        label: 'Actions',
        hideable: false,
        width: 130,
      },
    ],
    debouncedFetchData: null,
    selectedBrand: null,
    showDeleteConfirmationModal: false,
    dataTypeName: 'brand',
    dataTypeCount: '',
    showDeleteAlertModal: false,
  }),
  computed: {
    ...mapState('brands', [
      'brands',
      'fetchingBrands',
      'fetchBrandsError',
      'totalBrands',
      'page',
      'pageSize',
      'sort',
      'sortDir',
      'deleteBrandsError',
      'deletingBrands',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('filters', [
      'filterKey',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ page: 1 })
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    selectedProjectId() {
      this.$data.debouncedFetchData()
    },
    filterKey() {
      this.$data.debouncedFetchData()
    },
    appliedFilters() {
      this.setPage({ page: 1 })
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  methods: {
    ...mapActions('brands', [
      'fetchBrands',
      'setPage',
      'setPageSize',
      'setSort',
      'deleteBrands',
      'clearErrors',
    ]),
    fetchData() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        selectedProjectId,
        appliedFilters,
      } = this

      let payload = {
        page, pageSize, ...appliedFilters, projectId: selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchBrands(payload)
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalBrands })
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onToggleDeleteConfirmationModal({ item } = {}) {
      this.selectedBrand = item
      this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal
      this.clearErrors({ deleteBrandsError: '' })
    },
    async onDelete({ value }) {
      this.clearErrors({ deleteBrandsError: '' })
      const { op, alternate } = value

      this.dataTypeCount = this.selectedBrand.name
      const brandIds = [this.selectedBrand._id]
      const payload = { brandIds }
      if (alternate) {
        payload.projectId = this.selectedProjectId
      } else {
        payload.op = op
      }
      await this.deleteBrands(payload)
      if (!this.deleteTagsError) {
        this.onToggleDeleteConfirmationModal()
        const queryPayload = this.getQueryParameters()
        this.showDeleteAlertModal = true
        await this.fetchBrands(queryPayload)
      }
    },
    getQueryParameters() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        selectedProjectId,
        appliedFilters,
      } = this

      let payload = {
        page, pageSize, ...appliedFilters, projectId: selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }
      return payload
    },
  },
}
</script>

<style scoped>
/* .brandstable /deep/ .rtable--row {
  min-width: 0;
} */
.brandstable--table {
  width: 100%;
  min-height: 600px;
}
.brandstable--name {
  display: block;
  width: 168px; /* 200 minus padding of 32 */
}
</style>
