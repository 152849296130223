<template>
  <v-tabs-items
    v-model="selectedTab"
    class="rtabitems content"
  >
    <slot />
  </v-tabs-items>
</template>

<script>
export default {
  name: 'RTabItems',
  props: {
    selectedTab: {
      type: Number,
      required: false,
      default: 0,
    },
  },
}
</script>

<style>
  .v-slide-group__content,
  .v-window-x-transition-enter-active,
  .v-window-x-transition-leave-active,
  .v-window-x-reverse-transition-enter-active,
  .v-window-x-reverse-transition-leave-active {
    transition:none;
  }

  .v-tabs--grow > .v-tabs-bar .v-tab {
    flex: 0 0 auto;
  }

  .v-tab {
    background: rgba(63, 63, 63, 0.15);
    border-radius: 4px 4px 0 0;
    margin: 0 5px;
  }

  .v-tab.v-tab--active {
    background: white;
    color: #1691CF;
    border: 2px solid rgba(63, 63, 63, 0.15);
  }

  .v-tabs-slider-wrapper {
    display: none;
  }
</style>
