var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rmarkasseen d-flex"
  }, [_c('a', {
    staticClass: "rmarkasseen--link",
    on: {
      "click": function ($event) {
        return _vm.$emit('on:mark-as-seen');
      }
    }
  }, [_c('v-icon', [_vm._v(" visibility ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }