<template>
  <div class="deletebatchurls">
    <r-modal
      v-if="showDeleteUrlsModal"
      class="deletebatchurls--deletemodal"
      :title="deleteModalLabels.title"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="deleteModalLabels.actionLabel"
      :alternate-action-label="deleteModalLabels.alternateActionLabel"
      :fetching="deleteUrlsFetching"
      @submit="onDeleteUrls"
      @submit:alternate="onDeleteUrlsFromProject"
      @close="$emit('close')"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          <label>{{ deleteModalLabels.content }}</label>
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showDeleteUrlsAlertModal"
      class="deletebatchurls--deleteconfirmationmodal"
      :title="(!deleteUrlsError
        ? deleteModalLabels.title
        : $t('urlsTable.batchActions.deleteUrls.multipleUrls.deleteErrorMessage',
             { deleteUrlsCount })
      )"
      :action-label="$t('global.actions.confirm')"
      @submit="$emit('close:alert-modal')"
    >
      <template v-slot:content>
        <div
          v-if="deleteUrlsError"
          class="text-center"
        >
          {{ deleteUrlsError }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ deleteModalLabels.deleteSuccess }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DeleteBatchUrls',
  components: {
    RModal,
  },
  props: {
    selectedUrl: {
      type: String,
      required: false,
    },
    showDeleteUrlsModal: {
      type: Boolean,
      default: false,
    },
    showDeleteUrlsAlertModal: {
      type: Boolean,
      default: false,
    },
    deleteUrlsCount: {
      type: Number,
    },
  },
  data: () => ({
    deleteFromEverywhere: false,
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('urls', [
      'deleteUrlsFetching',
      'deleteUrlsError',
    ]),
    deleteModalLabels() {
      const { deleteFromEverywhere } = this.$data
      const dataTypeCount = this.deleteUrlsCount === 1 ? this.selectedUrl : this.deleteUrlsCount
      const dataTypeName = this.deleteUrlsCount === 1 ? 'url' : 'urls'

      const deleteSuccess = deleteFromEverywhere
        ? this.$t('commonTable.batchActions.delete.deleteFromAllDataSuccessMessage', { dataTypeCount, dataTypeName })
        : this.$t('commonTable.batchActions.delete.deleteFromProjectSuccessMessage', { dataTypeCount, dataTypeName })

      if (this.selectedProjectId) {
        return {
          title: this.$t('commonTable.batchActions.delete.title'),
          content: this.$t('commonTable.batchActions.delete.descriptionProject', { dataTypeName }),
          actionLabel: this.$t('commonTable.batchActions.delete.deleteFromDataButton'),
          alternateActionLabel: this.$t('commonTable.batchActions.delete.deleteFromProjectButton'),
          deleteErrorMessage: this.$t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName }),
          deleteSuccess,
        }
      }
      return {
        title: this.$t('commonTable.batchActions.delete.title'),
        content: this.$t('commonTable.batchActions.delete.description', { dataTypeName }),
        actionLabel: this.$t('commonTable.batchActions.delete.deleteFromDataButton'),
        deleteErrorMessage: this.$t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName }),
        deleteSuccess,
      }
    },
    confirmationMessage() {
      return {
        deleteUrlsCount: this.deleteUrlsCount,
        resource: this.deleteUrlsCount > 1 ? 'urls' : 'url',
      }
    },
  },
  methods: {
    onDeleteUrls() {
      this.$data.deleteFromEverywhere = true
      this.$emit('submit:delete-urls')
    },
    onDeleteUrlsFromProject() {
      this.$data.deleteFromEverywhere = false
      this.$emit('submit:delete-urls-from-project')
    },
  },
}
</script>
