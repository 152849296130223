<template>
  <v-row class="ma-0 productsdropdown d-flex justify-center">
    <r-select
      :infinite="true"
      multiple
      class="projectsdropdown--select"
      :items="items"
      @change="onSelectProduct"
      @on:get-more="onGetMoreItems"
      @change:search="onSearchChange"
    >
      <template
        v-if="selectLabel"
        v-slot:label
      >
        <span>{{ selectLabel }}</span>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'
import { mapActions, mapState } from 'vuex'
import { generateParams } from '@/utils/store'

export default {
  name: 'ProductsDropdown',
  components: {
    RSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    preselectedProducts: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapState('products', [
      'fetchingProductsSummary',
      'productsSummary',
      'fetchProductsError',
    ]),
    items() {
      return this.mapProductsToDropdownItems({
        products: this.productsSummary,
      })
    },
  },
  mounted() {
    if (!this.productsSummary.length) {
      this.fetchProductsSummary()
    }
  },
  methods: {
    ...mapActions('products', [
      'fetchProductsSummary',
    ]),
    onSelectProduct({ value: selectedItems }) {
      this.$emit('change', { value: selectedItems, items: this.items })
    },
    mapProductsToDropdownItems({ products }) {
      const preselectedProductIds = this.preselectedProducts.map(({ _id }) => _id)

      return products.map(({ _id, name, color }) => ({
        label: name,
        value: _id,
        selected: preselectedProductIds.includes(_id),
        color,
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('products')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.projectId,
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchProductsSummary(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchProductsSummary({ projectId: this.projectId, search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
.projectsdropdown--select {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-content: center;
  gap: 10px;
}
</style>
