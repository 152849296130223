<template>
  <div>
    <r-modal
      v-if="showModal"
      class="addtoprojects"
      :title="$t('urlsTable.batchActions.addToProject.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('global.actions.add')"
      :action-disabled="disableAction"
      :fetching="addingToProjects"
      @submit="onAddToProjects"
      @close="onClose"
    >
      <template v-slot:content>
        <r-error-message :errors="[addingToProjectsError]" />
        <div class="mt-3">
          <r-select
            :key="`autpdropdown--rselect`"
            :infinite="true"
            class="autpdropdown--select"
            :items="items"
            :show-search="true"
            :allow-empty="false"
            :multiple="true"
            @change="onSelectProjects"
            @on:get-more="onGetMoreItems"
            @change:search="onSearchChange"
          >
            <template v-slot:item="slotProps">
              <div class="px-3 pt-2">
                <r-badge
                  :name="slotProps.item.label"
                  :color="slotProps.item.color"
                />
              </div>
            </template>
          </r-select>
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showAlertModal"
      class="addtoprojects-alert-modal"
      :title="$t('urlsTable.batchActions.addToProject.title')"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          {{ $t('urlsTable.batchActions.addToProject.successAddMessage') }}
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.backToData')"
          @click="() => $emit('close:add-to-projects-alert-modal')"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RModal from '@/components/library/organisms/RModal'
import RSelect from '@/components/library/molecules/RSelect'
import RBadge from '@/components/library/atoms/RBadge'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { mapActions, mapState } from 'vuex'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'
import { generateParams } from '@/utils/store'

export default {
  name: 'AddToProjects',
  components: {
    RButton,
    RModal,
    RBadge,
    RSelect,
    RErrorMessage,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    showAlertModal: {
      type: Boolean,
      default: true,
    },
    addingToProjects: {
      type: Boolean,
      default: false,
    },
    addingToProjectsError: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedProjectIds: [],
    searchValue: '',
  }),
  computed: {
    ...mapState('projects', [
      'projects',
    ]),
    disableAction() {
      return !this.selectedProjectIds.length
    },
    items() {
      return this.projects.map(({ _id, name }) => ({
        label: name,
        value: _id,
        selected: false,
      }))
    },
  },
  mounted() {
    this.fetchProjects()
  },
  methods: {
    ...mapActions('projects', [
      'fetchProjects',
    ]),
    onSelectProjects({ value: selectedItems }) {
      this.selectedProjectIds = selectedItems.map((item) => item.value)
    },
    onAddToProjects() {
      const value = {
        projectIds: this.selectedProjectIds,
        op: BATCH_OPERATIONS_MAP.ADD,
      }

      this.$emit('submit:add-to-projects', { value })
      if (this.searchValue) {
        this.fetchProjects()
        this.searchValue = ''
      }
    },
    onClose() {
      this.selectedProjectIds = []
      this.$emit('close:add-to-projects', { type: null })
      if (this.searchValue) {
        this.fetchProjects()
        this.searchValue = ''
      }
    },

    onGetMoreItems() {
      const pageParams = generateParams('projects')
      if (!pageParams) {
        return
      }
      let params = {
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchProjects(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchProjects({ search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
  .autpdropdown--select {
    width: 600px;
  }
  </style>
