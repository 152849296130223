<template>
  <r-modal
    v-if="show"
    :title="$t('bcrLinks.create.title')"
    class="createbcrlink"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <r-error-message
        class="mb-3"
        :errors="errors"
      />
      <p>
        <span>
          {{ $t('bcrLinks.create.message', { dataType }) }}
        </span>
      </p>

      <p>
        <span>
          {{ $t('bcrLinks.create.postLinkMessage') }}
        </span>
      </p>
    </template>

    <template v-slot:customActions>
      <r-button
        :outlined="true"
        class="close-btn mr-1"
        :label="$t('global.actions.cancel')"
        @click="onClickClose"
      />
      <r-button
        class="close-btn"
        :label="$t('global.actions.connect')"
        :loading="isCreateLoading"
        @click="onClickConnect"
      />
    </template>
  </r-modal>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import RButton from '@/components/library/atoms/RButton'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { DATA_TYPES } from '@/utils/constants'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreateBCRLink',
  components: {
    RModal,
    RButton,
    RErrorMessage,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      required: true,
      validator(value) {
        return [DATA_TYPES.URLS, DATA_TYPES.PRODUCTS].includes(value)
      },
    },
    selectedItemId: {
      type: String,
      required: false,
    },
    selectedItems: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('urls', [
      'fetchingLinkUrlToBCR',
      'fetchingLinkUrlToBCRError',
    ]),
    ...mapState('products', [
      'fetchingLinkProductToBCR',
      'fetchingLinkProductToBCRError',
    ]),
    errors() {
      return [this.fetchingLinkUrlToBCRError, this.fetchingLinkProductToBCRError]
    },
    isCreateLoading() {
      return this.fetchingLinkUrlToBCR || this.fetchingLinkProductToBCR
    },
  },
  methods: {
    ...mapActions('urls', [
      'fetchLinkUrlToBCR',
    ]),
    ...mapActions('products', [
      'fetchLinkProductToBCR',
    ]),
    onClickClose() {
      const value = { refresh: false }
      return this.$emit('close', { value })
    },
    connectUrl() {
      const payload = this.selectedItemId ? { urlIds: [this.selectedItemId] } : this.selectedItems

      this.fetchLinkUrlToBCR(payload).then(() => {
        if (!this.fetchingLinkUrlToBCRError) {
          const value = { refresh: true, dataType: DATA_TYPES.URLS }
          this.$emit('close', { value })
        }
      })
    },
    connectProduct() {
      // eslint-disable-next-line max-len
      const payload = this.selectedItemId ? { productIds: [this.selectedItemId] } : this.selectedItems

      this.fetchLinkProductToBCR(payload).then(() => {
        if (!this.fetchingLinkProductToBCRError) {
          const value = { refresh: true, dataType: DATA_TYPES.PRODUCTS }
          this.$emit('close', { value })
        }
      })
    },
    onClickConnect() {
      const FUNC_MAP = {
        [DATA_TYPES.URLS]: this.connectUrl,
        [DATA_TYPES.PRODUCTS]: this.connectProduct,
      }

      return FUNC_MAP[this.dataType]()
    },
  },
}
</script>
