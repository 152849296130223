<template>
  <div>
    <r-modal
      v-if="showEditCategoriesModal"
      class="edit-categories-modal"
      :title="$t('urlsTable.batchActions.editCategories.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('urlsTable.batchActions.editCategories.deleteCategories')"
      :alternate-action-label="$t('urlsTable.batchActions.editCategories.addCategories')"
      :action-disabled="disableAction"
      :alternate-action-disabled="disableAction"
      :fetching="loading"
      @submit:alternate="onAddCategories"
      @submit="onRemoveCategories"
      @close="closeEditCategoriesModal"
    >
      <template v-slot:content>
        <label>{{ $t('urlsTable.batchActions.editCategories.subTitle') }}</label>
        <r-error-message :errors="[error]" />
        <div class="mt-3">
          <categories-dropdown
            @change="onSelectedCategory"
            @open:create-category-modal="onClickCreateNewCategory"
          />
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showEditCategoriesAlertModal"
      class="create-product-alert-modal"
      :title="alertTitle"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          {{ alertSuccessMessage }}
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.backToData')"
          @click="closeAlertModal"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RModal from '@/components/library/organisms/RModal'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import CategoriesDropdown from '@/components/app/data/CategoriesDropdown'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'

export default {
  name: 'BatchEditCategories',
  components: {
    RButton,
    RModal,
    RErrorMessage,
    CategoriesDropdown,
  },
  props: {
    showEditCategoriesModal: {
      type: Boolean,
      default: false,
    },
    showEditCategoriesAlertModal: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedCategoryIds: [],
    lastOperation: '',
  }),
  computed: {
    alertTitle() {
      const removeTranslationKey = 'urlsTable.batchActions.editCategories.deleteCategories'
      const addTranslationKey = 'urlsTable.batchActions.editCategories.addCategories'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertTitleKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertTitleKey)
    },
    alertSuccessMessage() {
      const removeTranslationKey = 'urlsTable.batchActions.editCategories.successRemoveMessage'
      const addTranslationKey = 'urlsTable.batchActions.editCategories.successAddMessage'

      const isRemoveOperation = BATCH_OPERATIONS_MAP.REMOVE === this.lastOperation
      const alertSuccessMessageKey = isRemoveOperation ? removeTranslationKey : addTranslationKey

      return this.$t(alertSuccessMessageKey, {
        numCategories: this.selectedCategoryIds.length,
        resource: this.selectedCategoryIds.length > 1 ? 'categories' : 'category',
      })
    },
    disableAction() {
      return !this.selectedCategoryIds.length
    },
  },
  methods: {
    onSelectedCategory({ value: selectedItems }) {
      this.selectedCategoryIds = selectedItems.map((item) => item.value)
    },
    onAddCategories() {
      this.lastOperation = BATCH_OPERATIONS_MAP.ADD

      const value = {
        categoryIds: this.selectedCategoryIds,
        op: BATCH_OPERATIONS_MAP.ADD,
      }

      this.$emit('submit:edit-categories', { value })
    },
    onRemoveCategories() {
      this.lastOperation = BATCH_OPERATIONS_MAP.REMOVE

      const value = {
        categoryIds: this.selectedCategoryIds,
        op: BATCH_OPERATIONS_MAP.REMOVE,
      }

      this.$emit('submit:edit-categories', { value })
    },
    closeEditCategoriesModal() {
      this.$emit('close:edit-categories', { type: null })
      this.selectedCategoryIds = []
    },
    closeAlertModal() {
      this.$emit('close:edit-categories-alert-modal')
      this.selectedCategoryIds = []
    },
    onClickCreateNewCategory() {
      this.$emit('open:create-category-modal')
    },
  },
}
</script>
