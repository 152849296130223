<template>
  <div class="rfileupload">
    <label
      v-if="label"
      :for="randomId"
    >{{ label }}</label>
    <input
      :id="randomId"
      ref="fileInput"
      type="file"
      hidden
      :accept="acceptTypes"
      @change="onUploadFile"
    >
    <v-text-field
      v-model="filename"
      :placeholder="placeholder"
      solo
      outlined
      dense
      flat
      readonly
      class="my-1"
      @click="$refs.fileInput.click()"
    />
    <r-button
      :label="selectFileLabel"
      outlined
      @click="$refs.fileInput.click()"
    />
    <r-button
      :label="uploadButtonLabel"
      class="ml-2"
      :disabled="uploadDisabled"
      @click="onClickUpload"
    />
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import RButton from '@/components/library/atoms/RButton'

export default {
  name: 'RFileUpload',
  components: {
    RButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    filetypes: {
      type: Array,
      default: () => ([]),
    },
    readfile: {
      type: Boolean,
      default: true,
    },
    selectFileLabel: {
      type: String,
      default: 'Select file',
    },
    uploadButtonLabel: {
      type: String,
      default: 'Upload',
    },
  },
  data: () => ({
    filename: '',
    uploadDisabled: true,
    file: null,
    fileData: null,
  }),
  computed: {
    randomId() {
      return `rfileupload-input-${uuid()}`
    },
    acceptTypes() {
      return this.$props.filetypes.join(',')
    },
  },
  methods: {
    getFile(e) {
      const { files } = e.target
      if (!files?.length) {
        return null
      }
      const [file] = files
      return file
    },
    onUploadFile(e) {
      const file = this.getFile(e)

      if (!file) {
        return
      }

      this.$data.file = file
      this.$data.filename = file.name
      this.$emit('file', file)

      if (!this.$props.readfile) {
        this.uploadDisabled = false
        return
      }

      const fileReader = new FileReader()

      fileReader.addEventListener('load', (loadEvent) => {
        const data = loadEvent.target.result
        this.$emit('data', data)
        this.$data.fileData = data
        this.uploadDisabled = false
      })
      fileReader.addEventListener('error', (fileReadError) => {
        this.$emit('error', fileReadError)
      })

      fileReader.readAsText(file)
    },
    onClickUpload() {
      const { file, fileData } = this.$data
      this.$emit('upload', { file, data: fileData })
      this.$data.filename = ''
      this.$data.uploadDisabled = true
      this.$data.file = null
      this.$data.fileData = null
      this.$refs.fileInput.value = null
    },
  },
}
</script>

<style scoped>
/deep/ .v-text-field__details {
  display: none;
}
</style>
