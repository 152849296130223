var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtablepopup"
  }, [_c('ul', {
    staticClass: "rtablepopup--list pa-2"
  }, _vm._l(_vm.items, function (item) {
    return _c('li', {
      key: `rtablepopup--item-${item.key}`,
      staticClass: "rtablepopup--item",
      on: {
        "click": function ($event) {
          return _vm.$emit('click:item', item);
        }
      }
    }, [_c('a', {
      staticClass: "rtablepopup--button"
    }, [_c('v-icon', {
      staticClass: "pr-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(item.label) + " ")], 1)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }