<template>
  <v-row
    class="ma-0 mr-4 datalisting--buttonsrow"
    justify="end"
    style="width:100%"
  >
    <r-button
      outlined
      class="mr-2 datalisting--add-to-project"
      label="Add to project"
      :disabled="batchButtonsDisabled"
      @click="toggleAddToProjectsModal()"
    />
    <r-button
      v-if="selectedTab === 0"
      outlined
      class="datalisting--createproducts mr-2"
      :label="$t('urlsTable.batchActions.createProduct.title')"
      :disabled="batchButtonsDisabled"
      @click="toggleCreateProductModal"
    />
    <r-button
      outlined
      label="Delete"
      :disabled="batchButtonsDisabled"
      @click="toggleDeleteConfirmationModal"
    />
    <v-divider
      v-if="selectedTab < 2"
      vertical
      class="mx-4"
    />
    <r-button
      v-if="selectedTab < 2"
      outlined
      class="mr-2 datalisting--editCategories"
      :label="$t('urlsTable.batchActions.editCategories.title')"
      :disabled="batchButtonsDisabled"
      @click="toggleEditCategoriesModal"
    />
    <r-button
      v-if="selectedTab < 2"
      outlined
      class="mr-2 datalisting--editbrands"
      :label="$t('urlsTable.batchActions.editBrands.title')"
      :disabled="batchButtonsDisabled"
      @click="toggleEditBrandsModal"
    />
    <r-button
      v-if="selectedTab < 2"
      outlined
      class="mr-2 datalisting--edittags"
      :label="$t('urlsTable.batchActions.editTags.title')"
      :disabled="batchButtonsDisabled"
      @click="toggleEditTagsModal"
    />
    <r-button
      v-if="selectedTab === 1"
      outlined
      class="mr-2 datalisting--editproducts"
      label="Edit Products"
      :disabled="batchButtonsDisabled"
      @click="toggleEditProductsModal"
    />
    <v-divider
      v-if="showLinkToBCRButtons"
      vertical
      class="mr-4 ml-2"
    />
    <r-button
      v-if="showLinkToBCRButtons"
      outlined
      class="datalisting--createbcrlink mr-3"
      :label="$t('urlsTable.batchActions.createBCRLink.title')"
      :disabled="batchButtonsDisabled"
      @click="toggleConnectToBCRModal"
    />
    <r-button
      v-if="showLinkToBCRButtons"
      outlined
      class="datalisting--createbcrquery"
      :label="$t('urlsTable.batchActions.createBCRQuery.title')"
      :disabled="batchButtonsDisabled"
      @click="toggleCreateBCRQueryModal"
    />
  </v-row>
</template>

<script>
import { DATA_TYPES } from '@/utils/constants'
import RButton from '@/components/library/atoms/RButton'

export default {
  name: 'DataListingBatchActions',
  components: {
    RButton,
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0,
    },
    allItemsSelected: {
      type: Boolean,
      default: false,
    },
    hasSelectedItems: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      required: true,
    },
    clientCanLinkToBCR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DATA_TYPES,
    }
  },
  computed: {
    batchButtonsDisabled() {
      return !(this.allItemsSelected || this.hasSelectedItems)
    },
    showLinkToBCRButtons() {
      return this.selectedTab < 2 && this.clientCanLinkToBCR
    },
  },
  methods: {
    toggleAddToProjectsModal() {
      this.$emit('toggle:add-to-project', { type: this.dataType })
    },
    toggleCreateProductModal() {
      this.$emit('toggle:create-product', { type: this.dataType })
    },
    toggleEditCategoriesModal() {
      this.$emit('toggle:edit-categories', { type: this.dataType })
    },
    toggleEditBrandsModal() {
      this.$emit('toggle:edit-brands')
    },
    toggleEditTagsModal() {
      this.$emit('toggle:edit-tags', { type: this.dataType })
    },
    toggleDeleteConfirmationModal() {
      this.$emit('toggle:delete', { type: this.dataType })
    },
    toggleConnectToBCRModal() {
      const value = { dataType: this.dataType }
      this.$emit('toggle:create-bcr-link', { value })
    },
    toggleCreateBCRQueryModal() {
      const value = { dataType: this.dataType }
      this.$emit('toggle:create-bcr-query', { value })
    },
    toggleEditProductsModal() {
      this.$emit('toggle:edit-products', { type: this.dataType })
    },
  },
}
</script>

<style scoped>
.datalisting--buttonsrow .v-btn--outlined {
  border-color: #E4E4E4;
}
</style>
