var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mx-0 mt-0 rtablepagination"
  }, [_c('v-col', {
    staticClass: "px-0"
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, _vm._l(_vm.availablePageSizes, function (thisPageSize) {
    return _c('r-chip', {
      key: `rtablepagination--chip--${thisPageSize}`,
      staticClass: "mr-2",
      class: `rtablepagination--pagesize-${thisPageSize}`,
      attrs: {
        "active": _vm.pageSize === thisPageSize,
        "label": `${thisPageSize}`,
        "circle": "",
        "light": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickPageSize(thisPageSize);
        }
      }
    });
  }), 1)], 1), _c('v-col', {
    staticClass: "px-0"
  }, [_c('r-pagination', {
    attrs: {
      "page": _vm.currentPage,
      "total-pages": Math.ceil(_vm.totalRows / _vm.pageSize),
      "justify": "end",
      "action-items": _vm.actionItems
    },
    on: {
      "click:page": _vm.onClickPage,
      "open:pagination-action-item": _vm.onClickActionItem
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }