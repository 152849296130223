<template>
  <v-list-item-avatar
    tile
    :size="size"
    color="grey"
    class="ma-0"
  >
    <div class="d-flex justify-start">
      <v-img
        v-for="id in allowedIds"
        :key="`image-${id}`"
        :src="image(id)"
        :height="getHeight"
        :max-width="getWidth"
        @error="loadingFailed = true"
      >
        <template
          v-if="!loadingFailed"
          v-slot:placeholder
        >
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
    </div>
  </v-list-item-avatar>
</template>

<script>
import { DASHBOARD_TYPES } from '@/utils/constants'

const API_ROOT = process.env.VUE_APP_API_ROOT_V3 || 'http://localhost:3050'
const MAX_AMOUNT_OF_IMAGES_TO_SHOW = 3

export default {
  name: 'RDataImage',
  props: {
    type: {
      type: String,
      required: false,
      default: 'product',
      validator: (val) => Object.values(DASHBOARD_TYPES).includes(val),
    },
    ids: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      loadingFailed: false,
      MAX_AMOUNT_OF_IMAGES_TO_SHOW,
    }
  },
  computed: {
    allowedIds() {
      return this.ids.slice(0, this.MAX_AMOUNT_OF_IMAGES_TO_SHOW)
    },
    getHeight() {
      return this.size
    },
    getWidth() {
      const totalImages = this.allowedIds.length

      return this.size / totalImages
    },
  },
  methods: {
    image(id) {
      if (this.$props.type === DASHBOARD_TYPES.URL) {
        return `${API_ROOT}/v3/urls/${id}/image`
      }
      return `${id}`
    },
  },
}
</script>

<style scoped>
/deep/ .v-image__image--cover {
  background-size: contain;
}
</style>
