import store from '../store/index'

const STORE_ITEMS_MAPPER = {
  categories: { items: 'categoriesSummary', total: 'totalCategories', page: 'summaryPage' },
  tags: { items: 'tagsSummary', total: 'totalTags', page: 'summaryPage' },
  brands: { items: 'brandsSummary', total: 'totalBrands', page: 'summaryPage' },
  products: { items: 'productsSummary', total: 'totalProducts', page: 'summaryPage' },
  projects: { items: 'projects', total: 'totalRecords', page: 'page' },
  admin: { items: 'domains', total: 'totalDomains', page: 'pageDomains' },
  urls: { items: 'urlsSummary', total: 'totalUrlsSummary', page: 'pageSummary' },
}
// eslint-disable-next-line import/prefer-default-export
export const generateParams = (module) => {
  const currentStore = store.state[module]
  const items = currentStore[STORE_ITEMS_MAPPER[module].items]
  const total = currentStore[STORE_ITEMS_MAPPER[module].total]
  const page = currentStore[STORE_ITEMS_MAPPER[module].page]
  if (items.length === total) {
    return undefined
  }
  return { page: parseInt(page, 10) + 1 }
}
