var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rdataproductcard box-shadow-custom",
    attrs: {
      "max-width": "240"
    }
  }, [_c('div', {
    staticClass: "pa-4"
  }, [_c('div', {
    staticClass: "rdataproductcard--header d-flex mb-2"
  }, [_c('span', {
    staticClass: "rdataproductcard--title text-truncate",
    attrs: {
      "title": _vm.name
    }
  }, [_vm._v(_vm._s(_vm.name))]), _c('v-icon', {
    staticClass: "rdataproductcard--icon ml-2"
  }, [_vm._v(" " + _vm._s(_vm.DASHBOARD_TYPE_TO_ICON[_vm.DASHBOARD_TYPES.PRODUCT]) + " ")])], 1), _c('div', {
    staticClass: "rdataproductcard--body d-flex"
  }, [_c('r-data-image', {
    staticClass: "rdataproductcard--image mr-2",
    attrs: {
      "type": _vm.DASHBOARD_TYPES.PRODUCT,
      "size": 42,
      "ids": _vm.ids
    }
  }), _c('div', {
    staticClass: "flex-expand"
  }, [_c('r-rating', {
    staticClass: "rdataurlcard--rating mb-2",
    attrs: {
      "value": _vm.rating
    }
  }), _c('div', {
    staticClass: "d-flex justify-space-between align-end"
  }, [_c('div', {
    staticClass: "rdataproductcard--numurls mb-0"
  }, [_vm._v(" " + _vm._s(_vm.numUrls !== 1 ? _vm.$t('resources.urlCount', {
    numUrls: _vm.numUrls
  }) : '1' + _vm.$t('resources.url')) + " ")]), _vm.showBCRStatusButton ? _c('r-button', {
    staticClass: "bcrlinkstatus--button",
    attrs: {
      "color": "grey",
      "label": "",
      "outlined": "",
      "icon": "cloud"
    },
    on: {
      "click": _vm.onClickBCRLinkStatus
    }
  }) : _vm._e()], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }