<template>
  <div class="rbox">
    <p
      v-if="title"
      class="rbox--title mb-0"
    >
      {{ title }}
    </p>
    <div class="rbox--content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RBox',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.rbox {
  border: 1px solid var(--r-border-color);
  border-radius: var(--r-border-radius);
  box-shadow: var(--r-box-shadow);
}

.rbox--title {
  font-weight: 400;
  font-size: var(--r-font-size);
  padding: 24px 40px;
  border-bottom: 1px solid var(--r-border-color);
}

.rbox--content {
  padding: 40px;
}
</style>
