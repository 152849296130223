var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rowactions"
  }, [_vm.tab === 'Products' || _vm.tab === 'Urls' ? _c('v-menu', {
    staticClass: "rowactions--dropdown brands",
    attrs: {
      "offset-y": "",
      "min-width": 240,
      "offset-overflow": true,
      "close-on-content-click": false,
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs,
          value = _ref.value;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rowactions--brand-btn mr-3",
          class: {
            'active': value
          },
          attrs: {
            "icon": "",
            "title": "Brands"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-alpha-b-circle")])], 1)];
      }
    }], null, false, 1960732115)
  }, [_c('div', {
    staticClass: "rowactions--dropdown-content",
    attrs: {
      "height": "400"
    }
  }, [_c('div', {
    staticClass: "rowactions--dropdown-title d-flex justify-center align-center mx-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('rowActions.editBrands')) + " ")]), _c('brands-dropdown', {
    attrs: {
      "always-open": true,
      "preselected-brands": _vm.item.brands,
      "disabled": _vm.fetchingEditBrandsUrls || _vm.fetchingEditBrandsProducts,
      "show-autogenerated-brands-filter": true
    },
    on: {
      "change": _vm.onCheckBrand,
      "open:create-brand-modal": _vm.onClickCreateNewBrand
    }
  })], 1)]) : _vm._e(), _vm.tab === 'Products' || _vm.tab === 'Urls' ? _c('v-menu', {
    staticClass: "rowactions--dropdown categories",
    attrs: {
      "offset-y": "",
      "min-width": 240,
      "offset-overflow": true,
      "close-on-content-click": false,
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs,
          value = _ref2.value;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rowactions--category-btn mr-3",
          class: {
            'active': value
          },
          attrs: {
            "icon": "",
            "title": "Categories"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-view-grid")])], 1)];
      }
    }], null, false, 2721999802)
  }, [_c('div', {
    staticClass: "rowactions--dropdown-content"
  }, [_c('div', {
    staticClass: "rowactions--dropdown-title d-flex justify-center align-center mx-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('rowActions.editCategories')) + " ")]), _c('categories-dropdown', {
    attrs: {
      "always-open": true,
      "preselected-categories": _vm.item.categories,
      "disabled": _vm.fetchingEditCategoriesUrls || _vm.fetchingEditCategoriesProducts,
      "show-autogenerated-categories-filter": true
    },
    on: {
      "change": _vm.onCheckCategory,
      "open:create-category-modal": _vm.onClickCreateNewCategory
    }
  })], 1)]) : _vm._e(), _vm.tab === 'Products' || _vm.tab === 'Urls' ? _c('v-menu', {
    staticClass: "rowactions--dropdown tags",
    attrs: {
      "offset-y": "",
      "min-width": 240,
      "offset-overflow": true,
      "close-on-content-click": false,
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs,
          value = _ref3.value;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rowactions--tag-btn mr-3",
          class: {
            'active': value
          },
          attrs: {
            "icon": "",
            "title": "Tags"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-tag")])], 1)];
      }
    }], null, false, 2722960005)
  }, [_c('div', {
    staticClass: "rowactions--dropdown-content",
    staticStyle: {
      "height": "398px"
    }
  }, [_c('div', {
    staticClass: "rowactions--dropdown-title d-flex justify-center align-center mx-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('rowActions.editTags')) + " ")]), _c('tags-dropdown', {
    attrs: {
      "always-open": true,
      "preselected-tags": _vm.item.tags,
      "disabled": _vm.fetchingEditTagsUrls || _vm.fetchingEditTags
    },
    on: {
      "change": _vm.onSelectTag,
      "open:create-tag-modal": _vm.onClickCreateNewTag
    }
  })], 1)]) : _vm._e(), _vm.showBCRLinkButton ? _c('v-btn', {
    staticClass: "rowactions--bcr-link-btn mr-3",
    attrs: {
      "icon": "",
      "title": "BCR Connection"
    },
    on: {
      "click": _vm.onClickCreateBCRLink
    }
  }, [_c('v-icon', [_vm._v("cloud")])], 1) : _vm._e(), _vm.showDownloadReviewsButton ? _c('v-btn', {
    staticClass: "rowactions--download-review-btn mr-3",
    attrs: {
      "icon": "",
      "title": "Download reviews"
    },
    on: {
      "click": _vm.onClickDownloadReviews
    }
  }, [_c('v-icon', [_vm._v("download")])], 1) : _vm._e(), _c('v-btn', {
    staticClass: "rowactions--delete-btn mr-3",
    attrs: {
      "icon": "",
      "title": "Delete"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDeleteModal();
      }
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }