var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "recentdatacards"
  }, [_vm.fetchRecentDataError ? _c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchRecentDataError]
    }
  }) : _vm.fetchingRecentData ? _c('v-row', {
    staticClass: "ma-0"
  }, _vm._l(4, function (index) {
    return _c('v-col', {
      key: `recentdatacards--loading--${index}`,
      staticClass: "pa-0 recentdatacards--cell px-2"
    }, [_c('v-skeleton-loader', {
      staticClass: "elevation-0 recentdatacards--loader",
      attrs: {
        "type": "list-item, list-item-avatar-three-line, card-heading",
        "height": "144"
      }
    })], 1);
  }), 1) : _c('v-row', {
    staticClass: "ma-0"
  }, _vm._l(_vm.recentData, function (item) {
    return _c('v-col', {
      key: `recentdatacards--${item._id}`,
      staticClass: "pa-0 recentdatacards--cell px-2",
      attrs: {
        "cols": "3"
      }
    }, [_c('r-data-card', {
      attrs: {
        "id": item._id,
        "type": item.type,
        "name": item.name,
        "url": item.url,
        "hostname": item.hostname,
        "created": item.created,
        "rating": item.averageRating,
        "num-reviews": item.numReviews,
        "last-update": item.lastUpdate,
        "num-urls": item.numUrls,
        "bcr-link-job-id": item.bcrLinkJobId,
        "bcr-link-job-ids": item.bcrLinkJobIds
      },
      on: {
        "click:bcrlink-status": function ($event) {
          return _vm.$emit('click:bcrlink-status', $event);
        }
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }