<template>
  <div class="recentdatacards">
    <r-error-message
      v-if="fetchRecentDataError"
      :errors="[fetchRecentDataError]"
    />
    <v-row
      v-else-if="fetchingRecentData"
      class="ma-0"
    >
      <v-col
        v-for="index in 4"
        :key="`recentdatacards--loading--${index}`"
        class="pa-0 recentdatacards--cell px-2"
      >
        <v-skeleton-loader
          class="elevation-0 recentdatacards--loader"
          type="list-item, list-item-avatar-three-line, card-heading"
          height="144"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="ma-0"
    >
      <v-col
        v-for="item in recentData"
        :key="`recentdatacards--${item._id}`"
        class="pa-0 recentdatacards--cell px-2"
        cols="3"
      >
        <r-data-card
          :id="item._id"
          :type="item.type"
          :name="item.name"
          :url="item.url"
          :hostname="item.hostname"
          :created="item.created"
          :rating="item.averageRating"
          :num-reviews="item.numReviews"
          :last-update="item.lastUpdate"
          :num-urls="item.numUrls"
          :bcr-link-job-id="item.bcrLinkJobId"
          :bcr-link-job-ids="item.bcrLinkJobIds"
          @click:bcrlink-status="$emit('click:bcrlink-status', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RDataCard from '@/components/library/molecules/RDataCard'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { DASHBOARD_TYPES } from '@/utils/constants'

export default {
  name: 'RecentDataCards',
  components: {
    RDataCard,
    RErrorMessage,
  },
  data: () => ({
    DASHBOARD_TYPES,
  }),
  computed: {
    ...mapState('recentdata', [
      'fetchingRecentData',
      'fetchRecentDataError',
      'recentData',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
  },
  watch: {
    selectedProjectId() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('recentdata', [
      'fetchRecentData',
    ]),
    fetchData() {
      if (this.selectedProjectId) {
        this.fetchRecentData({ projectId: this.selectedProjectId })
      } else {
        this.fetchRecentData({ })
      }
    },
  },
}
</script>

<style scoped>
.recentdatacards--loader {
  border: 1px solid var(--r-border-color);
}
.recentdatacards--cell:first-child {
  padding-left: 0 !important;
  border-color: green;
}
.recentdatacards--cell:last-child {
  padding-right: 0 !important;
  border-color: red;
}
</style>
