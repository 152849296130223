var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 mr-4 datalisting--buttonsrow",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "justify": "end"
    }
  }, [_c('r-button', {
    staticClass: "mr-2 datalisting--add-to-project",
    attrs: {
      "outlined": "",
      "label": "Add to project",
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.toggleAddToProjectsModal();
      }
    }
  }), _vm.selectedTab === 0 ? _c('r-button', {
    staticClass: "datalisting--createproducts mr-2",
    attrs: {
      "outlined": "",
      "label": _vm.$t('urlsTable.batchActions.createProduct.title'),
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleCreateProductModal
    }
  }) : _vm._e(), _c('r-button', {
    attrs: {
      "outlined": "",
      "label": "Delete",
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleDeleteConfirmationModal
    }
  }), _vm.selectedTab < 2 ? _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "vertical": ""
    }
  }) : _vm._e(), _vm.selectedTab < 2 ? _c('r-button', {
    staticClass: "mr-2 datalisting--editCategories",
    attrs: {
      "outlined": "",
      "label": _vm.$t('urlsTable.batchActions.editCategories.title'),
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleEditCategoriesModal
    }
  }) : _vm._e(), _vm.selectedTab < 2 ? _c('r-button', {
    staticClass: "mr-2 datalisting--editbrands",
    attrs: {
      "outlined": "",
      "label": _vm.$t('urlsTable.batchActions.editBrands.title'),
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleEditBrandsModal
    }
  }) : _vm._e(), _vm.selectedTab < 2 ? _c('r-button', {
    staticClass: "mr-2 datalisting--edittags",
    attrs: {
      "outlined": "",
      "label": _vm.$t('urlsTable.batchActions.editTags.title'),
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleEditTagsModal
    }
  }) : _vm._e(), _vm.selectedTab === 1 ? _c('r-button', {
    staticClass: "mr-2 datalisting--editproducts",
    attrs: {
      "outlined": "",
      "label": "Edit Products",
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleEditProductsModal
    }
  }) : _vm._e(), _vm.showLinkToBCRButtons ? _c('v-divider', {
    staticClass: "mr-4 ml-2",
    attrs: {
      "vertical": ""
    }
  }) : _vm._e(), _vm.showLinkToBCRButtons ? _c('r-button', {
    staticClass: "datalisting--createbcrlink mr-3",
    attrs: {
      "outlined": "",
      "label": _vm.$t('urlsTable.batchActions.createBCRLink.title'),
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleConnectToBCRModal
    }
  }) : _vm._e(), _vm.showLinkToBCRButtons ? _c('r-button', {
    staticClass: "datalisting--createbcrquery",
    attrs: {
      "outlined": "",
      "label": _vm.$t('urlsTable.batchActions.createBCRQuery.title'),
      "disabled": _vm.batchButtonsDisabled
    },
    on: {
      "click": _vm.toggleCreateBCRQueryModal
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }