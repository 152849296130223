var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "createbrand"
  }, [_vm.showCreateBrandModal ? _c('r-modal', {
    attrs: {
      "title": _vm.$t('createBrand.label'),
      "close-action-label": "Cancel",
      "action-label": _vm.$t('createBrand.label'),
      "fetching": _vm.fetchingCreateBrand,
      "action-disabled": !(_vm.name && _vm.color)
    },
    on: {
      "close": _vm.onClickCancel,
      "submit": _vm.onClickSave
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-form', {
          ref: "form",
          staticClass: "createbrandform mt-5",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('v-row', {
          staticClass: "mt-5",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('r-text-field', {
          staticClass: "input-text createbrandform--name",
          attrs: {
            "value": _vm.name,
            "label": _vm.$t('createBrand.fields.name'),
            "placeholder": "Lorem ipsum",
            "rules": [_vm.rules.required]
          },
          on: {
            "change": _vm.onNameInputChange
          }
        })], 1)], 1), _c('span', [_vm._v(" " + _vm._s(_vm.$t('createBrand.fields.color')) + " ")]), _c('color-picker', {
          attrs: {
            "show-color-picker": _vm.showColorPicker
          },
          on: {
            "select:custom-color": _vm.onSelectCustomColor
          }
        }), _c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "createbrandform--colors"
        }, [_vm._l(_vm.colors, function (color, i) {
          return _c('div', {
            key: i,
            staticClass: "createbrandform--color",
            class: {
              'selected': _vm.$data.color === color && !_vm.showColorPicker
            },
            style: 'background:' + color,
            attrs: {
              "colors": _vm.colors,
              "color": color
            },
            on: {
              "click": function ($event) {
                return _vm.onSelectColor(color);
              }
            }
          });
        }), _c('div', {
          staticClass: "createbrandform--color-picker",
          class: {
            'selected': _vm.showColorPicker
          },
          attrs: {
            "color": _vm.color
          },
          on: {
            "click": _vm.onSelectColorPicker
          }
        })], 2)], 1)], 1), _c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchCreateBrandError]
          }
        })];
      },
      proxy: true
    }], null, false, 1421701228)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }