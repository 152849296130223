var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 categoriesdropdown d-flex justify-center"
  }, [_c('r-select', {
    key: "categoriesdropdown--rselect",
    staticClass: "categoriesdropdown--select",
    attrs: {
      "items": _vm.items,
      "show-search": true,
      "search-placeholder": _vm.$t('categoriesDropdown.searchPlaceHolderText'),
      "allow-empty": false,
      "multiple": true,
      "infinite": true,
      "disabled": _vm.disabled,
      "always-open": _vm.alwaysOpen
    },
    on: {
      "change": _vm.onSelectCategory,
      "click:prepend-item": _vm.onClickCreateNewCategory,
      "on:get-more": _vm.onGetMoreItems,
      "change:search": _vm.onSearchChange
    },
    scopedSlots: _vm._u([_vm.showAutogeneratedCategoriesFilter ? {
      key: "filters",
      fn: function () {
        return [_c('div', {
          staticClass: "categoriesdropdown--filters pl-2"
        }, [_c('r-switch', {
          attrs: {
            "value": _vm.showAutogeneratedCategories,
            "disabled": _vm.disabled
          },
          on: {
            "change": _vm.onShowAutogenerateCategoriesFilterChange
          }
        }), _c('label', {
          staticClass: "px-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('categoriesDropdown.filters.showAutomaticallyCreated')) + " ")])], 1)];
      },
      proxy: true
    } : null, {
      key: "item",
      fn: function (slotProps) {
        return [_c('div', [_c('r-badge', {
          attrs: {
            "name": slotProps.item.label,
            "color": slotProps.item.color,
            "prepend-icon": "mdi-view-grid"
          }
        })], 1)];
      }
    }, {
      key: "prepend-item",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex rselectitem"
        }, [_c('button', {
          staticClass: "rcategoriesdropdown--create-category-button rselectitem--row",
          on: {
            "click": _vm.onClickCreateNewCategory
          }
        }, [_c('v-icon', {
          staticClass: "pl-2",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" add ")]), _c('span', [_vm._v(_vm._s(_vm.$t('categoriesDropdown.create')))])], 1)])];
      },
      proxy: true
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }