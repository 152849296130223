var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 productsdropdown d-flex justify-center"
  }, [_c('r-select', {
    staticClass: "projectsdropdown--select",
    attrs: {
      "infinite": true,
      "multiple": "",
      "items": _vm.items
    },
    on: {
      "change": _vm.onSelectProduct,
      "on:get-more": _vm.onGetMoreItems,
      "change:search": _vm.onSearchChange
    },
    scopedSlots: _vm._u([_vm.selectLabel ? {
      key: "label",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.selectLabel))])];
      },
      proxy: true
    } : null], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }