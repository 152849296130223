<template>
  <div class="rtablepopup">
    <ul class="rtablepopup--list pa-2">
      <li
        v-for="item in items"
        :key="`rtablepopup--item-${item.key}`"
        class="rtablepopup--item"
        @click="$emit('click:item', item)"
      >
        <a class="rtablepopup--button">
          <v-icon
            class="pr-1"
            small
          >{{ item.icon }}</v-icon>
          {{ item.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RTablePopup',
  props: {
    items: Array,
  },
}
</script>

<style scoped>
.rtablepopup {
  position: absolute;
  right: 0;
  top: 80px;
  background: var(--r-light-grey);
}
.rtablepopup--list {
  list-style-type: none;
  background: white;
  border: 1px solid  var(--r-border-color-popup);
  border-radius: var(--r-border-radius);
  margin: 0;
  padding: 0;
}
.rtablepopup--button {
  padding: 8px;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1px solid var(--r-border-color-popup);
  background: white;
  display: block;
  color: var(--r-text-color);
}
.rtablepopup--button:hover {
  background: var(--r-light-grey);
}
.rtablepopup--item:first-child .rtablepopup--button {
  border-top-left-radius: var(--r-border-radius);
  border-top-right-radius: var(--r-border-radius);
}
.rtablepopup--item:last-child .rtablepopup--button {
  border-bottom: 0;
}
</style>
