<template>
  <div class="createtag">
    <r-modal
      v-if="showCreateTagModal"
      :title="$t('createTag.label')"
      close-action-label="Cancel"
      :action-label="$t('createTag.label')"
      :fetching="fetchingCreateTag"
      :action-disabled="!(name && color)"
      @close="onClickCancel"
      @submit="onClickSave"
    >
      <template v-slot:content>
        <v-form
          ref="form"
          v-model="valid"
          class="createtagform mt-5"
        >
          <v-row
            class="mt-5"
            no-gutters
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <r-text-field
                class="input-text createtagform--name"
                :value="name"
                :label="$t('createTag.fields.name')"
                placeholder="Lorem ipsum"
                :rules="[rules.required]"
                @change="onNameInputChange"
              />
            </v-col>
          </v-row>
          <span>
            {{ $t('createTag.fields.color') }}
          </span>
          <color-picker
            :show-color-picker="showColorPicker"
            @select:custom-color="onSelectCustomColor"
          />
          <v-row
            no-gutters
          >
            <v-col
              class="createtagform--colors"
            >
              <div
                v-for="(color, i) in colors"
                :key="i"
                :colors="colors"
                :color="color"
                class="createtagform--color"
                :class="{'selected': $data.color === color && !showColorPicker}"
                :style="'background:'+color"
                @click="onSelectColor(color)"
              />
              <div
                class="createtagform--color-picker"
                :class="{'selected': showColorPicker}"
                :color="color"
                @click="onSelectColorPicker"
              />
            </v-col>
          </v-row>
        </v-form>
        <r-error-message
          :errors="[fetchCreateTagError]"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal.vue'
import RTextField from '@/components/library/molecules/RTextField'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { GROUPING_COLORS } from '@/utils/constants'
import { mapActions, mapState } from 'vuex'
import ColorPicker from './ColorPicker.vue'

export default {
  name: 'CreateTag',
  components: {
    RModal,
    RTextField,
    RErrorMessage,
    ColorPicker,
  },
  props: {
    showCreateTagModal: {
      type: Boolean,
      default: false,
    },
    fetchingCreateTag: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    name: '',
    color: '',
    valid: false,
    colors: GROUPING_COLORS,
    rules: {
      required: (v) => !!v || 'Tag name should be provided',
    },
    showColorPicker: false,
  }),
  computed: {
    ...mapState('tags', [
      'fetchCreateTagError',
    ]),
  },
  watch: {
    showCreateTagModal() {
      if (!this.showCreateTagModal) {
        this.$data.name = ''
        this.$data.color = ''
      }
    },
  },
  methods: {
    ...mapActions('tags', [
      'clearErrors',
    ]),
    onNameInputChange({ value }) {
      this.name = value?.trim()
    },
    setColor(color) {
      if (color !== this.color) {
        this.color = color
      }
    },
    onSelectColor(color) {
      this.showColorPicker = false
      this.setColor(color)
    },
    onSelectCustomColor({ color }) {
      this.setColor(color)
    },
    onSelectColorPicker() {
      this.showColorPicker = true
    },
    onClickSave() {
      this.clearErrors({ fetchCreateTagError: '' })
      this.$emit('submit:create-tag', { value: { name: this.name, color: this.color } })
    },
    onClickCancel() {
      this.clearErrors({ fetchCreateTagError: '' })
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
  .createtagform--colors {
    display: grid;
    margin: 15px auto;
    gap: 16px;
    grid-template-columns: repeat(9, 16px);
  }
  .createtagform--color {
    border-radius: 50%;
    height: 16px;
    cursor: pointer;
  }
  .createtagform--color.selected,
  .createtagform--color-picker.selected {
    border: 2px solid var(--primary-color);
  }
  .createtagform--color-picker {
    background-color: '#FFF';
    border: 1px solid var(--r-border-color);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }
  .createtagform--color-picker::after {
    border-bottom: 1px solid red;
    width: 50%;
    transform: rotate(135deg);
    content: " ";
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .rbutton {
    min-width: 150px !important;
  }
</style>
