var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showModal ? _c('r-modal', {
    staticClass: "addtoprojects",
    attrs: {
      "title": _vm.$t('urlsTable.batchActions.addToProject.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('global.actions.add'),
      "action-disabled": _vm.disableAction,
      "fetching": _vm.addingToProjects
    },
    on: {
      "submit": _vm.onAddToProjects,
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('r-error-message', {
          attrs: {
            "errors": [_vm.addingToProjectsError]
          }
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('r-select', {
          key: `autpdropdown--rselect`,
          staticClass: "autpdropdown--select",
          attrs: {
            "infinite": true,
            "items": _vm.items,
            "show-search": true,
            "allow-empty": false,
            "multiple": true
          },
          on: {
            "change": _vm.onSelectProjects,
            "on:get-more": _vm.onGetMoreItems,
            "change:search": _vm.onSearchChange
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function (slotProps) {
              return [_c('div', {
                staticClass: "px-3 pt-2"
              }, [_c('r-badge', {
                attrs: {
                  "name": slotProps.item.label,
                  "color": slotProps.item.color
                }
              })], 1)];
            }
          }], null, false, 3516466688)
        })], 1)];
      },
      proxy: true
    }], null, false, 665455342)
  }) : _vm._e(), _vm.showAlertModal ? _c('r-modal', {
    staticClass: "addtoprojects-alert-modal",
    attrs: {
      "title": _vm.$t('urlsTable.batchActions.addToProject.title')
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('urlsTable.batchActions.addToProject.successAddMessage')) + " ")])];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function () {
        return [_c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.backToData')
          },
          on: {
            "click": function () {
              return _vm.$emit('close:add-to-projects-alert-modal');
            }
          }
        })];
      },
      proxy: true
    }], null, false, 2815624014)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }