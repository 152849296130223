<template>
  <div
    class="rmarkasseen d-flex"
  >
    <a
      class="rmarkasseen--link"
      @click="$emit('on:mark-as-seen')"
    >
      <v-icon>
        visibility
      </v-icon>
    </a>
  </div>
</template>
<script>
export default {
  name: 'MarkAsSeen',
}
</script>
<style scoped>
.rmarkasseen a {
    text-decoration: none;
}

</style>
