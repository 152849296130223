<template>
  <div
    class="urlstable"
    :class="{ 'spacerowaction': hasPopupMenuOpen }"
  >
    <r-error-message
      v-if="fetchUrlsError || exportUrlsError"
      class="urlstable--errormessage"
      :errors="fetchUrlsError ?
        [fetchUrlsError, fetchingDownloadReviewsError] :
        [exportUrlsError]
      "
    />
    <r-table
      key="urlstable"
      class="urlstable--table"
      :columns="tableColumns"
      :rows="urls"
      :total-rows="totalUrls"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingUrls || exportingUrls"
      :action-items="actionItems"
      @change:page="({ value }) => setPage({ page: value })"
      @change:page-size="onChangePageSize"
      @change:page-size-all="onChangePageSizeAll"
      @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
      @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      @change:batch-select="(data) => $emit('change:batch-select', data)"
      @change:batch-select-all="(data) => $emit('change:batch-select-all')"
      @open:pagination-action-item="onClickActionItem"
    >
      <template v-slot:item.name="{ item }">
        <a
          :href="item.url"
          class="urlstable--name text-truncate"
          target="_blank"
        >{{ item.name }}</a>
      </template>
      <template v-slot:item.url="{ item }">
        <div class="urlstable--chip">
          <r-chip :label="item.url" />
        </div>
      </template>
      <template v-slot:item.brands="{ item }">
        <r-badge
          v-for="brand in (item.brands || []).slice(0, 1)"
          :key="brand.name"
          :name="brand.name"
          :color="brand.color"
        />
      </template>
      <template v-slot:item.averageRating="{ item }">
        <r-rating
          class="urlstable--rating"
          :value="item.averageRating"
        />
      </template>
      <template v-slot:item.numReviews="{ item }">
        {{ item.numReviews }}
      </template>
      <template v-slot:item.lastReviewed="{ item }">
        <span v-if="item.lastReviewed">{{ formatDate(item.lastReviewed) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.nonCanonicalUrls="{ item }">
        <div class="d-flex justify-center">
          <r-badge
            v-if="item.nonCanonicalUrls.length"
            :name="`${item.nonCanonicalUrls.length}`"
            :color="variantUrlsCountBadgeColor(item)"
            @click="toggleShowVariantsPopup($event, item)"
          />
        </div>
      </template>
      <template v-slot:item.actions="{item}">
        <row-actions
          tab="Urls"
          :item="item"
          @toggle:delete-modal="toggleDeleteOneUrlModal"
          @remove:brands="onRemoveBrands"
          @add:brands="onAddBrands"
          @open:create-brand-modal="onClickCreateNewBrand"
          @remove:categories="onRemoveCategories"
          @add:categories="onAddCategories"
          @open:create-category-modal="onClickCreateNewCategory"
          @remove:tags="onRemoveTags"
          @add:tags="onAddTags"
          @open:create-tag-modal="onClickCreateNewTag"
          @create:bcr-link="onClickBCRLink"
          @click:download-reviews="onClickDownloadReviews"
        />
      </template>
    </r-table>

    <variant-urls-popup
      v-if="showVariantUrlsPopup"
      :variant-urls="popupVariantsUrls"
      :menu-position="variantUrlsPopupPosition"
    />

    <r-modal
      v-if="showDeleteOneUrlModal"
      class="urlstable--deletemodal"
      :title="deleteModalLabels.title"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="deleteModalLabels.actionLabel"
      :alternate-action-label="deleteModalLabels.alternateActionLabel"
      :fetching="deleteUrlFetching"
      @submit="onDeleteUrl"
      @submit:alternate="onDeleteUrlFromProject"
      @close="toggleDeleteOneUrlModal"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          <label>{{ deleteModalLabels.content }}</label>
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showDeleteOneUrlAlertModal"
      class="urlstable--deleteconfirmationmodal"
      :title="(!deleteUrlError
        ? deleteModalLabels.title
        : $t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName })
      )"
      :action-label="$t('global.actions.confirm')"
      @submit="showDeleteOneUrlAlertModal = false"
    >
      <template v-slot:content>
        <div
          v-if="deleteUrlError"
          class="text-center"
        >
          {{ deleteUrlError }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ deleteModalLabels.deleteSuccess }}
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showExportUrlAlertModal"
      class="urlstable--deleteconfirmationmodal"
      :title="$t('commonTable.batchActions.export.exportTitle')"
      :action-label="$t('commonTable.batchActions.export.exportActionLabel')"
      :close-action-label="$t('global.actions.cancel')"
      @submit="onSubmitActionItem"
      @close="showExportUrlAlertModal = false"
    >
      <template v-slot:content>
        <p class="export-url-alert-modal-center">
          {{ $t('commonTable.batchActions.export.exportContent') }}
        </p>
        <p class="export-url-alert-modal-center">
          <span class="bold ">
            🔗 {{ $t('commonTable.batchActions.export.exportURLs', {urls:totalUrls}) }}
          </span>
          {{ $t('commonTable.batchActions.export.exportOutOf', {totalUrls:maxTotalUrls}) }}
        </p>
      </template>
    </r-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RModal from '@/components/library/organisms/RModal'
import RChip from '@/components/library/atoms/RChip'
import RRating from '@/components/library/atoms/RRating'
import RBadge from '@/components/library/atoms/RBadge'
import VariantUrlsPopup from '@/components/app/data/VariantUrlsPopup'
import debounce from '@/utils/debounce'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import {
  BATCH_OPERATIONS_MAP, COLORS, DATA_TYPES,
  RPOP_UP_TOP_MARGIN, PAGINATION_ACTION_ITEMS,
} from '@/utils/constants'
import RowActions from '@/components/app/data/RowActions'

export default {
  name: 'UrlsTable',
  components: {
    RowActions,
    RTable,
    RErrorMessage,
    RChip,
    RRating,
    RModal,
    RBadge,
    VariantUrlsPopup,
  },
  props: {
    search: String,
  },
  data: () => ({
    variantUrlsPopupPosition: null,
    popupVariantsUrls: null,
    selectedUrlId: null,
    tableColumns: [
      {
        key: 'name',
        label: 'URL',
        hideable: true,
        sortable: true,
        width: 270,
      },
      {
        key: 'url',
        label: 'Link',
        hidden: true,
        hideable: true,
        sortable: true,
        width: 250,
      },
      {
        key: 'brands',
        label: 'Brand',
        hideable: true,
        sortable: false,
        width: 130,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: true,
        sortable: true,
        width: 130,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: true,
        sortable: true,
        width: 100,
      },
      {
        key: 'lastReviewed',
        label: 'Last reviewed',
        hideable: true,
        sortable: true,
        width: 100,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        hidden: true,
        width: 100,
      },
      {
        key: 'nonCanonicalUrls',
        label: 'Variant Urls',
        hidden: true,
        sortable: false,
        hideable: true,
        width: 100,
      },
      {
        key: 'actions',
        label: 'Actions',
        hideable: false,
        width: 185,
      },
    ],
    debouncedFetchData: null,
    showDeleteOneUrlModal: false,
    showDeleteOneUrlAlertModal: false,
    showVariantUrlsPopup: false,
    deleteUrlId: null,
    deleteUrlName: null,
    deleteFromEverywhere: false,
    actionItems: [
      {
        id: PAGINATION_ACTION_ITEMS.EXPORT,
        name: 'commonTable.batchActions.export.exportTitle',
      },
    ],
    selectedActionItem: '',
    showExportUrlAlertModal: false,
  }),
  computed: {
    ...mapState('urls', [
      'urls',
      'fetchingUrls',
      'fetchUrlsError',
      'exportUrlsError',
      'exportingUrls',
      'totalUrls',
      'maxTotalUrls',
      'page',
      'pageSize',
      'sort',
      'sortDir',
      'deleteUrlFetching',
      'deleteUrlError',
      'fetchingDownloadReviewsError',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('filters', [
      'filterKey',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
    deleteModalLabels() {
      const { deleteFromEverywhere } = this.$data
      const dataTypeCount = this.deleteUrlName
      const dataTypeName = 'url'
      const deleteSuccess = deleteFromEverywhere
        ? this.$t('commonTable.batchActions.delete.deleteFromAllDataSuccessMessage', { dataTypeCount, dataTypeName })
        : this.$t('commonTable.batchActions.delete.deleteFromProjectSuccessMessage', { dataTypeCount, dataTypeName })

      if (this.selectedProjectId) {
        return {
          title: this.$t('commonTable.batchActions.delete.title'),
          content: this.$t('commonTable.batchActions.delete.descriptionProject', { dataTypeName }),
          actionLabel: this.$t('commonTable.batchActions.delete.deleteFromDataButton'),
          alternateActionLabel: this.$t('commonTable.batchActions.delete.deleteFromProjectButton'),
          deleteErrorMessage: this.$t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName }),
          deleteSuccess,
        }
      }
      return {
        title: this.$t('commonTable.batchActions.delete.title'),
        content: this.$t('commonTable.batchActions.delete.description', { dataTypeName }),
        actionLabel: this.$t('commonTable.batchActions.delete.deleteFromDataButton'),
        deleteErrorMessage: this.$t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName }),
        alternateActionLabel: '',
        deleteSuccess,
      }
    },
    hasPopupMenuOpen() {
      return this.showVariantUrlsPopup
    },
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ page: 1 })
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    selectedProjectId() {
      this.$data.debouncedFetchData()
    },
    filterKey() {
      this.$data.debouncedFetchData()
    },
    totalUrls() {
      if (this.totalUrls > this.maxTotalUrls) {
        this.updateMaxUrl(this.totalUrls)
      }
    },
    appliedFilters() {
      this.setPage({ page: 1 })
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
    document.addEventListener('mousedown', this.onClickPage)
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.onClickPage)
  },
  methods: {
    ...mapActions('urls', [
      'fetchUrls',
      'exportUrls',
      'setPage',
      'setPageSize',
      'setSort',
      'deleteOneUrl',
      'updateMaxUrl',
    ]),
    ...mapActions('brands', [
      'editBrandsUrls',
    ]),
    ...mapActions('categories', [
      'editCategoriesUrls',
    ]),
    ...mapActions('tags', [
      'editTagsUrls',
    ]),
    fetchData() {
      const {
        page, pageSize, sort, sortDir, selectedProjectId, appliedFilters,
      } = this
      let payload = {
        page, pageSize, ...appliedFilters, projectId: selectedProjectId,
      }
      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchUrls(payload)
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalUrls })
    },
    toggleDeleteOneUrlModal(eventObj) {
      if (eventObj) {
        const { item } = eventObj
        this.$data.deleteUrlId = item._id
        this.$data.deleteUrlName = item.url
      }
      this.$data.showDeleteOneUrlModal = !this.$data.showDeleteOneUrlModal
    },
    toggleShowVariantsPopup(e, item) {
      this.showVariantUrlsPopup = !this.showVariantUrlsPopup

      if (!this.showVariantUrlsPopup || !e) {
        this.variantUrlsPopupPosition = null
        this.popupVariantsUrls = null
        return
      }

      this.variantUrlsPopupPosition = this.getRowItemPosition.call(this, e)
      this.popupVariantsUrls = item.nonCanonicalUrls
      this.selectedUrlId = item._id
    },
    async onDeleteUrl() {
      this.$data.deleteFromEverywhere = true
      await this.deleteOneUrl({ urlId: this.$data.deleteUrlId })
      this.onAfterDelete()
    },
    async onDeleteUrlFromProject() {
      this.$data.deleteFromEverywhere = false
      await this.deleteOneUrl({ urlId: this.$data.deleteUrlId, projectId: this.selectedProjectId })
      this.onAfterDelete()
    },
    onAfterDelete() {
      this.$data.deleteUrlId = null
      this.$data.showDeleteOneUrlModal = false
      this.$data.showDeleteOneUrlAlertModal = true
      this.$data.debouncedFetchData()
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    async editUrlBrands({ op, urlId, brandIds }) {
      const payload = {
        brandIds,
        op,
        urlIds: [urlId],
      }

      await this.editBrandsUrls({ ...payload })
      await this.fetchData()
    },
    onRemoveBrands({ value: { id: urlId, brandIds } }) {
      this.editUrlBrands({
        op: BATCH_OPERATIONS_MAP.REMOVE,
        urlId,
        brandIds,
      })
    },
    onAddBrands({ value: { id: urlId, brandIds } }) {
      this.editUrlBrands({
        op: BATCH_OPERATIONS_MAP.ADD,
        urlId,
        brandIds,
      })
    },
    onClickCreateNewBrand() {
      this.$emit('open:create-brand-modal')
    },
    async editUrlCategories({ op, urlId, categoryIds }) {
      const payload = {
        categoryIds,
        op,
        urlIds: [urlId],
      }

      await this.editCategoriesUrls({ ...payload })
      await this.fetchData()
    },
    async editUrlTags({ op, urlId, tagIds }) {
      const payload = {
        tagIds,
        op,
        urlIds: [urlId],
      }

      await this.editTagsUrls({ ...payload })
      await this.fetchData()
    },
    onRemoveCategories({ value: { id: urlId, categoryIds } }) {
      this.editUrlCategories({
        op: BATCH_OPERATIONS_MAP.REMOVE,
        urlId,
        categoryIds,
      })
    },
    onAddCategories({ value: { id: urlId, categoryIds } }) {
      this.editUrlCategories({
        op: BATCH_OPERATIONS_MAP.ADD,
        urlId,
        categoryIds,
      })
    },
    onRemoveTags({ value: { id: urlId, tagIds } }) {
      this.editUrlTags({
        op: BATCH_OPERATIONS_MAP.REMOVE,
        urlId,
        tagIds,
      })
    },
    onAddTags({ value: { id: urlId, tagIds } }) {
      this.editUrlTags({
        op: BATCH_OPERATIONS_MAP.ADD,
        urlId,
        tagIds,
      })
    },
    onClickCreateNewTag() {
      this.$emit('open:create-tag-modal')
    },
    getRowItemPosition(event) {
      const POP_UP_HEIGHT = 400
      const bb = this.$el.getBoundingClientRect()
      const [item] = event.currentTarget.getClientRects()
      if (item?.y + POP_UP_HEIGHT > window.innerHeight) {
        this.$emit('set-popup-position', { isUp: true })

        return {
          x: event.clientX - bb.left,
          y: event.clientY
              - bb.top - POP_UP_HEIGHT
              - item?.height ?? 0, // workaround unit
          // test fails getClientRects  return empty array
        }
      }
      this.$emit('set-popup-position', { isUp: false })

      return {
        x: event.clientX - bb.left,
        y: event.clientY - bb.top + RPOP_UP_TOP_MARGIN + 2 * item?.height ?? 0, // workaround unit
        // test fails getClientRects  return empty array
      }
    },
    onClickCreateNewCategory() {
      this.$emit('open:create-category-modal')
    },
    onClickDownloadReviews({ value }) {
      const { id } = value

      return this.$emit('click:download-reviews', {
        value: { id, dataType: DATA_TYPES.URLS },
      })
    },
    onClickBCRLink({ item }) {
      const { _id, bcrLinkJobId } = item
      const value = { itemId: _id, dataType: 'urls' }

      if (!bcrLinkJobId) {
        return this.$emit('open:create-bcr-link', { value })
      }

      return this.$emit('open:bcr-link-status', { value })
    },
    variantUrlsCountBadgeColor(item) {
      return (this.showVariantUrlsPopup && item._id
        === this.selectedUrlId) ? COLORS.BLUE : COLORS.GREY
    },
    onClickActionItem(actionItem) {
      this.selectedActionItem = actionItem.id
      this.showExportUrlAlertModal = true
    },
    onSubmitActionItem() {
      const {
        sort, sortDir, selectedProjectId, appliedFilters,
      } = this
      let payload = {
        ...appliedFilters, projectId: selectedProjectId,
      }
      if (sort) {
        payload = { ...payload, sort, sortDir }
      }
      switch (this.selectedActionItem) {
        case PAGINATION_ACTION_ITEMS.EXPORT:
          this.showExportUrlAlertModal = false
          this.exportUrls(payload)
          break
        default:
          this.showExportUrlAlertModal = false
          break
      }
    },
  },
}
</script>

<style scoped>
.urlstable.spacerowaction {
  margin-bottom: 25rem;
}
.urlstable--table {
  width: 100%;
  position: relative;
  z-index: 1;
}

.urlstable--name {
  width: 238px; /* 270 minus padding */
  display: block;
}
.urlstable--chip {
  max-width: 250px;
}
.v-btn.v-btn--icon.active {
  color: var(--primary-color);
}
.export-url-alert-modal-center{
  text-align: center;
}
.bold{
  font-weight: bolder;
}
</style>
