<template>
  <v-row class="ma-0 tagsdropdown">
    <r-select
      key="tagsdropdown--rselect"
      class="tagsdropdown--select"
      :items="items"
      :show-search="true"
      :search-placeholder="$t('tagsDropdown.searchPlaceHolderText')"
      :allow-empty="false"
      :multiple="true"
      :disabled="disabled"
      :always-open="alwaysOpen"
      :infinite="true"
      @change="onSelectTag"
      @click:prepend-item="onClickCreateNewTag"
      @on:get-more="onGetMoreItems"
      @change:search="onSearchChange"
    >
      <template v-slot:item="slotProps">
        <div>
          <r-badge
            :name="slotProps.item.label"
            :color="slotProps.item.color"
          />
        </div>
      </template>
      <template v-slot:prepend-item>
        <div
          class="d-flex rselectitem"
        >
          <button
            class="rtagsdropdown--create-tag-button rselectitem--row"
            @click="onClickCreateNewTag"
          >
            <v-icon
              class="pl-2"
              dense
            >
              add
            </v-icon>
            <span> {{ $t('tagsDropdown.create') }}</span>
          </button>
        </div>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect'
import RBadge from '@/components/library/atoms/RBadge'
import { generateParams } from '@/utils/store'

export default {
  name: 'TagsDropdown',
  components: {
    RBadge,
    RSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    preselectedTags: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    searchValue: '',
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('tags', [
      'fetchingTagsSummary',
      'tagsSummary',
      'fetchTagsSummaryError',
    ]),
    items() {
      return this.mapTagsToDropdown({
        tags: this.tagsSummary,
      })
    },
  },
  beforeMount() {
    this.fetchTagsSummary({ projectId: this.selectedProjectId })
  },
  methods: {
    ...mapActions('tags', [
      'fetchTagsSummary',
    ]),
    onClickCreateNewTag() {
      this.$emit('open:create-tag-modal')
    },
    onClickCog({ value }) {
      this.$router.push(`/tags/${value}/edit`)
    },
    onSelectTag({ value: selectedItems }) {
      this.$emit('change', { value: selectedItems, items: this.items })
    },
    mapTagsToDropdown({ tags }) {
      const preselectedTagIds = this.preselectedTags.map(({ _id }) => _id)
      return tags.map(({ _id, name, color }) => ({
        label: name,
        value: _id,
        selected: preselectedTagIds.includes(_id),
        color,
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('tags')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchTagsSummary(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchTagsSummary({ projectId: this.selectedProjectId, search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
.tagsdropdown--select {
  width: 100%;
}
.rtagsdropdown--create-tag-button {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--r-border-color);
}
.rtagsdropdown--create-tag-button span {
  margin-left: 19px;
  font-size: 12px;
  color: rgba(63, 63, 63, 0.75);
}
.rselectitem .rselectitem--row:hover {
  background: var(--r-light-grey);
  cursor: pointer;
}
</style>
