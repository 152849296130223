<template>
  <div class="colorpicker">
    <v-color-picker
      v-if="showColorPicker"
      v-model="color"
      class="colorpicker-container"
      mode="hexa"
      canvas-height="200"
      hide-mode-switch
      dot-size="20px"
      :value="color"
    />
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    showColorPicker: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    color: '',
  }),
  watch: {
    color() {
      this.$emit('select:custom-color', { color: this.color })
    },
  },
}
</script>

<style scoped>
  .colorpicker /deep/ .colorpicker-container{
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    max-width: 520px !important;
  }
 .colorpicker /deep/ .v-color-picker {
    contain: none;
  }
 .colorpicker /deep/ .v-color-picker__canvas {
    width:  700px !important;
    border-radius: var(--r-border-radius);
  }
 .colorpicker /deep/ .v-color-picker__canvas > canvas {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }
 .colorpicker /deep/ .v-color-picker__canvas-dot {
    width: 14px !important;
    height: 14px !important;
    box-shadow: 0 0 1px 1px #ccc;
    background: var(--soft-grey-color) !important;
    cursor: pointer;
    transform: translate(10px 10px) !important;
  }
 .colorpicker /deep/ .v-color-picker__input span {
    display: none !important;
  }
  .colorpicker /deep/ .v-color-picker__input input {
    margin: 0;
  }
 .colorpicker /deep/ .v-color-picker__controls {
    margin-left: 12px;
    padding: 0 12px;
    width: 90%;
    justify-content: end;
  }
  .colorpicker /deep/ .v-slider,
  .colorpicker /deep/ .v-slider__thumb-container > .v-slider__thumb {
    cursor: pointer;
  }
 .colorpicker /deep/ .v-slider__thumb-container > .v-slider__thumb::before,
 .colorpicker /deep/ .v-slider__thumb-container > .v-slider__thumb::after {
    display: none;
  }
 .colorpicker /deep/ .v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__thumb {
    box-shadow: none;
  }
</style>
