<template>
  <div>
    <r-modal
      v-if="showCreateProductModal"
      class="create-product-modal"
      :title="$t('addProduct.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('addProduct.confirmationButton')"
      :fetching="fetchingCreateProduct"
      :action-completed="false"
      :action-disabled="!productName"
      @submit="onCreateProduct"
      @close="onClose"
    >
      <template v-slot:content>
        <div>
          <r-text-field
            :label="$t('addProduct.name.title')"
            :placeholder="$t('addProduct.name.placeholder')"
            @change="onProductNameChange"
          />
          <r-error-message :errors="[fetchCreateProductError]" />
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showCreateProductAlertModal"
      class="create-product-alert-modal"
      :title="$t('addProduct.createSuccessMessage', { productName })"
      :action-label="$t('global.actions.confirm')"
      :fetching="false"
      :action-completed="false"
      @submit="() => $emit('close:create-product-alert')"
    />
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import RTextField from '@/components/library/molecules/RTextField'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'

export default {
  name: 'BatchAddProduct',
  components: {
    RModal,
    RTextField,
    RErrorMessage,
  },
  props: {
    showCreateProductModal: {
      type: Boolean,
      default: false,
    },
    showCreateProductAlertModal: {
      type: Boolean,
      default: false,
    },
    fetchingCreateProduct: {
      type: Boolean,
      default: false,
    },
    fetchCreateProductError: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    productName: '',
  }),
  methods: {
    onProductNameChange({ value }) {
      this.productName = value?.trim()
    },
    onCreateProduct() {
      this.$emit('submit:create-product', { value: { name: this.productName } })
    },
    onClose() {
      this.productName = ''
      this.$emit('close:create-product')
    },
  },
}
</script>

<style scoped>
/deep/ .rtextfield {
  text-align: start;
}
</style>
