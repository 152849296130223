<template>
  <v-card
    class="rdataproductcard box-shadow-custom"
    max-width="240"
  >
    <div class="pa-4">
      <div class="rdataproductcard--header d-flex mb-2">
        <span
          class="rdataproductcard--title text-truncate"
          :title="name"
        >{{ name }}</span>
        <v-icon class="rdataproductcard--icon ml-2">
          {{ DASHBOARD_TYPE_TO_ICON[DASHBOARD_TYPES.PRODUCT] }}
        </v-icon>
      </div>

      <div class="rdataproductcard--body d-flex">
        <r-data-image
          class="rdataproductcard--image mr-2"
          :type="DASHBOARD_TYPES.PRODUCT"
          :size="42"
          :ids="ids"
        />
        <div class="flex-expand">
          <r-rating
            class="rdataurlcard--rating mb-2"
            :value="rating"
          />
          <div class="d-flex justify-space-between align-end">
            <div class="rdataproductcard--numurls mb-0">
              {{ numUrls !== 1 ?
                $t('resources.urlCount', { numUrls }) : '1' + $t('resources.url') }}
            </div>
            <r-button
              v-if="showBCRStatusButton"
              color="grey"
              class="bcrlinkstatus--button"
              label=""
              outlined
              icon="cloud"
              @click="onClickBCRLinkStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import RDataImage from '@/components/library/atoms/RDataImage'
import RRating from '@/components/library/atoms/RRating'
import RButton from '@/components/library/atoms/RButton'

import { DASHBOARD_TYPES, DATA_TYPES, RESOURCE_TYPE_TO_ICON } from '@/utils/constants'

export default {
  name: 'RDataProductCard',
  components: {
    RDataImage,
    RRating,
    RButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rating: {
      type: Number,
      default: 0,
    },
    numUrls: {
      type: Number,
      default: 0,
    },
    bcrLinkJobIds: {
      type: Array,
      required: false,
    },
    clientCanLinkToBCR: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    DASHBOARD_TYPES,
    DASHBOARD_TYPE_TO_ICON: RESOURCE_TYPE_TO_ICON,
  }),
  computed: {
    ids() {
      return [this.$props.id]
    },
    hasBCRLinkJobId() {
      return this.bcrLinkJobIds?.length > 0
    },
    showBCRStatusButton() {
      return this.hasBCRLinkJobId && this.clientCanLinkToBCR
    },
  },
  methods: {
    onClickBCRLinkStatus() {
      const value = { itemId: this.$props.id, dataType: DATA_TYPES.PRODUCTS }
      this.$emit('click:bcrlink-status', { value })
    },
  },
}
</script>

<style scoped>
.rdataproductcard--title {
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
}
.rdataproductcard--icon {
  flex: 0 0;
}
/deep/ .rdataproductcard--footer .v-icon {
  margin-top: -2px;
}
.rdataproductcard--body p {
  font-size: 12px;
}
.bcrlinkstatus--button {
  border: none;
  padding: 0 !important;
  min-height: 10px !important;
}
.flex-expand {
  flex: 1;
}
</style>
