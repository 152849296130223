var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item-avatar', {
    staticClass: "ma-0",
    attrs: {
      "tile": "",
      "size": _vm.size,
      "color": "grey"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-start"
  }, _vm._l(_vm.allowedIds, function (id) {
    return _c('v-img', {
      key: `image-${id}`,
      attrs: {
        "src": _vm.image(id),
        "height": _vm.getHeight,
        "max-width": _vm.getWidth
      },
      on: {
        "error": function ($event) {
          _vm.loadingFailed = true;
        }
      },
      scopedSlots: _vm._u([!_vm.loadingFailed ? {
        key: "placeholder",
        fn: function () {
          return [_c('v-row', {
            staticClass: "fill-height ma-0",
            attrs: {
              "align": "center",
              "justify": "center"
            }
          }, [_c('v-progress-circular', {
            attrs: {
              "indeterminate": "",
              "color": "grey lighten-5"
            }
          })], 1)];
        },
        proxy: true
      } : null], null, true)
    });
  }), 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }