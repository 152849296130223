var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    staticClass: "datafilters",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "datafilters--options"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "datafilters--option datafilters--totalreviews"
  }, [_c('r-num-reviews-filter', {
    attrs: {
      "min-reviews": _vm.filters.minReviews,
      "max-reviews": _vm.filters.maxReviews
    },
    on: {
      "change:min-reviews": _vm.onChangeMinReviews,
      "change:max-reviews": _vm.onChangeMaxReviews
    }
  })], 1), _c('v-col', {
    staticClass: "datafilters--option datafilters--products"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('resources.products')) + " ")]), _c('products-dropdown', {
    staticClass: "datafilters--filter",
    attrs: {
      "preselected-products": _vm.preselectedProducts
    },
    on: {
      "change": _vm.onProductsSelectionChange
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "datafilters--option datafilters--lastrevieweddate"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('dataFilters.lastReviewed')) + " ")]), _c('div', {
    staticClass: "datafilters--filter datafilters--range-container"
  }, [_c('span', [_vm._v("Start")]), _c('r-text-field', {
    attrs: {
      "value": _vm.filters.minLastReviewedDate,
      "error-message": _vm.errors.minLastReviewedDate.message,
      "placeholder": "2022-09-09"
    },
    on: {
      "change": function (e) {
        return _vm.onDateChange(e, 'minLastReviewedDate');
      }
    }
  }), _c('span', [_vm._v("End")]), _c('r-text-field', {
    attrs: {
      "value": _vm.filters.maxLastReviewedDate,
      "error-message": _vm.errors.maxLastReviewedDate.message,
      "placeholder": "2022-09-10"
    },
    on: {
      "change": function (e) {
        return _vm.onDateChange(e, 'maxLastReviewedDate');
      }
    }
  })], 1), _c('div', {
    staticClass: "datafilters--range-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.minLastReviewedDate.message || _vm.errors.maxLastReviewedDate.message) + " ")])]), _c('v-col', {
    staticClass: "datafilters--option datafilters--creationdate"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('dataFilters.creationDate')) + " ")]), _c('div', {
    staticClass: "datafilters--filter datafilters--range-container"
  }, [_c('span', [_vm._v("Start")]), _c('r-text-field', {
    attrs: {
      "value": _vm.filters.minCreationDate,
      "error-message": _vm.errors.minCreationDate.message,
      "placeholder": "2022-09-09"
    },
    on: {
      "change": function (e) {
        return _vm.onDateChange(e, 'minCreationDate');
      }
    }
  }), _c('span', [_vm._v("End")]), _c('r-text-field', {
    attrs: {
      "value": _vm.filters.maxCreationDate,
      "error-message": _vm.errors.maxCreationDate.message,
      "placeholder": "2022-09-10"
    },
    on: {
      "change": function (e) {
        return _vm.onDateChange(e, 'maxCreationDate');
      }
    }
  })], 1), _c('div', {
    staticClass: "datafilters--range-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.maxCreationDate.message || _vm.errors.minCreationDate.message) + " ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "datafilters--option datafilters--starrating"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('dataFilters.starRating')) + " ")]), _c('div', {
    staticClass: "datafilters--filter datafilters--range-container"
  }, [_c('span', [_vm._v("Min")]), _c('r-rating', {
    attrs: {
      "value": _vm.filters.minRating || 1,
      "readonly": false,
      "is-half-incrementing": false
    },
    on: {
      "change": _vm.onMinRatingChange
    }
  }), _c('span', [_vm._v("Max")]), _c('r-rating', {
    attrs: {
      "value": _vm.filters.maxRating || 5,
      "readonly": false,
      "is-half-incrementing": false
    },
    on: {
      "change": _vm.onMaxRatingChange
    }
  })], 1)]), _c('v-col', {
    staticClass: "datafilters--option datafilters--hostnames"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('dataFilters.domains')) + " ")]), _c('r-select', {
    staticClass: "datafilters--filter",
    attrs: {
      "multiple": "",
      "items": _vm.hostItems
    },
    on: {
      "change": _vm.onHostnameSelectionChange
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "datafilters--option datafilters--tags"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('resources.tags')) + " ")]), _c('tags-dropdown', {
    staticClass: "datafilters--filter",
    attrs: {
      "preselected-tags": _vm.preselectedTags
    },
    on: {
      "open:create-tag-modal": function ($event) {
        return _vm.$emit('open:create-tag-modal');
      },
      "change": _vm.onTagsSelectionChange
    }
  })], 1), _c('v-col', {
    staticClass: "datafilters--option datafilters--brands px-2"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('resources.brands')) + " ")]), _c('brands-dropdown', {
    staticClass: "datafilters--filter",
    attrs: {
      "show-autogenerated-brands-filter": true,
      "preselected-brands": _vm.preselectedBrands
    },
    on: {
      "open:create-brand-modal": function ($event) {
        return _vm.$emit('open:create-brand-modal');
      },
      "change": _vm.onBrandsSelectionChange
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "datafilters--option datafilters--categories"
  }, [_c('p', {
    staticClass: "datafilters--filterlabel"
  }, [_vm._v(" " + _vm._s(_vm.$t('resources.categories')) + " ")]), _c('categories-dropdown', {
    staticClass: "datafilters--filter",
    attrs: {
      "show-autogenerated-categories-filter": true,
      "preselected-categories": _vm.preselectedCategories
    },
    on: {
      "open:create-category-modal": function ($event) {
        return _vm.$emit('open:create-category-modal');
      },
      "change": _vm.onCategoriesSelectionChange
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "datafilters--actionbtns"
  }, [_c('r-button', {
    attrs: {
      "type": "button",
      "label": _vm.$t('global.actions.cancel'),
      "outlined": ""
    },
    on: {
      "click": _vm.onClickCancel
    }
  }), _c('r-button', {
    attrs: {
      "label": _vm.$t('global.actions.apply'),
      "type": "button",
      "disabled": _vm.hasErrors
    },
    on: {
      "click": _vm.onClickApply
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }