<template>
  <v-card
    class="rsearchnewfoundsalertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="rsearchnewfoundsalertcard--header d-flex py-1">
        <v-icon class="rsearchnewfoundsalertcard--icon">
          mdi-new-box
        </v-icon>
        <span class="rsearchnewfoundsalertcard--title text-truncate text-h6 ml-2">
          {{ $t('alerts.newFounds.title') }}
        </span>
      </div>

      <div class="rsearchnewfoundsalertcard--text d-flex">
        <span>
          {{ $t('alerts.newFounds.message', { numFounds, searchName }) }}
        </span>
      </div>

      <div class="rsearchnewfoundsalertcard--link d-flex mt-2">
        <a @click="onViewResultClick">{{ $t('alerts.viewResults') }}</a>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />

      <v-divider class="my-3" />

      <div class="rsearchnewfoundsalertcard--footer d-flex justify-space-between">
        <span class="date-title">{{ $t('alerts.date') }}</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RSearchNewFoundsAlertCard',
  components: { MarkAsSeen },
  props: {
    date: {
      type: String,
      required: true,
    },
    searchId: {
      type: String,
      required: false,
    },
    searchName: {
      type: String,
      required: true,
    },
    numFounds: {
      type: Number,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onViewResultClick() {
      this.$router.push(`/data/search/${this.searchId}/results`)
    },
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.rsearchnewfoundsalertcard--text {
  color: var(--r-carbon-color);
}
.rsearchnewfoundsalertcard--link a {
  text-decoration: none;
}

.rsearchnewfoundsalertcard--title,
.rsearchnewfoundsalertcard--icon {
  color: var(--primary-color);
}

.rsearchnewfoundsalertcard--icon {
  font-size: 1.5rem !important;
}

</style>
