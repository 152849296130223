<template>
  <v-card
    class="rurlerroralertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="rurlerroralertcard--header d-flex py-1">
        <v-icon class="rurlerroralertcard--icon">
          mdi-alert-circle
        </v-icon>
        <span class="rurlerroralertcard--title text-truncate text-h6 ml-2">
          URLs couldn't be added
        </span>
      </div>

      <div class="rurlerroralertcard--text d-flex">
        <span class="rsearchcard--text">We couldn't add URLs</span>
      </div>

      <div class="rurlerroralertcard--link d-flex mt-2">
        <a href="#">{{ $t('alerts.viewResults') }}</a>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />
      <v-divider class="my-3" />

      <div class="rurlerroralertcard--footer d-flex justify-space-between">
        <span class="date-title">Date Upload</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RUrlErrorAlertCard',
  components: { MarkAsSeen },
  props: {
    date: {
      type: String,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.rurlerroralertcard--text {
  color: var(--r-carbon-color);
}

.rurlerroralertcard--link a {
  text-decoration: none;
}

.rurlerroralertcard--title {
  color: #F03A61;
}

.rurlerroralertcard--icon {
  font-size: 1.5rem !important;
  color: #F03A61;
}

</style>
