<template>
  <span class="rowactions">
    <v-menu
      v-if="tab === 'Products' || tab === 'Urls'"
      offset-y
      :min-width="240"
      :offset-overflow="true"
      :close-on-content-click="false"
      class="rowactions--dropdown brands"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs, value }">
        <v-btn
          icon
          title="Brands"
          class="rowactions--brand-btn mr-3"
          :class="{ 'active': value}"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-alpha-b-circle</v-icon>
        </v-btn>
      </template>
      <div
        class="rowactions--dropdown-content"
        height="400"
      >
        <div class="rowactions--dropdown-title d-flex justify-center align-center mx-4">
          {{ $t('rowActions.editBrands') }}
        </div>
        <brands-dropdown
          :always-open="true"
          :preselected-brands="item.brands"
          :disabled="fetchingEditBrandsUrls || fetchingEditBrandsProducts"
          :show-autogenerated-brands-filter="true"
          @change="onCheckBrand"
          @open:create-brand-modal="onClickCreateNewBrand"
        />
      </div>
    </v-menu>

    <v-menu
      v-if="tab === 'Products' || tab === 'Urls'"
      offset-y
      :min-width="240"
      :offset-overflow="true"
      :close-on-content-click="false"
      class="rowactions--dropdown categories"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs, value }">
        <v-btn
          icon
          class="rowactions--category-btn mr-3"
          :class="{ 'active': value}"
          v-bind="attrs"
          title="Categories"
          v-on="on"
        >
          <v-icon>mdi-view-grid</v-icon>
        </v-btn>
      </template>

      <div class="rowactions--dropdown-content">
        <div class="rowactions--dropdown-title d-flex justify-center align-center mx-4">
          {{ $t('rowActions.editCategories') }}
        </div>
        <categories-dropdown
          :always-open="true"
          :preselected-categories="item.categories"
          :disabled="fetchingEditCategoriesUrls || fetchingEditCategoriesProducts"
          :show-autogenerated-categories-filter="true"
          @change="onCheckCategory"
          @open:create-category-modal="onClickCreateNewCategory"
        />
      </div>
    </v-menu>

    <v-menu
      v-if="tab === 'Products' || tab === 'Urls'"
      offset-y
      :min-width="240"
      :offset-overflow="true"
      :close-on-content-click="false"
      class="rowactions--dropdown tags"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs, value }">
        <v-btn
          icon
          class="rowactions--tag-btn mr-3"
          :class="{ 'active': value}"
          v-bind="attrs"
          title="Tags"
          v-on="on"
        >
          <v-icon>mdi-tag</v-icon>
        </v-btn>
      </template>

      <div
        class="rowactions--dropdown-content"
        style="height:398px"
      >
        <div class="rowactions--dropdown-title d-flex justify-center align-center mx-4">
          {{ $t('rowActions.editTags') }}
        </div>
        <tags-dropdown
          :always-open="true"
          :preselected-tags="item.tags"
          :disabled="fetchingEditTagsUrls || fetchingEditTags"
          @change="onSelectTag"
          @open:create-tag-modal="onClickCreateNewTag"
        />
      </div>
    </v-menu>

    <v-btn
      v-if="showBCRLinkButton"
      icon
      class="rowactions--bcr-link-btn mr-3"
      title="BCR Connection"
      @click="onClickCreateBCRLink"
    >
      <v-icon>cloud</v-icon>
    </v-btn>

    <v-btn
      v-if="showDownloadReviewsButton"
      icon
      title="Download reviews"
      class="rowactions--download-review-btn mr-3"
      @click="onClickDownloadReviews"
    >
      <v-icon>download</v-icon>
    </v-btn>

    <v-btn
      icon
      title="Delete"
      class="rowactions--delete-btn mr-3"
      @click="toggleDeleteModal()"
    >
      <v-icon>delete</v-icon>
    </v-btn>
  </span>
</template>

<script>
import BrandsDropdown from '@/components/app/data/BrandsDropdown'
import CategoriesDropdown from '@/components/app/data/CategoriesDropdown'
import TagsDropdown from '@/components/app/data/TagsDropdown.vue'
import { DATA_TYPES } from '@/utils/constants'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'RowActions',
  components: {
    BrandsDropdown,
    CategoriesDropdown,
    TagsDropdown,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('brands', [
      'fetchingEditBrandsUrls',
      'fetchingEditBrandsProducts',
    ]),
    ...mapState('categories', [
      'fetchingEditCategoriesUrls',
      'fetchingEditCategoriesProducts',
    ]),
    ...mapState('tags', [
      'fetchingEditTagsUrls',
    ]),
    ...mapState('products', [
      'fetchingEditTags',
    ]),
    ...mapGetters('users', [
      'clientCanLinkToBCR',
    ]),
    showBCRLinkButton() {
      const tabName = this.tab.toLowerCase()
      const dataTypeCanBeLinked = tabName === DATA_TYPES.URLS || tabName === DATA_TYPES.PRODUCTS

      return this.clientCanLinkToBCR && dataTypeCanBeLinked
    },
    showDownloadReviewsButton() {
      const tabName = this.tab.toLowerCase()
      return tabName === DATA_TYPES.URLS || tabName === DATA_TYPES.PRODUCTS
    },
    selectedBrandIds() {
      return this.item.brands.map(({ _id }) => _id)
    },
    selectedCategoryIds() {
      return this.item.categories.map(({ _id }) => _id)
    },
    selectedTagIds() {
      return this.item.tags.map(({ _id }) => _id)
    },
  },
  methods: {
    ...mapActions('categories', [
      'fetchCategories',
    ]),
    onCheckBrand({ value: newSelectedBrands, items }) {
      const newSelectedBrandIds = newSelectedBrands.map(({ value }) => value)

      const addedIds = newSelectedBrandIds.filter((item) => !this.selectedBrandIds.includes(item))
      if (addedIds.length) {
        return this.$emit('add:brands', { value: { id: this.item._id, brandIds: addedIds } })
      }

      const removedIds = items
        .map(({ value }) => value)
        .filter((item) => !newSelectedBrandIds.includes(item))
      return this.$emit('remove:brands', { value: { id: this.item._id, brandIds: removedIds } })
    },
    onClickCreateNewBrand() {
      this.$emit('open:create-brand-modal')
    },
    onCheckCategory({ value: newSelectedCategories, items }) {
      const newSelectedCategoryIds = newSelectedCategories.map(({ value }) => value)

      // eslint-disable-next-line max-len
      const addedIds = newSelectedCategoryIds.filter((item) => !this.selectedCategoryIds.includes(item))
      if (addedIds.length) {
        return this.$emit('add:categories', { value: { id: this.item._id, categoryIds: addedIds } })
      }

      const removedIds = items
        .map(({ value }) => value)
        .filter((item) => !newSelectedCategoryIds.includes(item))
      return this.$emit('remove:categories', { value: { id: this.item._id, categoryIds: removedIds } })
    },
    onClickCreateNewCategory() {
      this.$emit('open:create-category-modal')
    },
    onSelectTag({ value: newSelectedTags, items }) {
      const newSelectedTagIds = newSelectedTags.map(({ value }) => value)
      const addedIds = newSelectedTagIds.filter((item) => !this.selectedTagIds.includes(item))
      if (addedIds.length) {
        return this.$emit('add:tags', { value: { id: this.item._id, tagIds: addedIds } })
      }

      const removedIds = items
        .map(({ value }) => value)
        .filter((item) => !newSelectedTagIds.includes(item))
      return this.$emit('remove:tags', { value: { id: this.item._id, tagIds: removedIds } })
    },
    onClickCreateNewTag() {
      this.$emit('open:create-tag-modal')
    },
    toggleDeleteModal() {
      this.$emit('toggle:delete-modal', { item: this.item })
    },
    onClickCreateBCRLink() {
      this.$emit('create:bcr-link', { item: this.item })
    },
    onClickDownloadReviews() {
      const value = { id: this.item._id }
      this.$emit('click:download-reviews', { value })
    },
  },
}
</script>

<style scoped>
.v-btn.v-btn--icon.active {
  color: var(--primary-color);
}
/deep/ .v-btn--icon.v-size--default {
  width: 1.07692rem;
  height: 1.07692rem;
}
/deep/ .v-btn.v-btn--icon .v-icon {
  font-size: 1.23077rem;
}
.rowactions--dropdown-content {
  background-color: white;
  padding: 16px 0;
  height: 408px;
}
.rowactions--dropdown-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid rgba(63, 63, 63, 0.15);
  margin-bottom: 11px;
  padding-bottom: 8px;
}
/deep/ .selector-option-container[data-v-561d8888] {
  max-height: 25rem !important;
}
/deep/ .bcrlinkstatus--button {
  border: none;
  padding: 0 !important;
}
</style>
