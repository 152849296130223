<template>
  <div class="tagstable">
    <r-error-message
      v-if="fetchTagsError"
      class="tagstable--errormessage"
      :errors="[fetchTagsError]"
    />
    <r-table
      key="tagstable"
      class="tagstable--table"
      :columns="tableColumns"
      :rows="tags"
      :total-rows="totalTags"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingTags"
      @change:page="({ value }) => setPage({ page: value })"
      @change:page-size="onChangePageSize"
      @change:page-size-all="onChangePageSizeAll"
      @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
      @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      @change:batch-select="(data) => $emit('change:batch-select', data)"
      @change:batch-select-all="() => $emit('change:batch-select-all')"
    >
      <template v-slot:item.name="{ item }">
        <r-badge
          :name="item.name"
          :color="item.color"
        />
      </template>
      <template v-slot:item.averageRating="{ item }">
        <r-rating
          class="productstable--rating"
          :value="item.averageRating"
        />
      </template>
      <template v-slot:item.numReviews="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ item.numReviews }}</span>
          <v-icon class="ml-1">
            mdi-download
          </v-icon>
        </div>
      </template>
      <template v-slot:item.lastReviewed="{ item }">
        <span v-if="item.lastReviewed">{{ formatDate(item.lastReviewed) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <row-actions
          tab="Tags"
          :item="item"
          @toggle:delete-modal="onToggleDeleteConfirmationModal"
        />
      </template>
    </r-table>

    <delete-confirmation
      :show-modal="showDeleteConfirmationModal"
      :error="deleteTagsError"
      :fetching="deletingTags"
      :custom-action-label="$t('commonTable.batchActions.delete.deleteFromDataButton')"
      :custom-header="$t('commonTable.batchActions.delete.title')"
      :custom-description="selectedProjectId ?
        $t('commonTable.batchActions.delete.descriptionProject', { dataTypeName } )
        : $t('commonTable.batchActions.delete.description', { dataTypeName } )"
      :custom-alternate-action-label="!selectedProjectId ? undefined : $t(
        'commonTable.batchActions.delete.deleteFromProjectButton'
      )"
      @delete:cancel="onToggleDeleteConfirmationModal"
      @delete:confirm="onDelete"
    />

    <r-modal
      v-if="showDeleteAlertModal"
      class="urlstable--deleteconfirmationmodal"
      :title="(!deleteTagsError
        ? $t(
          'commonTable.batchActions.delete.title'
        )
        : $t('commonTable.batchActions.delete.deleteErrorMessage', {
          entityName
        })
      )"
      :action-label="$t('global.actions.confirm')"
      @submit="showDeleteAlertModal = false"
    >
      <template v-slot:content>
        <div
          v-if="deleteTagsError"
          class="text-center"
        >
          {{ deleteTagsError }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{
            $t(`commonTable.batchActions.delete.${
              !selectedProjectId ?
                'deleteFromAllDataSuccessMessage' :
                'deleteFromProjectSuccessMessage'
            }`, {
              dataTypeCount,
              dataTypeName
            })
          }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RRating from '@/components/library/atoms/RRating'
import RBadge from '@/components/library/atoms/RBadge'
import debounce from '@/utils/debounce'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import DeleteConfirmation from '@/components/app/data/add/DeleteConfirmation'
import RowActions from '@/components/app/data/RowActions'
import RModal from '@/components/library/organisms/RModal.vue'

export default {
  name: 'TagsTable',
  components: {
    RModal,
    RBadge,
    RTable,
    RRating,
    RErrorMessage,
    DeleteConfirmation,
    RowActions,
  },
  props: {
    search: String,
  },
  data: () => ({
    tableColumns: [
      {
        key: 'name',
        label: 'Tag',
        hideable: true,
        sortable: true,
        width: 250,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: true,
        sortable: true,
        width: 130,
      },
      {
        key: 'numUrls',
        label: 'URLs',
        hideable: true,
        sortable: true,
        width: 80,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'lastReviewed',
        label: 'Last reviewed',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'actions',
        label: 'Actions',
        hideable: false,
        width: 130,
      },
    ],
    debouncedFetchData: null,
    selectedTag: null,
    showDeleteConfirmationModal: false,
    dataTypeName: 'tag',
    dataTypeCount: '',
    showDeleteAlertModal: false,
  }),
  computed: {
    ...mapState('tags', [
      'tags',
      'fetchingTags',
      'fetchTagsError',
      'totalTags',
      'page',
      'pageSize',
      'sort',
      'sortDir',
      'deleteTagsError',
      'deletingTags',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('filters', [
      'filterKey',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ page: 1 })
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    selectedProjectId() {
      this.$data.debouncedFetchData()
    },
    filterKey() {
      this.$data.debouncedFetchData()
    },
    appliedFilters() {
      this.setPage({ page: 1 })
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  methods: {
    ...mapActions('tags', [
      'fetchTags',
      'setPage',
      'setPageSize',
      'setSort',
      'clearErrors',
      'deleteTags',
    ]),
    fetchData() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        selectedProjectId,
        appliedFilters,
      } = this

      let payload = {
        page, pageSize, ...appliedFilters, projectId: selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchTags(payload)
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalTags })
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onToggleDeleteConfirmationModal({ item } = {}) {
      this.selectedTag = item
      this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal
      this.clearErrors({ deleteTagsError: '' })
    },
    async onDelete({ value }) {
      this.clearErrors({ deleteTagsError: '' })
      const { op, alternate } = value

      this.dataTypeCount = this.selectedTag.name
      const tagIds = [this.selectedTag._id]
      const payload = { tagIds }
      if (alternate) {
        payload.projectId = this.selectedProjectId
      } else {
        payload.op = op
      }
      await this.deleteTags(payload)
      if (!this.deleteTagsError) {
        this.onToggleDeleteConfirmationModal()
        const queryPayload = this.getQueryParameters()
        this.showDeleteAlertModal = true
        await this.fetchTags(queryPayload)
      }
    },
    getQueryParameters() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        selectedProjectId,
        appliedFilters,
      } = this

      let payload = {
        page, pageSize, ...appliedFilters, projectId: selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }
      return payload
    },
  },
}
</script>

<style scoped>
.tagstable--table {
  width: 100%;
  min-height: 600px;
}
</style>
