<template>
  <div>
    <r-modal
      v-if="showModal"
      class="deleteconfirmation"
      :title="customHeader ? customHeader : $t('deleteConfirmation.title')"
      :close-action-label="$t('global.actions.cancel')"
      :alternate-action-label="customAlternateActionLabel"
      :action-label="customActionLabel ? customActionLabel : $t('deleteConfirmation.delete')"
      :fetching="fetching === true ? true : false"
      @submit="onDelete"
      @submit:alternate="onDeleteAlternate"
      @close="() => $emit('delete:cancel')"
    >
      <template v-slot:content>
        <r-error-message
          class="mb-2"
          :errors="[error]"
        />

        <div class="d-flex justify-center align-center">
          {{ customDescription ? customDescription : $t('deleteConfirmation.content') }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'

export default {
  name: 'DeleteConfirmation',
  components: {
    RModal,
    RErrorMessage,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    customHeader: {
      type: String,
      required: false,
      default: undefined,
    },
    customDescription: {
      type: String,
      required: false,
      default: undefined,
    },
    customAlternateActionLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    customActionLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    fetching: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    onDelete() {
      const value = {
        op: BATCH_OPERATIONS_MAP.REMOVE,
      }
      this.$emit('delete:confirm', { value })
    },
    onDeleteAlternate() {
      const value = {
        op: BATCH_OPERATIONS_MAP.REMOVE,
        alternate: true,
      }
      this.$emit('delete:confirm', { value })
    },
  },
}
</script>
