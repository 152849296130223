<template>
  <div>
    <v-row class="ma-0 productsdropdown d-flex justify-center">
      <r-select
        :selected-items-for-chip="selectedUrls"
        :infinite="true"
        multiple
        class="projectsdropdown--select"
        :items="items"
        @change="onSelectUrls"
        @on:get-more="onGetMoreItems"
        @change:search="onSearchChange"
      />
    </v-row>
  </div>
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'
import { mapActions, mapState } from 'vuex'
import { generateParams } from '@/utils/store'

export default {
  name: 'UrlsDropdown',
  components: {
    RSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    preselectedUrls: {
      type: Array,
      default: () => [],
    },
    productId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchValue: '',
    selectedUrls: [],
  }),
  computed: {
    ...mapState('urls', [
      'fetchingUrlsSummary',
      'urlsSummary',
      'fetchUrlsSummaryError',
      'pageSummarySize',
      'pageSummary',
    ]),
    items() {
      return this.mapUrlsToDropdownItems({
        urls: this.urlsSummary,
      })
    },
  },
  mounted() {
    this.fetchUrls()
  },
  beforeDestroy() {
    this.setPageSummary({ pageSummary: 1 })
  },
  methods: {
    ...mapActions('urls', [
      'fetchUrlsSummary',
      'setPageSummary',
    ]),
    onSelectUrls({ value: selectedItems }) {
      const allSelectedUrls = [...this.selectedUrls, ...selectedItems]
      this.selectedUrls = Array.from(new Set(allSelectedUrls.map((a) => a.value)))
        .map((itemValue) => allSelectedUrls.find((a) => a.value === itemValue))
        .map(({ value, label }) => ({
          label,
          value,
        }))
      this.$emit('change', { value: selectedItems, items: this.items })
    },
    mapUrlsToDropdownItems({ urls }) {
      const preselectedUrlsIds = this.selectedUrls.map(({ value }) => value)
      return urls.map(({ _id, name }) => ({
        label: name,
        value: _id,
        selected: preselectedUrlsIds.includes(_id),
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('urls')
      if (!pageParams) {
        return
      }
      let params = {
        isInfinityScroll: true,
      }
      params = { ...params, ...pageParams }
      this.fetchUrls(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchUrls({ page: 1 })
    },
    fetchUrls(params) {
      this.fetchUrlsSummary({
        projectId: this.projectId,
        search: this.searchValue,
        page: this.pageSummary,
        pageSize: this.pageSummarySize,
        productIds: [this.productId],
        ...params,
      })
    },
  },
}
</script>

<style scoped>
.projectsdropdown--select {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-content: center;
  gap: 10px;
}
</style>
