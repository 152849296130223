var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rdatacard"
  }, [_vm.type === _vm.DASHBOARD_TYPES.BRAND ? _c('r-data-brand-card', {
    attrs: {
      "name": _vm.name,
      "num-products": _vm.numProducts,
      "num-urls": _vm.numUrls
    }
  }) : _vm.type === _vm.DASHBOARD_TYPES.CATEGORY ? _c('r-data-category-card', {
    attrs: {
      "name": _vm.name,
      "num-products": _vm.numProducts,
      "num-urls": _vm.numUrls
    }
  }) : _vm.type === _vm.DASHBOARD_TYPES.PRODUCT ? _c('r-data-product-card', {
    attrs: {
      "id": _vm.id,
      "name": _vm.name,
      "rating": _vm.rating,
      "num-urls": _vm.numUrls,
      "client-can-link-to-b-c-r": _vm.clientCanLinkToBCR,
      "bcr-link-job-ids": _vm.bcrLinkJobIds
    },
    on: {
      "click:bcrlink-status": function ($event) {
        return _vm.$emit('click:bcrlink-status', $event);
      }
    }
  }) : _vm.type === _vm.DASHBOARD_TYPES.TAG ? _c('r-data-tag-card', {
    attrs: {
      "name": _vm.name,
      "num-products": _vm.numProducts,
      "num-urls": _vm.numUrls
    }
  }) : _vm.type === _vm.DASHBOARD_TYPES.URL ? _c('r-data-url-card', {
    attrs: {
      "id": _vm.id,
      "name": _vm.name,
      "rating": _vm.rating,
      "url": _vm.url,
      "hostname": _vm.hostname,
      "created": _vm.created,
      "last-update": _vm.lastUpdate,
      "client-can-link-to-b-c-r": _vm.clientCanLinkToBCR,
      "bcr-link-job-id": _vm.bcrLinkJobId
    },
    on: {
      "click:bcrlink-status": function ($event) {
        return _vm.$emit('click:bcrlink-status', $event);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }