export default (value, regex) => {
  if (!regex.test(value)) {
    return false
  }

  const date = new Date(value)

  const timestamp = date.getTime()

  return !(typeof timestamp !== 'number' || Number.isNaN(timestamp))
}
