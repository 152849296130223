<template>
  <v-dialog
    v-model="show"
    class="rmodal"
    hide-overlay
    persistent
    no-click-animation
    width="0"
  >
    <v-overlay
      opacity="0.5"
      color="white"
      :dark="false"
    >
      <v-card
        min-width="650px"
        style="background-color: white; box-shadow: 1px 1px 5px -2px;"
      >
        <v-card-title
          class="px-6 py-4"
          style="justify-content: center;"
        >
          <span>{{ title }}</span>
        </v-card-title>
        <span v-if="subTitle">{{ subTitle }}</span>

        <div
          v-if="errorMessage"
          class="ma-4"
        >
          <v-alert
            type="error"
            dense
            class="mb-0"
          >
            <small>{{ errorMessage }}</small>
          </v-alert>
        </div>

        <v-card-text
          v-if="fetchingInitial"
          data-testid="initial-loading"
          class="pt-4"
          :style="contentStyle"
        >
          <v-row justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
          <v-row
            justify="center"
            class="mt-4"
          >
            <p>Fetching</p>
          </v-row>
        </v-card-text>

        <v-card-text
          v-else
          class="pt-4 content-container"
          :style="contentStyle"
        >
          <slot name="content" />
        </v-card-text>

        <v-card-actions>
          <div v-if="!!this.$slots.customActions">
            <slot name="customActions" />
          </div>
          <div
            v-else
            class="actions-container"
          >
            <r-button
              v-if="!actionCompleted && closeActionLabel"
              data-testid="cancel"
              class="close-btn"
              outlined
              :label="closeActionLabel"
              :disabled="fetching"
              @click="onClickCancel"
            />
            <r-button
              v-if="!actionCompleted && alternateActionLabel"
              data-testid="submit"
              class="submit-alternate-btn"
              :label="alternateActionLabel"
              :loading="fetching"
              :disabled="alternateActionDisabled"
              @click="$emit('submit:alternate')"
            />
            <r-button
              v-if="!actionCompleted && actionLabel"
              data-testid="submit"
              class="submit-btn"
              :label="actionLabel"
              :disabled="actionDisabled"
              :loading="fetching"
              @click="$emit('submit')"
            />
          </div>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-dialog>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'

export default {
  name: 'RModal',
  components: {
    RButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    maxContentHeight: {
      type: Number,
      required: false,
    },
    fetchingInitial: {
      type: Boolean,
      default: false,
    },
    actionCompleted: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    actionDisabled: {
      type: Boolean,
      default: false,
    },
    alternateActionDisabled: {
      type: Boolean,
      default: false,
    },
    closeActionLabel: {
      type: String,
      default: '',
    },
    actionLabel: {
      type: String,
      default: 'Submit',
    },
    alternateActionLabel: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    show: true,
  }),
  computed: {
    contentStyle() {
      if (!this.maxContentHeight) {
        return {}
      }

      return {
        maxHeight: `${this.maxContentHeight}px`,
        overflowY: 'auto',
      }
    },
  },
  methods: {
    onClickCancel() {
      this.show = false
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.v-overlay {
  backdrop-filter: blur(5px);
}
.v-card.v-sheet {
  border-radius: 8px;
  padding: 20px 40px;
}
.v-card__actions {
  display: block;
  text-align: center;
}
.v-application--is-ltr .v-card__actions > .rbutton {
  min-width: 150px;
}
.actions-container > * {
  margin-right: 5px;
}
.actions-container > .rbutton {
  min-width: 150px !important;
}
/deep/ .close-btn.v-btn--disabled {
  border-color: var(--r-grey);
}
/deep/ .close-btn.v-btn--disabled .v-btn__content {
  color: var(--r-grey);
}
/deep/ .v-btn.v-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid var(--r-border-color) !important;
}

</style>
