<template>
  <div class="numreviewsfilter">
    <p class="numreviewsfilter--label">
      {{ $t('numReviewsFilter.totalNumberOfReviews') }}
    </p>
    <div class="numreviewsfilter--field d-flex justify-space-between">
      <r-text-field
        placeholder="20"
        type="number"
        :min="0"
        :value="minReviews"
        :rules="minReviewsRules"
        class="minreviews-filter"
        @change="onChangeMinReviews"
      />
      <span class="mt-2"> - </span>
      <r-text-field
        placeholder="30"
        type="number"
        :min="maxReviewsMinValue"
        :value="maxReviews"
        :rules="maxReviewsRules"
        class="maxreviews-filter"
        @change="onChangeMaxReviews"
      />
    </div>
  </div>
</template>

<script>
import RTextField from '@/components/library/molecules/RTextField'

export default {
  name: 'RNumReviewsFilter',
  components: {
    RTextField,
  },
  props: {
    minReviews: {
      type: Number,
      required: false,
    },
    maxReviews: {
      type: Number,
      required: false,
    },
  },
  computed: {
    maxReviewsMinValue() {
      return this.minReviews || 0
    },
    minValueRule() {
      return (val) => (val >= 0 || '') || this.$t('numReviewsFilter.validations.minValue')
    },
    minReviewsRules() {
      if (this.minReviews === undefined) {
        return []
      }

      return [this.minValueRule]
    },
    maxReviewsRules() {
      if (this.maxReviews === undefined) {
        return []
      }

      return [
        this.minValueRule,
        (maxReviews) => (maxReviews >= this.maxReviewsMinValue) || this.$t('numReviewsFilter.validations.maxReviewsMinValue'),
      ]
    },
  },
  methods: {
    onChangeMinReviews({ value }) {
      const newValue = value === '' ? '' : Number(value)
      return this.$emit('change:min-reviews', { value: newValue })
    },
    onChangeMaxReviews({ value }) {
      const newValue = value === '' ? '' : Number(value)
      return this.$emit('change:max-reviews', { value: newValue })
    },
  },
}
</script>

<style scoped>
.numreviewsfilter--label {
  font-weight: 500;
}
</style>
