<template>
  <v-card
    class="ruploadcompletealertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="ruploadcompletealertcard--header d-flex py-1">
        <v-icon class="ruploadcompletealertcard--icon">
          mdi-tray-arrow-up
        </v-icon>
        <span class="ruploadcompletealertcard--title text-truncate text-h6 ml-2">
          "{{ uploadName }}" upload completed
        </span>
      </div>

      <div class="ruploadcompletealertcard--text d-flex">
        <span class="rsearchcard--text">Upload is completed</span>
      </div>

      <div class="ruploadcompletealertcard--progress d-flex my-2 d-flex align-center">
        <v-progress-linear
          :value="progressPercent"
          color="#00A682"
          height="15"
        />
        <span class="ml-2">{{ progressPercent }}%</span>
      </div>

      <div class="ruploadcompletealertcard--link d-flex">
        <a href="#">{{ $t('alerts.viewResults') }}</a>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />

      <v-divider class="my-3" />

      <div class="ruploadcompletealertcard--footer d-flex justify-space-between">
        <span class="date-title">Date Upload</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RUploadCompleteAlertCard',
  components: { MarkAsSeen },
  props: {
    uploadName: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    progressPercent: 100,
  }),
  methods: {
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.ruploadcompletealertcard--text {
  color: var(--r-carbon-color);
}

.ruploadcompletealertcard--link a {
  text-decoration: none;
}

.ruploadcompletealertcard--title {
  color: #00A682;
}

.ruploadcompletealertcard--icon {
  font-size: 1.5rem !important;
  color: #00A682;
}

</style>
