<template>
  <v-rating
    :value="value"
    class="rrating pa-0 ma-0"
    :readonly="readonly"
    :half-increments="isHalfIncrementing"
    :size="16"
    half-icon="mdi-star-half-full"
    color="yellow darken-2"
    background-color="yellow darken-2"
    dense
    @input="onChange"
  />
</template>

<script>
export default {
  name: 'RRating',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    isHalfIncrementing: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onChange(newValue) {
      this.$emit('change', { value: newValue })
    },
  },
}
</script>
