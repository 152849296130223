var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rdataurlcard box-shadow-custom",
    attrs: {
      "max-width": "240"
    }
  }, [_c('div', {
    staticClass: "pa-4"
  }, [_c('div', {
    staticClass: "rdataurlcard--header d-flex mb-2"
  }, [_c('span', {
    staticClass: "rdataurlcard--title text-truncate",
    attrs: {
      "title": _vm.url
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('v-icon', {
    staticClass: "rdataurlcard--icon ml-2"
  }, [_vm._v(" " + _vm._s(_vm.DASHBOARD_TYPE_TO_ICON[_vm.DASHBOARD_TYPES.URL]) + " ")])], 1), _c('div', {
    staticClass: "rdataurlcard--body d-flex"
  }, [_c('r-data-image', {
    staticClass: "mr-2",
    attrs: {
      "type": _vm.DASHBOARD_TYPES.URL,
      "size": 42,
      "ids": _vm.ids
    }
  }), _c('div', {
    staticClass: "flex-expand"
  }, [_c('r-chip', {
    attrs: {
      "label": _vm.hostname,
      "title": _vm.url
    }
  }), _c('div', {
    staticClass: "d-flex justify-end align-end"
  }, [_vm.showBCRStatusButton ? _c('r-button', {
    staticClass: "bcrlinkstatus--button",
    attrs: {
      "color": "grey",
      "label": "",
      "outlined": "",
      "icon": "cloud"
    },
    on: {
      "click": _vm.onClickBCRLinkStatus
    }
  }) : _vm._e()], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }