<template>
  <div
    ref="rtable"
    class="rtable"
  >
    <div
      v-if="numBatchSelectedRows"
      class="rtable--batchselect"
      :style="{ height: `${batchSelectRowHeight}px` }"
    >
      <span v-if="!selectAllPages">
        {{ numBatchSelectedRows }} row{{ numBatchSelectedRows !== 1 ? 's' : '' }} selected.
        <a
          v-if="wholePageSelected && numBatchSelectedRows !== totalRows"
          class="rtable--batchselect--allpages"
          @click="selectAllPages = true"
        >
          Select all {{ totalRows }} rows
        </a>
      </span>
      <span v-else>
        All {{ totalRows }} rows selected.
        <a @click="onClickBatchSelectThisPageOnly">
          Select this page only
        </a>
      </span>
    </div>
    <div
      v-if="initialLoading"
      class="rtable--headerbg"
    />
    <no-listing-results v-if="noData" />
    <div
      v-else
      class="rtable--data--container"
      style="position: relative"
    >
      <table
        ref="rtable--table"
        class="rtable--table"
      >
        <thead
          ref="rtable--header"
          class="rtable--header"
        >
          <tr class="rtable--row d-flex align-center">
            <th
              v-for="(column, i) in visibleColumns"
              :key="`${column.key}-${column.sorted}`"
              class="rtable--cell"
              :class="{'d-flex align-center': hasHiddenColumns && i === (visibleColumns.length-1)}"
              :style="headerCellStyle(column, i)"
            >
              <template v-if="i === 0 && isSelectable">
                <v-checkbox
                  v-model="batchSelectAll"
                  class="rtable--batchselectall"
                  dense
                  @click="onClickBatchSelectAll"
                />
              </template>
              <div
                v-else
                class="d-flex align-center"
              >
                <a
                  v-if="column.sortable || column.hideable"
                  class="rtable--popuptrigger"
                  @click="onClickChangeColumn($event, column, i)"
                >{{ column.label }}</a>
                <span
                  v-else
                  class="rtable--unselectable"
                >{{ column.label }}</span>
                <v-icon
                  v-if="column.sorted === 'desc'"
                  small
                >
                  arrow_drop_down
                </v-icon>
                <v-icon
                  v-else-if="column.sorted === 'asc'"
                  small
                >
                  arrow_drop_up
                </v-icon>
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          ref="rtable--body"
          class="rtable--body"
        >
          <tr
            v-for="(row, i) in rows"
            ref="rtable--renderedrow"
            :key="`renderedrow-${i}`"
            class="rtable--row d-flex align-center"
          >
            <td
              v-for="(column, j) in visibleColumns"
              :key="`${column.key}-${row._id}`"
              class="rtable--cell"
              :style="cellStyle(column, j)"
            >
              <template
                v-if="j === 0 && isSelectable"
              >
                <v-checkbox
                  v-model="batchSelectedRows[row._id]"
                  class="rtable--batchselect"
                  dense
                />
              </template>
              <slot
                v-else
                :name="`item.${column.key}`"
                :item="row"
              >
                <span v-if="!Array.isArray(row[column.key]) || row[column.key].length > 0">
                  {{ row[column.key] }}
                </span>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
      <template
        v-if="hasHiddenColumns"
      >
        <v-btn
          ref="rtable--add-column"
          :style="plusButtonStyle"
          class="rtable--popuptrigger rtable--add-column"
          icon
          @click="onClickShowAddColumnPopup"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </div>

    <r-table-pagination
      v-if="rows.length"
      ref="rtable--footer"
      :key="`pagination-size-${totalRows}`"
      :page-size="pageSize"
      :current-page="page"
      :total-rows="totalRows"
      :show-all="totalRows === rows.length"
      :action-items="actionItems"
      @click:page-size-all="$emit('change:page-size-all')"
      @click:page-size="(data) => $emit('change:page-size', data)"
      @click:page="(data) => $emit('change:page', data)"
      @open:pagination-action-item="onClickActionItem"
    />
    <r-table-popup
      v-if="showAddColumn"
      :style="addColumnPopupStyle"
      :items="hiddenColumns.map((c) => ({ ...c, icon: 'add' }))"
      @click:item="onClickAddColumn"
    />
    <r-table-popup
      v-if="showChangeColumn"
      :items="changeColumnItems"
      :style="changeColumnPopupStyle"
      @click:item="onClickChangeColumnItem"
    />
    <div
      v-if="loading"
      ref="rtable--loading"
      class="rtable--loading"
    >
      <v-progress-circular indeterminate />
    </div>
  </div>
</template>

<script>
import didNotClickOn from '@/utils/didNotClickOn'
import RTablePopup from '@/components/library/atoms/RTablePopup'
import RTablePagination from '@/components/library/molecules/RTablePagination'
import NoListingResults from '@/components/app/data/NoListingResults'
import helpers from './helpers/rtable'

const SORT_DESCENDING_ITEM = { icon: 'sort_by_alpha', key: 'sort-desc', label: 'Sort descending' }
const SORT_ASCENDING_ITEM = { icon: 'sort_by_alpha', key: 'sort-asc', label: 'Sort ascending' }
const HIDE_COLUMN_ITEM = { icon: 'visibility_off', key: 'hide-column', label: 'Hide column' }
const HEADER_HEIGHT = 60
const PLUS_BUTTON_MARGIN_LEFT = 12
const PLUS_BUTTON_WIDTH = 24
const SELECTORS = {
  CELL: '.rtable--cell',
  SCROLL_CANVAS: '.rtable--scrollcanvas',
}
const BATCH_SELECT_ROW_HEIGHT = 30

const fillBatchSelectedRows = (rows, value, batchSelect) => rows.reduce((memo, item) => ({
  ...memo,
  [item._id]: (item?.selected && !batchSelect) || value,
}), {})

export default {
  name: 'RTable',
  components: {
    RTablePopup,
    RTablePagination,
    NoListingResults,
  },
  props: {
    columns: Array,
    rows: Array,
    totalRows: Number,
    height: {
      type: [Number, String],
      default: 600,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    actionItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    const tableColumns = this.buildTableColumns()
    const batchSelectedRows = fillBatchSelectedRows(this.$props.rows, false)
    return {
      firstRowIndex: 0,
      maxVisibleRows: 0,
      scrollableAreaHeight: 0,
      scrollableCanvasHeight: 0,
      rowHeight: 0,
      headerCellWidths: [],
      debouncedOnResize: null,
      showAddColumn: false,
      showChangeColumn: false,
      selectedColumn: null,
      changeColumnLeft: '',
      changeColumnRight: '',
      batchSelectAll: false,
      selectAllPages: false,
      batchSelectedRows,
      batchSelectRowHeight: BATCH_SELECT_ROW_HEIGHT,
      tableColumns,
      changeColumnTop: '',
      addColumnTop: '',
      addColumnLeft: '',
    }
  },
  computed: {
    initialLoading() {
      const { rows, loading } = this.$props
      return loading && !rows?.length
    },
    noData() {
      const { rows, loading } = this.$props
      return !loading && !rows?.length
    },
    visibleColumns() {
      return this.$data.tableColumns.filter(({ hidden }) => !hidden)
    },
    hiddenColumns() {
      return this.$data.tableColumns.filter(({ hidden }) => hidden)
    },
    hasHiddenColumns() {
      return this.hiddenColumns.length > 0
    },
    changeColumnItems() {
      const items = []
      if (!this.selectedColumn) {
        return items
      }
      if (this.selectedColumn.sortable) {
        items.push(SORT_DESCENDING_ITEM)
        items.push(SORT_ASCENDING_ITEM)
      }
      if (this.selectedColumn.hideable) {
        items.push(HIDE_COLUMN_ITEM)
      }
      return items
    },
    numBatchSelectedRows() {
      return Object.values(this.$data.batchSelectedRows).filter((v) => v).length
    },
    wholePageSelected() {
      const { rows } = this.$props
      if (rows.length === 0) {
        return false
      }
      const { batchSelectedRows } = this.$data
      return !rows.find(({ _id }) => !batchSelectedRows[_id])
    },
    selectedRows() {
      const { batchSelectedRows } = this.$data
      const { rows } = this.$props

      return rows.filter(({ _id }) => batchSelectedRows[_id])
    },
    changeColumnPopupStyle() {
      return {
        right: this.changeColumnRight,
        left: this.changeColumnLeft,
        top: `${this.changeColumnTop}px`,
      }
    },
    addColumnPopupStyle() {
      return {
        top: `${this.addColumnTop}px`,
        right: `-${PLUS_BUTTON_MARGIN_LEFT + PLUS_BUTTON_WIDTH}px`,
      }
    },
    plusButtonStyle() {
      return {
        'margin-left': `${PLUS_BUTTON_MARGIN_LEFT}px`,
        width: `${PLUS_BUTTON_WIDTH}px`,
      }
    },
  },
  watch: {
    async visibleColumns() {
      await this.waitForCss()
      await this.setSizes()
    },
    async rows() {
      if (!this.$props.rows.length) {
        this.$data.batchSelectedRows = {}
        return
      }
      this.$refs['rtable--body'].scrollTop = 0
      if (this.$data.selectAllPages) {
        this.$data.batchSelectedRows = {
          ...this.$data.batchSelectedRows,
          ...fillBatchSelectedRows(this.$props.rows, true),
        }
      } else {
        this.$data.batchSelectedRows = fillBatchSelectedRows(this.$props.rows, false)
      }
      await this.waitForCss()
      await this.setSizes()
      this.$data.batchSelectAll = this.wholePageSelected
    },
    selectAllPages() {
      if (this.selectAllPages) {
        this.$emit('change:batch-select-all', { value: this.selectAllPages })
      }
    },
    batchSelectedRows: {
      handler() {
        this.$data.batchSelectAll = this.wholePageSelected
        if (!this.selectAllPages) {
          this.$emit('change:batch-select', { value: this.selectedRows })
        }
      },
      deep: true,
    },
  },
  mounted() {
    document.body.addEventListener('click', this.onClickDocument)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.onClickDocument)
  },
  methods: {
    buildTableColumns() {
      if (this.isSelectable) {
        const batchSelectColumn = {
          key: 'batchSelect',
          label: '',
          hideable: false,
          sortable: false,
          width: 40,
        }

        return [batchSelectColumn, ...this.$props.columns]
      }

      return [...this.$props.columns]
    },
    async setSizes() {
      this.resetStyle()
      await this.waitForCss()

      const wrapperNode = this.$refs.rtable
      const tableNode = this.$refs['rtable--table']
      if (!tableNode) {
        return
      }

      const headerNode = this.$refs['rtable--header']
      const bodyNode = this.$refs['rtable--body']
      const footerNode = this.$refs['rtable--footer']?.$el
      const { height } = this.$props

      const {
        rowHeight,
        scrollableAreaHeight,
        maxVisibleRows,
        headerCellWidths,
      } = helpers.getSizes({
        wrapperNode,
        headerNode,
        bodyNode,
        footerNode,
        numRows: this.rows.length,
        batchSelectRowHeight: BATCH_SELECT_ROW_HEIGHT,
      })

      this.$data.rowHeight = rowHeight
      this.$data.scrollableAreaHeight = scrollableAreaHeight
      this.$data.maxVisibleRows = maxVisibleRows
      this.$data.headerCellWidths = headerCellWidths

      const totalWidth = headerCellWidths.reduce((memo, width) => memo + width, 0)

      helpers.setContainerSize({
        tableNode,
        wrapperNode,
        headerNode,
        bodyNode,
        totalWidth,
        scrollableAreaHeight,
        height,
      })
      helpers.setCellSizes({
        headerNode,
        bodyNode,
        headerCellWidths,
        totalWidth,
        rowHeight,
      })
    },
    async onResize() {
      await this.setSizes()
    },
    cellStyle(column, i) {
      return {
        textAlign: column.align || 'left',
        width: `${column.width}px`,
        display: i === 0 ? 'block' : 'table-cell',
      }
    },
    headerCellStyle(column, i) {
      const style = {
        textAlign: column.align || 'left',
      }
      if (column.width) {
        return {
          ...style,
          width: `${column.width}px`,
          minWidth: i !== 0 ? 'max-content' : 'fit-content',
          display: i === 0 ? 'block' : 'table-cell',
        }
      }
      return style
    },
    resetStyle() {
      const { visibleColumns } = this
      const headerNode = this.$refs['rtable--header']
      const headerCellNodes = [...headerNode.querySelectorAll(SELECTORS.CELL)]
      headerCellNodes.pop()
      const headerCellStyles = headerCellNodes
        .map((_, i) => this.headerCellStyle(visibleColumns[i]))
      helpers.resetStyle({
        wrapperNode: this.$refs.rtable,
        bodyNode: this.$refs['rtable--body'],
        headerNode,
        headerCellStyles,
        height: this.$props.height,
      })
    },
    waitForCss() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    onClickShowAddColumnPopup(e) {
      const { top: topItem, left: columnLeft } = e.target.getBoundingClientRect()
      const { top: topContainer, left: containerLeft } = this.$el.getBoundingClientRect()
      this.addColumnTop = topItem - 5 - topContainer + HEADER_HEIGHT / 2
      this.addColumnLeft = `${columnLeft - containerLeft}px`
      this.showAddColumn = true
      this.showChangeColumn = false
    },
    onClickAddColumn({ key }) {
      this.showAddColumn = false
      this.$data.tableColumns = this.$data.tableColumns.map((c) => {
        let { hidden } = c
        if (c.key === key) {
          hidden = false
        }
        return {
          ...c,
          hidden,
        }
      })
    },
    onClickChangeColumn(e, column, i) {
      const { left: columnLeft, top: topItem } = e.target.getBoundingClientRect()
      const { left: containerLeft, top: topContainer } = this.$el.getBoundingClientRect()
      this.showAddColumn = false
      this.showChangeColumn = true
      this.selectedColumn = column
      this.changeColumnTop = topItem - topContainer + HEADER_HEIGHT / 2
      if (i === this.visibleColumns.length - 1) {
        this.changeColumnRight = '22px'
        this.changeColumnLeft = 'auto'
      } else {
        this.changeColumnRight = 'auto'
        this.changeColumnLeft = `${columnLeft - containerLeft}px`
      }
    },
    onClickDocument(e) {
      const { target } = e
      if (!didNotClickOn(target, {
        classes: ['rtable--popuptrigger'],
      })) {
        return
      }
      this.showAddColumn = false
      this.showChangeColumn = false
    },
    onClickChangeColumnItem({ key }) {
      if (key === 'sort-desc') {
        this.$emit('sort:desc', { value: this.selectedColumn.key })
      }
      if (key === 'sort-asc') {
        this.$emit('sort:asc', { value: this.selectedColumn.key })
      }
      if (key === 'hide-column') {
        this.$data.tableColumns = this.$data.tableColumns.map((c) => ({
          ...c,
          hidden: c.hidden || c.key === this.selectedColumn.key,
        }))
      }
      this.showChangeColumn = false
      this.selectedColumn = null
    },
    onClickBatchSelectAll() {
      this.$data.selectAllPages = false
      if (this.$data.batchSelectAll) {
        this.$data.batchSelectedRows = {
          ...this.$data.batchSelectedRows,
          ...fillBatchSelectedRows(this.$props.rows, true, true),
        }
      } else {
        this.$data.batchSelectedRows = fillBatchSelectedRows(this.$props.rows, false, true)
      }
    },
    onClickBatchSelectThisPageOnly() {
      this.$data.selectAllPages = false
      this.$data.batchSelectedRows = fillBatchSelectedRows(this.$props.rows, true)
    },
    onClickActionItem(actionItem) {
      this.$emit('open:pagination-action-item', actionItem)
    },
  },
}
</script>

<style scoped>
.rtable--cell {
  white-space: nowrap;
}
.rtable--cell:first-child {
  overflow: visible;
}
.rtable--body .rtable--cell {
  padding: 8px 16px;
}
.rtable--header .rtable--cell {
  padding: 0 16px;
}
.rtable /deep/ .rtable--cell:first-child {
  padding-left: 8px;
  padding-right: 8px;
}
.rtable--headerbg {
  position: absolute;
  left: 0;
  right: 0;
}
.rtable--header,
.rtable--headerbg {
  background: var(--r-light-grey);
}
.rtable--header,
.rtable--headerbg,
.rtable--header .rtable--cell,
.rtable--header .rtable--cell .d-flex {
  height: 60px;
}
.rtable--header a {
  color: var(--r-text-color);
}
.rtable--unselectable {
  user-select: none;
}
.rtable--add-column {
  background: var(--r-light-grey);
  border: 1px solid var(--r-grey);
  margin-top: 18px;
  height: 24px;
  border-radius: unset;
  position: absolute;
  top: 0;
  right: -36px;
}
.rtable--loading,
.rtable--nodata {
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--r-border-radius);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.rtable--nodata {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.v-input--checkbox {
  height: 26px;
}
.rtable--row {
  margin-top: 10px;
  width: fit-content;
  min-width: 100%;
}
.rtable--row:first-child {
  margin-top: 0;
}
.rtable--header .v-input--checkbox {
  position: relative;
  top: 9px;
}
.rtable--header {
  padding-right: 17px;
  display: block;
  min-width: 100%;
}
.rtable--table {
  overflow-y: hidden;
  overflow-x: auto;
  display: block;
  min-width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.rtable--body {
  position: relative;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 100%;
}
.rtable {
  position: relative;
  min-width: 100%;
  width: auto !important;
}
.rtable--header .rtable--cell:first-child {
  padding-right: 0;
}
.rtable--body .rtable--batchselect {
  margin: 0;
  padding: 0;
}
</style>
