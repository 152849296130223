<template>
  <v-card
    class="rsearchprogressalertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="rsearchprogressalertcard--header d-flex py-1">
        <v-icon class="rsearchprogressalertcard--icon">
          mdi-magnify
        </v-icon>
        <span class="rsearchprogressalertcard--title text-truncate text-h6 ml-2">
          {{ cardTitle }}
        </span>
      </div>

      <div class="rsearchprogressalertcard--text d-flex">
        <span class="rsearchcard--text">{{ cardSubtitle }}</span>
      </div>

      <div class="rsearchprogressalertcard--progress d-flex my-2 d-flex align-center">
        <v-progress-linear
          :value="progress"
          :color="progressColor"
          height="15"
        />
        <span class="ml-2">{{ progress }}%</span>
      </div>

      <div class="rsearchprogressalertcard--link d-flex">
        <a @click="onViewResultClick">{{ $t('alerts.viewResults') }}</a>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />

      <v-divider class="my-3" />

      <div class="rsearchprogressalertcard--footer d-flex justify-space-between">
        <span class="date-title">Date Upload</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { COLORS } from '@/utils/constants'
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RSearchProgressAlertCard',
  components: { MarkAsSeen },
  props: {
    searchId: {
      type: String,
      required: false,
    },
    searchName: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      if (this.progress === 100) {
        return 'completed'
      }

      return 'in progress'
    },
    progressColor() {
      if (this.progress === 100) {
        return COLORS.SOFT_GREEN
      }

      return COLORS.BLUE
    },
    cardTitle() {
      return `"${this.searchName}" search ${this.status}`
    },
    cardSubtitle() {
      return `Search add is ${this.status}`
    },
  },
  methods: {
    onViewResultClick() {
      this.$router.push(`/data/search/${this.searchId}/results`)
    },
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.rsearchprogressalertcard--text {
  color: var(--r-carbon-color);
}
.rsearchprogressalertcard--link a {
  text-decoration: none;
}

.rsearchprogressalertcard--title {
  color: var(--r-soft-green-color);
}

.rsearchprogressalertcard--icon {
  font-size: 1.5rem !important;
  color: var(--r-soft-green-color);
}

</style>
