<template>
  <v-row
    class="rpagination ma-0"
    :justify="justify"
  >
    <div
      v-for="actionItem in actionItems"
      :key="`action-item-${actionItem.id}`"
      class="pagination-action-item-container"
      @click="onClickActionItem(actionItem)"
    >
      <div class="pagination-action-item-text">
        {{ $t(actionItem.name) }}
      </div>
      <div class="pagination-action-item-divider" />
    </div>
    <r-chip
      icon="chevron_left"
      class="mr-2 rpagination--previous"
      :disabled="page === 1"
      outlined
      circle
      @click="onClickPreviousPage"
    />
    <r-chip
      v-if="showFirstPageLink"
      label="1"
      class="mr-2 rpagination--firstpage"
      outlined
      circle
      @click="onClickPage(1)"
    />
    <span
      v-if="showFirstPageSeparator"
      class="mr-2 rpagination--separator rpagination--separator--previous"
    >...</span>

    <r-chip
      v-for="pageNum in pagesBefore"
      :key="`rpagination--chip--page--${pageNum}`"
      :label="`${pageNum}`"
      :active="page === pageNum"
      :outlined="page !== pageNum"
      class="mr-2 rpagination--page"
      :class="{
        'rpagination--active': page === pageNum,
      }"
      circle
      @click="onClickPage(pageNum)"
    />

    <span
      v-if="showEndPageSeparator"
      class="mr-2 rpagination--separator rpagination--separator--next"
    >...</span>
    <r-chip
      v-if="showEndPageLink"
      :label="`${totalPages}`"
      class="mr-2 rpagination--lastpage"
      outlined
      circle
      @click="onClickPage(totalPages)"
    />
    <r-chip
      icon="chevron_right"
      class="mr-2 rpagination--next"
      :disabled="page === totalPages"
      outlined
      circle
      @click="onClickNextPage"
    />
  </v-row>
</template>

<script>
import RChip from '@/components/library/atoms/RChip'

const NUM_PAGES_AROUND = 2

export default {
  name: 'RPagination',
  components: {
    RChip,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    justify: {
      type: String,
      default: 'start',
    },
    actionItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    pagesBefore() {
      const { page, totalPages } = this.$props
      const pages = []
      const start = Math.max(1, page - NUM_PAGES_AROUND)
      const end = Math.min(totalPages, page + NUM_PAGES_AROUND)
      for (let i = start; i <= end; i += 1) {
        pages.push(i)
      }
      return pages
    },
    showFirstPageLink() {
      const { page } = this.$props
      return page - NUM_PAGES_AROUND - 1 > 0
    },
    showFirstPageSeparator() {
      const { page } = this.$props
      return page - NUM_PAGES_AROUND - 2 > 0
    },
    showEndPageLink() {
      const { page, totalPages } = this.$props
      return page + NUM_PAGES_AROUND < totalPages
    },
    showEndPageSeparator() {
      const { page, totalPages } = this.$props
      return page + NUM_PAGES_AROUND + 1 < totalPages
    },
  },
  methods: {
    onClickPage(pageNum) {
      const { page } = this.$props
      if (pageNum !== page) {
        this.$emit('click:page', { value: pageNum })
      }
    },
    onClickPreviousPage() {
      const { page } = this.$props
      if (page > 1) {
        this.$emit('click:page', { value: page - 1 })
      }
    },
    onClickNextPage() {
      const { page, totalPages } = this.$props
      if (page < totalPages) {
        this.$emit('click:page', { value: page + 1 })
      }
    },
    onClickActionItem(actionItem) {
      this.$emit('open:pagination-action-item', actionItem)
    },
  },
}
</script>

<style scoped>

.pagination-action-item-container{
  display: contents;
}
.pagination-action-item-divider {
  background-color: #E0E0E0;
  border: solid #E0E0E0 1px;
  height: 21px;
  margin-left: 13px;
  margin-right: 13px;
}
.pagination-action-item-text{
  margin-top: 2px;
  color: #239EDB;
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.03px;
  cursor: pointer;
}
.rpagination .mr-2:last-child {
  margin-right: 0 !important;
}
.rpagination--separator {
  user-select: none;
}
</style>
