<template>
  <div class="completebcrlink">
    <v-alert
      type="success"
      dense
      class="px-2"
    >
      <span>{{ $t('bcrLinks.statuses.complete.title', { dataType: formattedDataType }) }}</span>
    </v-alert>

    <p>
      <span>{{ $t('bcrLinks.statuses.complete.message', { name }) }}</span>
    </p>

    <p>
      <span>
        {{ historicalDataMessage }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CompleteBCRLink',
  props: {
    name: {
      type: String,
      required: true,
    },
    formattedDataType: {
      type: String,
      required: true,
    },
    monthsHistoricData: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isAllDataUploaded() {
      return this.monthsHistoricData === 99999
    },
    historicalDataMessage() {
      if (this.isAllDataUploaded) {
        return this.$t('bcrLinks.statuses.complete.allDataUploaded')
      }

      return this.$t('bcrLinks.statuses.complete.historicalDataMessage', { monthsHistoricData: this.monthsHistoricData })
    },
  },
}
</script>
