<template>
  <r-modal
    v-if="show"
    :title="$t('bcrLinks.statuses.title')"
    class="bcrlinkstatus"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <r-error-message
        class="mb-3"
        :errors="errors"
      />

      <v-row
        v-if="isFetchingStatus"
        justify="center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-row>

      <div
        v-else
        class="bcrlinkstatus--content"
      >
        <failed-b-c-r-link
          v-if="isFailedLink"
          :name="bcrLinkStatus.name"
          :data-type="dataType"
          :formatted-data-type="formattedDataType"
        />
        <pending-b-c-r-link
          v-if="isPendingLink"
          :name="bcrLinkStatus.name"
          :formatted-data-type="formattedDataType"
        />
        <running-b-c-r-link
          v-if="isInProgressLink"
          :name="bcrLinkStatus.name"
          :num-linked-reviews="bcrLinkStatus.numLinkedReviews"
          :formatted-data-type="formattedDataType"
        />
        <complete-b-c-r-link
          v-if="isCompleteLink"
          :name="bcrLinkStatus.name"
          :months-historic-data="bcrLinkStatus.monthsHistoricData"
          :formatted-data-type="formattedDataType"
        />
      </div>
    </template>

    <template v-slot:customActions>
      <div v-if="isFailedLink">
        <r-button
          :outlined="true"
          class="close-btn mr-1"
          :label="$t('global.actions.cancel')"
          @click="closeModal"
        />
        <r-button
          class="close-btn"
          :label="$t('global.actions.connect')"
          :loading="isFetchingStatus || isFetchingRetry"
          @click="onClickRetryConnection"
        />
      </div>
      <div v-if="isPendingLink || isInProgressLink || isCompleteLink">
        <r-button
          :outlined="true"
          class="close-btn mr-1"
          :label="$t('global.actions.confirm')"
          @click="closeModal"
        />
      </div>
    </template>
  </r-modal>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import RButton from '@/components/library/atoms/RButton'
import FailedBCRLink from '@/components/app/bcrlink/FailedBCRLink'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import PendingBCRLink from '@/components/app/bcrlink/PendingBCRLink'
import RunningBCRLink from '@/components/app/bcrlink/RunningBCRLink'
import CompleteBCRLink from '@/components/app/bcrlink/CompleteBCRLink'
import { DATA_TYPES } from '@/utils/constants'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BCRLinkStatus',
  components: {
    CompleteBCRLink,
    RunningBCRLink,
    PendingBCRLink,
    RModal,
    RButton,
    FailedBCRLink,
    RErrorMessage,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('urls', {
      urlBcrLinkStatus: 'bcrLinkStatus',
      fetchingUrlBCRLinkStatus: 'fetchingBCRLinkStatus',
      fetchUrlBCRLinkStatusError: 'fetchBCRLinkStatusError',

      fetchingRetryLinkUrlToBCR: 'fetchingRetryLinkUrlToBCR',
      fetchingRetryLinkUrlToBCRError: 'fetchingRetryLinkUrlToBCRError',
    }),
    ...mapState('products', {
      productBcrLinkStatus: 'bcrLinkStatus',
      fetchingProductBCRLinkStatus: 'fetchingBCRLinkStatus',
      fetchProductBCRLinkStatusError: 'fetchBCRLinkStatusError',

      fetchingRetryLinkProductToBCR: 'fetchingRetryLinkProductToBCR',
      fetchingRetryLinkProductToBCRError: 'fetchingRetryLinkProductToBCRError',
    }),
    bcrLinkStatus() {
      if (this.urlBcrLinkStatus) {
        return this.urlBcrLinkStatus
      }

      return this.productBcrLinkStatus
    },
    errors() {
      return [
        this.fetchUrlBCRLinkStatusError,
        this.fetchingRetryLinkUrlToBCRError,
        this.fetchProductBCRLinkStatusError,
        this.fetchingRetryLinkProductToBCRError,
      ]
    },
    isFetchingRetry() {
      return this.fetchingRetryLinkUrlToBCR || this.fetchingRetryLinkProductToBCR
    },
    isFetchingStatus() {
      return this.fetchingUrlBCRLinkStatus || this.fetchingProductBCRLinkStatus
    },
    isFailedLink() {
      return this.isInStatus('failed')
    },
    isPendingLink() {
      return this.isInStatus('ready')
    },
    isInProgressLink() {
      return this.isInStatus('in_progress')
    },
    isCompleteLink() {
      return this.isInStatus('complete')
    },
    dataType() {
      const { dataType } = this.bcrLinkStatus

      return dataType
    },
    formattedDataType() {
      const LABELS_BY_DATA_TYPE = {
        [DATA_TYPES.PRODUCTS]: this.$t('resources.product'),
        [DATA_TYPES.URLS]: this.$t('resources.url'),
      }

      return LABELS_BY_DATA_TYPE[this.dataType]
    },
  },
  methods: {
    ...mapActions('urls', [
      'fetchRetryLinkUrlToBCR',
    ]),
    ...mapActions('products', [
      'fetchRetryLinkProductToBCR',
    ]),
    isInStatus(expectedStatus) {
      if (this.isFetchingStatus) {
        return ''
      }

      const { status } = this.bcrLinkStatus
      return status === expectedStatus
    },
    closeModal() {
      const value = { refresh: false }
      return this.$emit('close', { value })
    },
    retryLinkUrlToBCR() {
      const { urlId } = this.bcrLinkStatus
      this.fetchRetryLinkUrlToBCR({ urlIds: [urlId] }).then(() => {
        if (!this.fetchingRetryLinkUrlToBCRError) {
          const value = { refresh: true, dataType: DATA_TYPES.URLS }
          this.$emit('close', { value })
        }
      })
    },
    retryLinkProductToBCR() {
      const { productId } = this.bcrLinkStatus
      this.fetchRetryLinkProductToBCR({ productIds: [productId] }).then(() => {
        if (!this.fetchingRetryLinkProductToBCRError) {
          const value = { refresh: true, dataType: DATA_TYPES.PRODUCTS }
          this.$emit('close', { value })
        }
      })
    },
    async onClickRetryConnection() {
      const { dataType } = this.bcrLinkStatus

      const ACTIONS_BT_DATA_TYPE = {
        [DATA_TYPES.PRODUCTS]: this.retryLinkProductToBCR,
        [DATA_TYPES.URLS]: this.retryLinkUrlToBCR,
      }

      return ACTIONS_BT_DATA_TYPE[dataType]()
    },
  },
}
</script>
<style scoped>
/deep/ .v-alert__wrapper {
  padding: 10px;
}

.bcrlinkstatus--content {
  width: 600px;

}
</style>
