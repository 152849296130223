<template>
  <div class="ralertcard">
    <r-search-progress-alert-card
      v-if="isSearchProgressAlertType"
      :search-id="data.searchId"
      :search-name="data.searchName"
      :progress="data.progress"
      :date="dateToReadable"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
    <r-search-error-alert-card
      v-if="isSearchErrorAlertType"
      :date="dateToReadable"
      :search-name="data.searchName"
      :search-id="data.searchId"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
    <r-search-new-founds-alert-card
      v-if="isSearchNewFoundsAlertType"
      :date="dateToReadable"
      :search-name="data.searchName"
      :search-id="data.searchId"
      :num-founds="data.newFounds"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
    <r-url-error-alert-card
      v-if="isUrlErrorAlertType"
      :date="dateToReadable"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
    <r-upload-complete-alert-card
      v-if="isUploadCompleteAlertType"
      :upload-name="data.uploadName"
      :date="dateToReadable"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
    <r-new-product-alert-card
      v-if="isNewProductAlertType"
      :upload-name="data.uploadName"
      :date="dateToReadable"
      :num-product="data.numProduct"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
    <r-non-canonical-alert-card
      v-if="isNonCanonicalAlertType"
      :date="dateToReadable"
      :canonical-url="data.canonicalUrl"
      :non-canonical-url="data.nonCanonicalUrl"
      :alert-id="alertId"
      :show-hide-icon="showHideIcon"
      @on:mark-as-seen="onMarkAsSeen"
    />
  </div>
</template>

<script>
import { ALERT_TYPES } from '@/utils/constants'
import dateStrToDate from '@/utils/dateStrToDate'
import formatDate from '@/utils/formatDate'
import RSearchProgressAlertCard from '@/components/library/molecules/RSearchProgressAlertCard'
import RUrlErrorAlertCard from '@/components/library/molecules/RUrlErrorAlertCard'
import RUploadCompleteAlertCard from '@/components/library/molecules/RUploadCompleteAlertCard'
import RNonCanonicalAlertCard from '@/components/library/molecules/RNonCanonicalAlertCard'
import RNewProductAlertCard from '@/components/library/molecules/RNewProductAlertCard'
import RSearchErrorAlertCard from '@/components/library/molecules/RSearchErrorAlertCard'
import RSearchNewFoundsAlertCard from '@/components/library/molecules/RSearchNewFoundsAlertCard'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RAlertCard',
  components: {
    RSearchProgressAlertCard,
    RUrlErrorAlertCard,
    RUploadCompleteAlertCard,
    RNewProductAlertCard,
    RNonCanonicalAlertCard,
    RSearchErrorAlertCard,
    RSearchNewFoundsAlertCard,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    clientCanLinkToBCR: {
      type: Boolean,
      default: false,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('alerts', [
      'fetchingMarkAsSeenError',
      'fetchingMarkAsSeen',
    ]),
    isSearchErrorAlertType() {
      return this.$props.type === ALERT_TYPES.SEARCH_ERRORS
    },
    isSearchNewFoundsAlertType() {
      return this.$props.type === ALERT_TYPES.SEARCH_NEW_FOUNDS
    },
    isSearchProgressAlertType() {
      return this.$props.type === ALERT_TYPES.SEARCH_PROGRESS
    },
    isUrlErrorAlertType() {
      return this.$props.type === ALERT_TYPES.URL_ERROR
    },
    isUploadCompleteAlertType() {
      return this.$props.type === ALERT_TYPES.UPLOAD_COMPLETED
    },
    isNewProductAlertType() {
      return this.$props.type === ALERT_TYPES.NEW_PRODUCT
    },
    isNonCanonicalAlertType() {
      return this.$props.type === ALERT_TYPES.NON_CANONICAL_URL
    },
    dateToReadable() {
      const date = dateStrToDate(this.$props.date)
      return formatDate({ $moment: this.$moment, date })
    },
  },
  methods: {
    ...mapActions('alerts', [
      'markAsSeen',
      'fetchRecentAlerts',
    ]),
    async onMarkAsSeen({ alertId }) {
      await this.markAsSeen({ alertId })
      if (!this.fetchingMarkAsSeenError) {
        this.fetchRecentAlerts()
      }
    },
  },
}
</script>

<style scoped>
/deep/ .v-progress-linear {
  border-radius: 3px;
}
</style>
