<template>
  <r-popup-menu
    v-if="menuPosition"
    :position="menuPosition"
    :width="270"
    :height="200"
    :margin-left="-200"
    class="varianturlspopup"
    @close="onClose"
  >
    <template v-slot:content>
      <div class="varianturlspopup--content">
        <div class="varianturlspopup--title d-flex justify-center align-center py-1 mt-2">
          {{ $t('variantUrlsPopup.title') }}
        </div>

        <v-divider class="my-1" />

        <div
          v-for="variant in variantUrls"
          :key="variant"
          class="varianturlspopup--item"
        >
          {{ variant }}
        </div>
      </div>
    </template>
  </r-popup-menu>
</template>

<script>
import RPopupMenu from '@/components/library/atoms/RPopupMenu'

export default {
  name: 'VariantUrlsPopup',
  components: {
    RPopupMenu,
  },
  props: {
    menuPosition: {
      type: Object,
      required: false,
    },
    variantUrls: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.varianturlspopup {
  overflow-y: auto;
}
.varianturlspopup--title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.varianturlspopup--content {
  padding: 5px 10px;
}

</style>
