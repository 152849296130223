<template>
  <v-card
    class="rnoncanonicalalertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="rnoncanonicalalertcard--header d-flex py-1">
        <v-icon class="rnoncanonicalalertcard--icon">
          mdi-alert-circle
        </v-icon>
        <span class="rnoncanonicalalertcard--title text-truncate text-h6 ml-2">
          URLs replaced
        </span>
      </div>

      <div class="rnoncanonicalalertcard--text d-flex">
        <span class="rnoncanonicalalertcard--text">
          URL
          <a
            :href="nonCanonicalUrl"
            :title="nonCanonicalUrl"
            target="_blank"
            v-html="abbrevNonCanonicalUrl"
          />
          has been replaced with the canonical URL
          <a
            :href="canonicalUrl"
            :title="canonicalUrl"
            target="_blank"
            v-html="abbrevCanonicalUrl"
          />
        </span>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />
      <v-divider class="my-3" />

      <div class="rnoncanonicalalertcard--footer d-flex justify-space-between">
        <span class="date-title">Date</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import abbrevUrl from '@/utils/abbrevUrl'
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RNonCanonicalAlertCard',
  components: { MarkAsSeen },
  props: {
    date: {
      type: String,
      required: true,
    },
    canonicalUrl: {
      type: String,
      required: true,
    },
    nonCanonicalUrl: {
      type: String,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    abbrevCanonicalUrl() {
      return abbrevUrl(this.$props.canonicalUrl)
    },
    abbrevNonCanonicalUrl() {
      return abbrevUrl(this.$props.nonCanonicalUrl)
    },
  },
  methods: {
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.rnoncanonicalalertcard--text {
  color: var(--r-carbon-color);
}
.rnoncanonicalalertcard--link a {
  text-decoration: none;
}

.rnoncanonicalalertcard--title,
.rnoncanonicalalertcard--icon {
  color: var(--r-warning-color);
}

.rnoncanonicalalertcard--icon {
  font-size: 1.5rem !important;
}

@media all and (max-width: 1120px) {
  .rnoncanonicalalertcard--text {
    max-width: 100%;
  }
}

</style>
