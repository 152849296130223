<template>
  <div class="rdatacard">
    <r-data-brand-card
      v-if="type === DASHBOARD_TYPES.BRAND"
      :name="name"
      :num-products="numProducts"
      :num-urls="numUrls"
    />
    <r-data-category-card
      v-else-if="type === DASHBOARD_TYPES.CATEGORY"
      :name="name"
      :num-products="numProducts"
      :num-urls="numUrls"
    />
    <r-data-product-card
      v-else-if="type === DASHBOARD_TYPES.PRODUCT"
      :id="id"
      :name="name"
      :rating="rating"
      :num-urls="numUrls"
      :client-can-link-to-b-c-r="clientCanLinkToBCR"
      :bcr-link-job-ids="bcrLinkJobIds"
      @click:bcrlink-status="$emit('click:bcrlink-status', $event)"
    />
    <r-data-tag-card
      v-else-if="type === DASHBOARD_TYPES.TAG"
      :name="name"
      :num-products="numProducts"
      :num-urls="numUrls"
    />
    <r-data-url-card
      v-else-if="type === DASHBOARD_TYPES.URL"
      :id="id"
      :name="name"
      :rating="rating"
      :url="url"
      :hostname="hostname"
      :created="created"
      :last-update="lastUpdate"
      :client-can-link-to-b-c-r="clientCanLinkToBCR"
      :bcr-link-job-id="bcrLinkJobId"
      @click:bcrlink-status="$emit('click:bcrlink-status', $event)"
    />
  </div>
</template>

<script>
import { DASHBOARD_TYPES, RESOURCE_TYPE_TO_ICON } from '@/utils/constants'
import RDataBrandCard from '@/components/library/molecules/RDataBrandCard'
import RDataCategoryCard from '@/components/library/molecules/RDataCategoryCard'
import RDataProductCard from '@/components/library/molecules/RDataProductCard'
import RDataTagCard from '@/components/library/molecules/RDataTagCard'
import RDataUrlCard from '@/components/library/molecules/RDataUrlCard'
import { mapGetters } from 'vuex'

export default {
  name: 'RDataCard',
  components: {
    RDataBrandCard,
    RDataCategoryCard,
    RDataProductCard,
    RDataTagCard,
    RDataUrlCard,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: DASHBOARD_TYPES.PRODUCT,
      validator: (val) => Object.values(DASHBOARD_TYPES).includes(val),
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    hostname: {
      type: String,
      required: false,
    },
    created: {
      type: String,
      required: false,
    },
    numProducts: Number,
    numReviews: Number,
    numUrls: Number,
    rating: {
      type: Number,
      required: false,
    },
    lastUpdate: String,
    modified: String,
    bcrLinkJobId: {
      type: String,
      required: false,
    },
    bcrLinkJobIds: {
      type: Array,
      required: false,
    },
    progressPercent: {
      type: Number,
      required: false,
    },
    bookmark: {
      type: Boolean,
      default: false,
    },
    sentimentDistribution: {
      type: Array,
      required: false,
    },
    ratingDistribution: {
      type: Array,
      required: false,
    },
    productIds: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    DASHBOARD_TYPE_TO_ICON: RESOURCE_TYPE_TO_ICON,
    DASHBOARD_TYPES,
  }),
  computed: {
    ...mapGetters('users', [
      'clientCanLinkToBCR',
    ]),
  },
}
</script>

<style scoped>
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
