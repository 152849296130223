var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "failedbcrlink"
  }, [_c('v-alert', {
    staticClass: "px-2",
    attrs: {
      "type": "error",
      "dense": ""
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('bcrLinks.statuses.failed.title')))])]), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('bcrLinks.statuses.failed.message', {
    dataType: _vm.formattedDataType,
    name: _vm.name
  })) + " ")])]), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('bcrLinks.statuses.failed.retry')) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }