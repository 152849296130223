var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ralertcard"
  }, [_vm.isSearchProgressAlertType ? _c('r-search-progress-alert-card', {
    attrs: {
      "search-id": _vm.data.searchId,
      "search-name": _vm.data.searchName,
      "progress": _vm.data.progress,
      "date": _vm.dateToReadable,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _vm.isSearchErrorAlertType ? _c('r-search-error-alert-card', {
    attrs: {
      "date": _vm.dateToReadable,
      "search-name": _vm.data.searchName,
      "search-id": _vm.data.searchId,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _vm.isSearchNewFoundsAlertType ? _c('r-search-new-founds-alert-card', {
    attrs: {
      "date": _vm.dateToReadable,
      "search-name": _vm.data.searchName,
      "search-id": _vm.data.searchId,
      "num-founds": _vm.data.newFounds,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _vm.isUrlErrorAlertType ? _c('r-url-error-alert-card', {
    attrs: {
      "date": _vm.dateToReadable,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _vm.isUploadCompleteAlertType ? _c('r-upload-complete-alert-card', {
    attrs: {
      "upload-name": _vm.data.uploadName,
      "date": _vm.dateToReadable,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _vm.isNewProductAlertType ? _c('r-new-product-alert-card', {
    attrs: {
      "upload-name": _vm.data.uploadName,
      "date": _vm.dateToReadable,
      "num-product": _vm.data.numProduct,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _vm.isNonCanonicalAlertType ? _c('r-non-canonical-alert-card', {
    attrs: {
      "date": _vm.dateToReadable,
      "canonical-url": _vm.data.canonicalUrl,
      "non-canonical-url": _vm.data.nonCanonicalUrl,
      "alert-id": _vm.alertId,
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }