<template>
  <div class="rdatafilterchips">
    <div
      v-for="(item, index) of items"
      :key="index"
      class="rdatafilterchips--chip"
    >
      <span class="rdatafilterchips--label">
        {{ `${item.label}: ${item.value}` }}
      </span>
      <v-icon
        class="rdatafilterchips--icon"
        @click="onRemove(item)"
      >
        close
      </v-icon>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

const filterLabelMapping = {
  productIds: 'Product',
  domainIds: 'Domain',
  tagIds: 'Tag',
  brandIds: 'Brand',
  categoryIds: 'Category',
  minReviews: 'Min Reviews',
  maxReviews: 'Max Reviews',
  minRating: 'Min Rating',
  maxRating: 'Max Rating',
  minCreationDate: 'Min Creation Date',
  maxCreationDate: 'Max Creation Date',
  minLastReviewedDate: 'Min Last Reviewed',
  maxLastReviewedDate: 'Max Last Reviewed',
  hostnames: 'Hostnames',
  search: 'Search',
}
export default {
  name: 'RDataFilterChips',
  computed: {
    ...mapState('products', [
      'productsSummary',
    ]),
    ...mapState('domains', [
      'domains',
    ]),
    ...mapState('brands', [
      'brandsSummary',
    ]),
    ...mapState('categories', [
      'categoriesSummary',
    ]),
    ...mapState('tags', [
      'tagsSummary',
    ]),
    ...mapState('urls', [
      'hostnames',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
    items() {
      let chips = []
      const keys = Object.keys(this.appliedFilters)

      keys.forEach((key) => {
        const label = filterLabelMapping[key]
        const value = this.appliedFilters[key]
        if (Array.isArray(value)) {
          if (key === 'productIds') {
            chips = chips.concat(
              this.convertArrayToChipObject(this.productsSummary, label, value, key),
            )
          } else if (key === 'brandIds') {
            chips = chips.concat(
              this.convertArrayToChipObject(this.brandsSummary, label, value, key),
            )
          } else if (key === 'tagIds') {
            chips = chips.concat(
              this.convertArrayToChipObject(this.tagsSummary, label, value, key),
            )
          } else if (key === 'categoryIds') {
            chips = chips.concat(
              this.convertArrayToChipObject(this.categoriesSummary, label, value, key),
            )
          } else if (key === 'domainIds') {
            chips = chips.concat(this.convertArrayToChipObject(this.domains, label, value, key))
          } else if (key === 'hostnames') {
            chips = chips.concat(this.convertArrayToChipObject(this.hostnames, label, value, key))
          } else if (key === 'search') {
            chips = chips.concat(this.convertArrayToChipObject(this.search, label, value, key))
          }
        } else {
          chips.push({
            key,
            label,
            value,
          })
        }
      })
      return chips
    },
  },
  methods: {
    ...mapActions('filters', [
      'setFilters',
      'resetFilters',
    ]),
    convertArrayToChipObject(collection, label, selected, key) {
      const chips = []
      selected.forEach((id) => {
        const queryField = key === 'hostnames' ? 'name' : '_id'
        const datum = collection.find((_datum) => _datum[queryField] === id)
        if (datum) {
          chips.push({
            id: datum._id,
            key,
            label,
            value: datum.name,
          })
        }
      })
      return chips
    },
    onRemove(item) {
      const { id, key, value } = item
      const queryField = key === 'hostnames' ? value : id
      const filters = this.appliedFilters

      filters[key] = (id || key === 'hostnames') ? filters[key].filter((_id) => _id !== queryField) : null

      this.resetFilters()
      this.setFilters(filters)
    },
  },
}
</script>

<style scoped>
  .rdatafilterchips--chip {
    display: inline-block;
    padding: 5px 10px;
    margin-top: 5px;
    margin-right: 6px;
    color: var(--r-text-color);
    background-color: var(--r-light-grey);
    border-radius: var(--r-border-radius);
    position: relative;
  }
  .rdatafilterchips--label {
    line-height: 1;
    font-size: 0.92rem;
  }
  .rdatafilterchips--icon {
    position: absolute;
    top: -7px;
    right: -6px;
    cursor: pointer;
  }
</style>
