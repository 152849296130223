<template>
  <div
    class="ranchoredpopup"
    :class="[anchor, { active: showPopup }]"
  >
    <div class="relative-wrapper">
      <r-button
        ref="anchorButton"
        :disabled="disabled"
        class="anchor-button ml-2"
        :icon="icon"
        :fab="fabIcon"
        outlined
        :small="smallButton"
        @click="onButtonClick"
      >
        <template v-slot:default>
          <span :class="{'pl-1': !!label}">{{ label }}</span>
        </template>
      </r-button>
      <div
        v-if="showPopup"
        ref="anchorCardContainer"
        class="anchor-card--container"
        :min-width="width"
        :min-height="height"
      >
        <v-card>
          <div
            v-if="anchor==='top-left' || anchor==='top-right'"
            class="popup-button-wrapper"
          >
            <r-button
              :label="label"
              :icon="icon"
              :fab="fabIcon"
              :small="smallButton"
              @click="onClosePopup"
            />
          </div>
          <div
            ref="contentwrapper"
            class="content-wrapper"
          >
            <slot
              :onClose="onClosePopup"
            />
          </div>
          <div
            v-if="anchor==='bottom-left' || anchor==='bottom-right'"
            class="popup-button-wrapper"
          >
            <r-button
              :label="label"
              :icon="icon"
              :fab="fabIcon"
              :small="smallButton"
              @click="onClosePopup"
            />
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'

const ANCHOR_POSITION = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
}

const REPOSITION_FOR_ANCHORS = [
  ANCHOR_POSITION.TOP_RIGHT,
  ANCHOR_POSITION.BOTTOM_RIGHT,
  ANCHOR_POSITION.TOP_LEFT,
  ANCHOR_POSITION.BOTTOM_LEFT,
]

const COMPONENT_PADDING = 16

export default {
  name: 'RAnchoredPopup',
  components: {
    RButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    anchor: {
      type: String,
      default: ANCHOR_POSITION.TOP_RIGHT,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    fabIcon: {
      type: Boolean,
      default: false,
    },
    smallButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPopup: false,
  }),
  watch: {
    showPopup(shown) {
      if (!shown) {
        return
      }
      this.$nextTick(() => {
        this.positionPopup()
      })
    },
  },
  methods: {
    onButtonClick(e) {
      e.preventDefault()

      this.showPopup = !this.showPopup
    },
    positionPopup() {
      if (!REPOSITION_FOR_ANCHORS.includes(this.$props.anchor)) {
        return
      }

      const cardEl = this.$refs.anchorCardContainer
      if (!cardEl) {
        return
      }

      const styles = {}
      if (this.$props.anchor.includes('right')) {
        styles.right = `-${COMPONENT_PADDING}px`
      }

      if (this.$props.anchor.includes('left')) {
        styles.left = `-${COMPONENT_PADDING}px`
      }

      if (this.$props.anchor.includes('top')) {
        styles.top = `-${COMPONENT_PADDING}px`
      }

      if (this.$props.anchor.includes('bottom')) {
        styles.bottom = `-${COMPONENT_PADDING}px`
      }

      cardEl.setAttribute('style', Object.entries(styles).map(([key, value]) => `${key}: ${value}`).join(';'))
    },
    onClosePopup() {
      this.$data.showPopup = false
    },
  },
}
</script>

<style scoped>
/*
  I added this to make `position:relative` and `position:absolute` to always work the same way.
  Without having `position: relative` in container with `display: flex`, CSS `right` property would
  work inconsistently. Sometimes `anchor-card--container` would be pushed from it's right border,
  sometimes from the right of the `anchor-button`. This extra wrapping with `.relative-wrapper`
  inside of `.ranchoredpopup` makes `right` property to always push element from right of the
  `anchor-button`.
*/
.ranchoredpopup {
  display: flex;
}
.ranchoredpopup.active {
  z-index: 2;
}
.relative-wrapper {
  position: relative;
}
/*
  `grid-auto-rows` is used to make sure that the `popup-button` at the bottom of the
  `anchor-card--container` would be positioned correctly if the component height is much greater
  than the content height.
  Without this the button wouldn't be rendered at the bottom of the card, even though `bottom-left`
  or `bottom-right` anchors would be provided.
*/
.anchor-card--container {
  display: grid;
  grid-auto-rows: auto 1fr;

  position: absolute;
  /*
  This is added to remove v-card's `max-width:100%`,
   because it does not allow popup to have bigger width than it's parent.
   After removing `max-width`, the content of v-card does not overflow.
  */
  max-width: none;
  z-index: 9999;
  padding-top: 2px;
  padding-bottom: 2px;
}
.popup-button-wrapper {
  display: flex;
  padding: 16px;
  align-self: end;
}
.top-left .popup-button-wrapper,
.bottom-left .popup-button-wrapper {
  justify-content: flex-start;
}
.top-right .popup-button-wrapper,
.bottom-right .popup-button-wrapper {
  justify-content: flex-end;
}
.v-btn__content {
  display: flex;
  text-align: end;
}
</style>
