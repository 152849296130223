<template>
  <v-card
    class="rdataurlcard box-shadow-custom"
    max-width="240"
  >
    <div class="pa-4">
      <div class="rdataurlcard--header d-flex mb-2">
        <span
          class="rdataurlcard--title text-truncate"
          :title="url"
        >
          {{ name }}
        </span>
        <v-icon class="rdataurlcard--icon ml-2">
          {{ DASHBOARD_TYPE_TO_ICON[DASHBOARD_TYPES.URL] }}
        </v-icon>
      </div>

      <div class="rdataurlcard--body d-flex">
        <r-data-image
          class="mr-2"
          :type="DASHBOARD_TYPES.URL"
          :size="42"
          :ids="ids"
        />
        <div class="flex-expand">
          <r-chip
            :label="hostname"
            :title="url"
          />
          <div class="d-flex justify-end align-end">
            <r-button
              v-if="showBCRStatusButton"
              color="grey"
              class="bcrlinkstatus--button"
              label=""
              outlined
              icon="cloud"
              @click="onClickBCRLinkStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import RChip from '@/components/library/atoms/RChip'
import RButton from '@/components/library/atoms/RButton'
import RDataImage from '@/components/library/atoms/RDataImage'
import { DASHBOARD_TYPES, DATA_TYPES, RESOURCE_TYPE_TO_ICON } from '@/utils/constants'
import readableTimeSince from '@/utils/readableTimeSince'

export default {
  name: 'RDataUrlCard',
  components: {
    RChip,
    RButton,
    RDataImage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    hostname: {
      type: String,
      required: true,
    },
    created: {
      type: String,
      required: true,
    },
    lastUpdate: {
      type: String,
      required: false,
    },
    bcrLinkJobId: {
      type: String,
      required: false,
    },
    clientCanLinkToBCR: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    DASHBOARD_TYPES,
    DASHBOARD_TYPE_TO_ICON: RESOURCE_TYPE_TO_ICON,
  }),
  computed: {
    ids() {
      return [this.$props.id]
    },
    readableTimeSinceLastUpdate() {
      return readableTimeSince({ $moment: this.$moment, date: this.$props.lastUpdate })
    },
    hasBCRLinkJobId() {
      return !!this.bcrLinkJobId
    },
    showBCRStatusButton() {
      return this.hasBCRLinkJobId && this.clientCanLinkToBCR
    },
  },
  methods: {
    onClickBCRLinkStatus() {
      const value = { itemId: this.$props.id, dataType: DATA_TYPES.URLS }
      this.$emit('click:bcrlink-status', { value })
    },
  },
}
</script>

<style scoped>
.rdataurlcard--title {
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
}
.rdataurlcard--icon {
  flex: 0 0;
}
/deep/ .rdataurlcard--footer .v-icon {
  margin-top: -2px;
}
.bcrlinkstatus--button {
  border: none;
  padding: 0 !important;
  min-height: 10px !important;
}
.flex-expand {
  flex: 1;
}
</style>
