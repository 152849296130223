<template>
  <v-row class="ma-0 categoriesdropdown d-flex justify-center">
    <r-select
      key="categoriesdropdown--rselect"
      class="categoriesdropdown--select"
      :items="items"
      :show-search="true"
      :search-placeholder="$t('categoriesDropdown.searchPlaceHolderText')"
      :allow-empty="false"
      :multiple="true"
      :infinite="true"
      :disabled="disabled"
      :always-open="alwaysOpen"
      @change="onSelectCategory"
      @click:prepend-item="onClickCreateNewCategory"
      @on:get-more="onGetMoreItems"
      @change:search="onSearchChange"
    >
      <template
        v-if="showAutogeneratedCategoriesFilter"
        v-slot:filters
      >
        <div class="categoriesdropdown--filters pl-2">
          <r-switch
            :value="showAutogeneratedCategories"
            :disabled="disabled"
            @change="onShowAutogenerateCategoriesFilterChange"
          />
          <label class="px-2">
            {{ $t('categoriesDropdown.filters.showAutomaticallyCreated') }}
          </label>
        </div>
      </template>
      <template v-slot:item="slotProps">
        <div>
          <r-badge
            :name="slotProps.item.label"
            :color="slotProps.item.color"
            prepend-icon="mdi-view-grid"
          />
        </div>
      </template>
      <template v-slot:prepend-item>
        <div class="d-flex rselectitem">
          <button
            class="rcategoriesdropdown--create-category-button rselectitem--row"
            @click="onClickCreateNewCategory"
          >
            <v-icon
              class="pl-2"
              dense
            >
              add
            </v-icon>
            <span>{{ $t('categoriesDropdown.create') }}</span>
          </button>
        </div>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect'
import RBadge from '@/components/library/atoms/RBadge'
import RSwitch from '@/components/library/atoms/RSwitch'
import { generateParams } from '@/utils/store'

export default {
  name: 'CategoriesDropdown',
  components: {
    RBadge,
    RSelect,
    RSwitch,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    preselectedCategories: {
      type: Array,
      default: () => [],
    },
    showAutogeneratedCategoriesFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showAutogeneratedCategories: true,
    searchValue: '',
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('categories', [
      'fetchingCategories',
      'categoriesSummary',
      'fetchCategoriesError',
    ]),
    items() {
      if (!this.showAutogeneratedCategories) {
        return this.mapCategoriesToDropdownItems({
          categories: this.categoriesSummary.filter(({ autoGenerated }) => !autoGenerated),
        })
      }

      return this.mapCategoriesToDropdownItems({
        categories: this.categoriesSummary,
      })
    },
  },
  mounted() {
    this.fetchCategoriesSummary({ projectId: this.selectedProjectId })
  },
  methods: {
    ...mapActions('categories', [
      'fetchCategoriesSummary',
    ]),
    onClickCreateNewCategory() {
      this.$emit('open:create-category-modal')
    },
    onSelectCategory({ value: selectedItems }) {
      this.$emit('change', { value: selectedItems, items: this.items })
    },
    onShowAutogenerateCategoriesFilterChange({ value: showAutogeneratedCategories }) {
      this.showAutogeneratedCategories = showAutogeneratedCategories
    },
    mapCategoriesToDropdownItems({ categories }) {
      const preselectedCategoryIds = this.preselectedCategories.map(({ _id }) => _id)
      return categories.map(({ _id, name, color }) => ({
        label: name,
        value: _id,
        selected: preselectedCategoryIds.includes(_id),
        color,
      }))
    },
    onGetMoreItems() {
      const pageParams = generateParams('categories')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchCategoriesSummary(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchCategoriesSummary({ projectId: this.selectedProjectId, search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
.categoriesdropdown--select {
  width: 100%;
}
.categoriesdropdown--filters {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid var(--r-border-color);
  align-items: center;
  padding: 8px 0;
  display: flex;
}
.rcategoriesdropdown--create-category-button {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--r-border-color);
}
.rcategoriesdropdown--create-category-button span {
  margin-left: 19px;
  font-size: 12px;
  color: rgba(63, 63, 63, 0.75);
}
.rselectitem .rselectitem--row:hover {
  background: var(--r-light-grey);
  cursor: pointer;
}
</style>
