<template>
  <v-card
    class="rdatacategorycard box-shadow-custom"
    max-width="240"
  >
    <div class="pa-4">
      <div class="rdatacategorycard--header d-flex mb-2">
        <span
          class="rdatacategorycard--title text-truncate"
          :title="name"
        >{{ name }}</span>
        <v-icon class="rdatacategorycard--icon ml-2">
          {{ DASHBOARD_TYPE_TO_ICON[DASHBOARD_TYPES.CATEGORY] }}
        </v-icon>
      </div>

      <div class="rdatacategorycard--body d-flex">
        <div class="flex-expand">
          <p class="rdatacategorycard--numproducts mb-1">
            {{ numProducts !== 1 ?
              $t('resources.productCount', { numProducts }) : '1' + $t('resources.product') }}
          </p>
          <p class="rdatacategorycard--numurls mb-0">
            {{ numUrls !== 1 ?
              $t('resources.urlCount', { numUrls }) : '1' + $t('resources.url') }}
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { DASHBOARD_TYPES, RESOURCE_TYPE_TO_ICON } from '@/utils/constants'

export default {
  name: 'RDataCategoryCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    numProducts: {
      type: Number,
      default: 0,
    },
    numUrls: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    DASHBOARD_TYPES,
    DASHBOARD_TYPE_TO_ICON: RESOURCE_TYPE_TO_ICON,
  }),
}
</script>

<style scoped>
.rdatacategorycard--title {
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
}
.rdatacategorycard--icon {
  flex: 0 0;
}
/deep/ .rdatacategorycard--footer .v-icon {
  margin-top: -2px;
}
.rdatacategorycard--body p {
  font-size: 12px;
}
.flex-expand {
  flex: 1;
}
</style>
