<template>
  <div class="rtabs content">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
      @change="onChange"
    >
      <div
        v-if="hasStartSlot"
        class="rtabs--start-slot"
      >
        <slot name="start" />
      </div>
      <v-tab
        v-for="item in items"
        :key="item.key"
        :data-testid="`tab-${item.label}`"
      >
        <v-icon
          v-if="item.icon"
          :class="{'icon-transformed': item.icon === 'mdi-tag', 'icon': item.icon !== 'mdi-tag'}"
          small
        >
          {{ item.icon }}
        </v-icon>
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <div class="rtabs--end-slot">
      <slot name="end" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'RTabs',
  props: {
    selectedTab: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: this.selectedTab,
    }
  },
  computed: {
    hasStartSlot() {
      return !!this.$slots.start
    },
  },
  watch: {
    selectedTab(newValue) {
      this.tab = newValue
    },
  },
  methods: {
    onChange(selectedTabIndex) {
      this.$emit('change', selectedTabIndex)
    },
  },
}
</script>

<style scoped>
.rtabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #9F9F9F;
}
.rtabs .v-tabs {
  border-bottom: 0;
}
.rtabs--start-slot {
  font-style: normal;
  font-weight: 700;
  font-size: var(--r-font-size);
  line-height: 20px;
  letter-spacing: -0.02px;
  color: var(--r-dark-grey);
}
.icon {
  padding-right: 5px;
}
.icon-transformed {
  transform: rotate(90deg);
  padding-top: 5px;
}

@media all and (max-width: 1300px) {
  .rtabs--start-slot {
    padding: 5px;
  }
}
</style>
