var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showModal ? _c('r-modal', {
    staticClass: "deleteconfirmation",
    attrs: {
      "title": _vm.customHeader ? _vm.customHeader : _vm.$t('deleteConfirmation.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "alternate-action-label": _vm.customAlternateActionLabel,
      "action-label": _vm.customActionLabel ? _vm.customActionLabel : _vm.$t('deleteConfirmation.delete'),
      "fetching": _vm.fetching === true ? true : false
    },
    on: {
      "submit": _vm.onDelete,
      "submit:alternate": _vm.onDeleteAlternate,
      "close": function () {
        return _vm.$emit('delete:cancel');
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('r-error-message', {
          staticClass: "mb-2",
          attrs: {
            "errors": [_vm.error]
          }
        }), _c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.customDescription ? _vm.customDescription : _vm.$t('deleteConfirmation.content')) + " ")])];
      },
      proxy: true
    }], null, false, 519784722)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }