const MAX_URL_LENGTH = 24

const abbrevUrl = (urlStr) => {
  const url = new URL(urlStr)
  const { hostname, pathname } = url
  const short = pathname === '/' ? hostname : `${hostname}${pathname}`
  if (short.length <= MAX_URL_LENGTH) {
    return short
  }

  const charsPerSide = Math.floor((MAX_URL_LENGTH - 1) / 2)
  const head = short.slice(0, charsPerSide)
  const tail = short.slice(-charsPerSide)
  return `${head}&hellip;${tail}`
}

export default abbrevUrl
