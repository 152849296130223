<template>
  <v-tab-item
    :key="label"
    class="rtabitem content"
  >
    <slot />
  </v-tab-item>
</template>

<script>
export default {
  name: 'RTabItem',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
