<template>
  <div class="pendingbcrlink">
    <v-alert
      type="info"
      dense
      class="px-2"
    >
      <span>{{ $t('bcrLinks.statuses.pending.title', { dataType: formattedDataType }) }}</span>
    </v-alert>

    <p>
      <span>{{ $t('bcrLinks.statuses.pending.message', { name }) }}</span>
    </p>

    <p>
      <span>
        {{ $t('bcrLinks.statuses.pending.postLinkMessage', { dataType: formattedDataType }) }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PendingBCRLink',
  props: {
    name: {
      type: String,
      required: true,
    },
    formattedDataType: {
      type: String,
      required: true,
    },
  },
}
</script>
