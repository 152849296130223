<template>
  <v-row class="mx-0 mt-0 rtablepagination">
    <v-col class="px-0">
      <v-row class="ma-0">
        <r-chip
          v-for="thisPageSize in availablePageSizes"
          :key="`rtablepagination--chip--${thisPageSize}`"
          :active="pageSize === thisPageSize"
          :label="`${thisPageSize}`"
          class="mr-2"
          :class="`rtablepagination--pagesize-${thisPageSize}`"
          circle
          light
          @click="onClickPageSize(thisPageSize)"
        />
      </v-row>
    </v-col>
    <v-col class="px-0">
      <r-pagination
        :page="currentPage"
        :total-pages="Math.ceil(totalRows / pageSize)"
        justify="end"
        :action-items="actionItems"
        @click:page="onClickPage"
        @open:pagination-action-item="onClickActionItem"
      />
    </v-col>
  </v-row>
</template>

<script>
import RChip from '@/components/library/atoms/RChip'
import RPagination from '@/components/library/molecules/RPagination'

export default {
  name: 'RTablePagination',
  components: {
    RChip,
    RPagination,
  },
  props: {
    pageSize: Number,
    totalRows: Number,
    currentPage: {
      type: Number,
      default: 1,
    },
    actionItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    const possiblePageSizes = Array.from(new Set([50, 100, 200, 400, this.pageSize]
      .sort((a, b) => a - b)))
    const availablePageSizes = possiblePageSizes.reduce((memo, size) => {
      if (size < this.$props.totalRows) {
        return memo.concat(size)
      }
      return memo
    }, [])

    return {
      availablePageSizes,
    }
  },
  methods: {
    onClickPageSize(value) {
      const { pageSize } = this.$props
      if (value !== pageSize) {
        this.$emit('click:page-size', { value })
      }
    },
    onClickPage(eventData) {
      const { currentPage } = this.$props
      const { value } = eventData
      if (value !== currentPage) {
        this.$emit('click:page', eventData)
      }
    },
    onClickActionItem(actionItem) {
      this.$emit('open:pagination-action-item', actionItem)
    },
  },
}
</script>
