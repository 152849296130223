<template>
  <r-select
    class="projectselect"
    :items="projectsItems"
    :allow-empty="false"
    :infinite="true"
    @change="onChange"
    @change:search="onSearchChange"
    @on:get-more="oneGetMoreItems"
  />
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ALL_DATA_PROJECT } from '@/utils/constants'

export default {
  name: 'ProjectSelect',
  components: {
    RSelect,
  },
  data: () => ({
    projectId: '',
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('projects', [
      'projects',
    ]),
    projectsItems() {
      const { projectId } = this.$data
      const allDataItem = {
        label: ALL_DATA_PROJECT.name,
        value: ALL_DATA_PROJECT.id,
        selected: ALL_DATA_PROJECT.id === projectId,
      }
      return [allDataItem].concat(this.projects.map(({ name, _id }) => ({
        label: name,
        value: _id,
        selected: _id === projectId,
      })))
    },
  },
  mounted() {
    // only to set initial value of projectId
    // according to the selected project in the header
    this.$data.projectId = this.selectedProjectId || ALL_DATA_PROJECT.id
  },
  methods: {
    ...mapActions('projects', [
      'fetchProjects',
    ]),
    onChange({ value }) {
      if (value === ALL_DATA_PROJECT.id) {
        this.$emit('change', { value: '' })
      } else {
        this.$emit('change', { value })
      }

      this.$data.projectId = value
    },
    oneGetMoreItems() {
      this.fetchProjects({ isInfinityScroll: true, search: this.search })
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchProjects({ search: value, page: 1 })
    },
  },
}
</script>
<style>
.selector-popup--items[data-v-d1673c80] {
  padding: 0 8px !important;
}
</style>
