<template>
  <div class="rswitch d-flex justify-start">
    <label
      v-if="label"
      class="pr-3"
    >{{ label }}</label>
    <v-switch
      v-model="newValue"
      :disabled="disabled"
      inset
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
  name: 'RSwitch',
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.$props.value,
    }
  },
  watch: {
    value(newValue) {
      this.newValue = newValue
    },
  },
  methods: {
    onChange() {
      if (this.disabled) {
        return
      }

      this.$emit('change', { value: this.newValue })
    },
  },
}
</script>

<style scoped>
/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-input--switch--inset .v-input--switch__track {
  height: 16px;
  left: -2px;
  top: calc(50% - 8px);
}

/deep/ .v-input--selection-controls__ripple {
  height: 28px;
  width: 28px;
  top: calc(50% - 21px) !important;
}

/deep/ .v-input--selection-controls__ripple:before {
  width: 28px;
  height: 28px;
}

/deep/ .v-input--switch__track.theme--light.primary--text {
  background: var(--primary-color);
  opacity: 1.0 !important;
}

/deep/ .v-input--selection-controls__ripple .primary--text {
  background: transparent;
}

/deep/ .v-input--switch__thumb {
  background: white;
}

/deep/ .theme--light.v-messages {
  display: none;
}

/deep/ .v-input__slot {
  margin-bottom: 0;
}

.rswitch > label {
  line-height: 22px;
}

</style>
