<template>
  <v-card
    class="rpopupmenu"
    :style="computedStyles"
  >
    <slot name="content" />
  </v-card>
</template>

<script>
import didNotClickOn from '@/utils/didNotClickOn'
import { RPOP_UP_TOP_MARGIN } from '@/utils/constants'

export default {
  name: 'RPopupMenu',
  props: {
    position: {
      x: {
        type: Number,
        required: true,
      },
      y: {
        type: Number,
        required: true,
      },
    },
    width: {
      type: Number,
      default: 290,
    },
    height: {
      type: Number,
      default: 150,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
    anchor: {
      type: String,
      default: 'top',
    },
  },
  computed: {
    computedTopMargin() {
      if (this.anchor === 'bottom') {
        return `${-this.height - 5}px`
      }

      return `${RPOP_UP_TOP_MARGIN}px`
    },
    computedLeftMargin() {
      if (this.marginLeft) {
        return `${this.marginLeft}px`
      }

      return `${-Math.floor((this.width - 10) / 2)}px`
    },
    computedStyles() {
      return {
        left: `${this.position.x}px`,
        top: `${this.position.y}px`,
        width: `${this.width}px`,
        height: `${this.height}px`,
        'margin-left': this.computedLeftMargin,
        'margin-top': this.computedTopMargin,
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.onClickPage)
  },
  beforeDestroy() {
    document.addEventListener('mousedown', this.onClickPage)
  },
  methods: {
    onClickPage(e) {
      if (didNotClickOn(e.target, { el: this.$el })) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>
.rpopupmenu {
  position: absolute;
  z-index: 2;
}
</style>
