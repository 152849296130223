<template>
  <div class="dataalertscards">
    <r-error-message
      v-if="fetchRecentAlertsError"
      :errors="[fetchRecentAlertsError]"
    />
    <v-row
      v-else-if="fetchingRecentAlerts"
      class="ma-0"
    >
      <v-col
        v-for="index in 4"
        :key="`dataalertscards--loading--${index}`"
        class="pa-0 dataalertscards--cell px-2"
      >
        <v-skeleton-loader
          class="elevation-0 dataalertscards--loader"
          type="list-item, list-item-avatar-three-line, card-heading"
          height="144"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="ma-0"
    >
      <v-col
        v-for="(alert, i) in recentAlerts"
        :key="`dataalertscards--item-key-${i}`"
        class="pa-0 dataalertscards--cell px-2"
        cols="3"
      >
        <r-alert-card
          class="dataalertscards--card"
          :type="alert.type"
          :date="alert.created"
          :data="alert.alertData"
          :alert-id="alert._id"
          :show-hide-icon="true"
        />
      </v-col>
    </v-row>
    <v-row v-if="noData">
      <v-col cols="12">
        <no-listing-results />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ALERT_TYPES } from '@/utils/constants'
import RAlertCard from '@/components/library/molecules/RAlertCard'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import NoListingResults from '@/components/app/data/NoListingResults'

export default {
  name: 'DataAlertsCards',
  components: {
    RAlertCard,
    RErrorMessage,
    NoListingResults,
  },
  data: () => ({
    ALERT_TYPES,
  }),
  computed: {
    ...mapState('alerts', [
      'fetchingRecentAlerts',
      'fetchRecentAlertsError',
      'recentAlerts',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    noData() {
      const { recentAlerts, fetchingRecentAlerts } = this
      return !fetchingRecentAlerts && !recentAlerts?.length
    },
  },
  watch: {
    selectedProjectId() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('alerts', [
      'fetchRecentAlerts',
    ]),
    fetchData() {
      const fetchParams = {
        projectId: this.selectedProjectId,
      }

      this.fetchRecentAlerts(fetchParams)
    },
  },
}
</script>

<style scoped>
.dataalertscards--cell:first-child {
  padding-left: 0 !important;
}
.dataalertscards--cell:last-child {
  padding-right: 0 !important;
}
@media all and (max-width: 1120px) {
  /deep/ .rsearchprogressalertcard, /deep/ .rnoncanonicalalertcard {
    max-width: 100% !important;
  }
}
</style>
