<template>
  <div class="deleteproducts">
    <r-modal
      v-if="!showFeedbackModal"
      class="deleteproducts--deletemodal"
      :title="labels.title"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="labels.actionLabel"
      :alternate-action-label="labels.alternateActionLabel"
      :fetching="deletingProducts"
      @submit="onDelete"
      @submit:alternate="onDeleteFromProject"
      @close="onClose"
    >
      <template v-slot:content>
        <div class="d-flex justify-center align-center">
          <label>{{ labels.content }}</label>
        </div>
      </template>
    </r-modal>

    <r-modal
      v-if="showFeedbackModal"
      class="deleteproducts--deletefeedbackmodal"
      :title="!deleteProductsError ? labels.title : labels.deleteErrorMessage"
      :action-label="$t('global.actions.confirm')"
      @submit="onCloseOfFeedbackModal"
    >
      <template v-slot:content>
        <div
          v-if="deleteProductsError"
          class="text-center"
        >
          {{ deleteProductsError }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ labels.deleteSuccess }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import RModal from '@/components/library/organisms/RModal.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { BATCH_OPERATIONS_MAP } from '@/utils/constants'

export default {
  name: 'DeleteProducts',
  components: {
    RModal,
  },
  props: {
    selectedProduct: {
      type: String,
      required: false,
    },
    productIds: {
      type: Array,
      required: true,
    },
    isAllProducts: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showFeedbackModal: false,
    deleteFromEverywhere: false,
    dataTypeCount: '',
  }),
  computed: {
    ...mapState('products', [
      'totalProducts',
      'deletingProducts',
      'deleteProductsError',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
    isOneProduct() {
      return this.productIds.length === 1
    },
    labels() {
      const { deleteFromEverywhere } = this.$data
      const dataTypeName = this.productIds.length === 1 ? 'product' : 'products'
      const dataTypeCount = this.productIds.length === 1 ? this.selectedProduct
        : this.productIds.length
      const deleteSuccess = deleteFromEverywhere
        ? this.$t('commonTable.batchActions.delete.deleteFromAllDataSuccessMessage', { dataTypeCount, dataTypeName })
        : this.$t('commonTable.batchActions.delete.deleteFromProjectSuccessMessage', { dataTypeCount, dataTypeName })

      if (this.selectedProjectId) {
        return {
          title: this.$t('commonTable.batchActions.delete.title'),
          content: this.$t('commonTable.batchActions.delete.descriptionProject', { dataTypeName }),
          actionLabel: this.$t('commonTable.batchActions.delete.deleteFromDataButton'),
          alternateActionLabel: this.$t('commonTable.batchActions.delete.deleteFromProjectButton'),
          deleteErrorMessage: this.$t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName }),
          deleteSuccess,
        }
      }
      return {
        title: this.$t('commonTable.batchActions.delete.title'),
        content: this.$t('commonTable.batchActions.delete.description', { dataTypeName }),
        actionLabel: this.$t('commonTable.batchActions.delete.deleteFromDataButton'),
        deleteErrorMessage: this.$t('commonTable.batchActions.delete.deleteErrorMessage', { dataTypeName }),
        deleteSuccess,
      }
    },
    confirmationMessage() {
      const { isAllProducts, totalProducts, productIds } = this
      const deletedProductCount = isAllProducts ? totalProducts : productIds.length

      return {
        deletedProductCount,
        resource: deletedProductCount > 1 ? 'Products' : 'Product',
      }
    },
  },
  mounted() {
    this.clearErrors({ deleteProductsError: '' })
  },
  methods: {
    ...mapActions('products', [
      'deleteProducts',
      'clearErrors',
    ]),
    async onDelete(projectId) {
      this.dataTypeCount = this.productIds.length
      if (this.productIds.length === 1) {
        this.dataTypeCount = this.selectedProduct?.name
      }
      this.$data.deleteFromEverywhere = !projectId
      this.clearErrors({ deleteProductsError: '' })
      let { productIds } = this
      let filters = {}

      if (this.isAllProducts) {
        filters = { ...filters, ...this.appliedFilters }
        productIds = null
      }

      await this.deleteProducts({
        op: BATCH_OPERATIONS_MAP.REMOVE, productIds, filters, projectId,
      })

      if (!this.deleteProductsError) {
        this.showFeedbackModal = true
      }
    },
    onClose() {
      this.$emit('delete:cancel')
    },
    onCloseOfFeedbackModal() {
      this.showFeedbackModal = false
      this.$emit('delete:success')
    },
    onDeleteFromProject() {
      this.onDelete(this.selectedProjectId)
    },
  },
}
</script>
