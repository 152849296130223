<template>
  <v-card
    class="rnewproductalertcard pa-0 pt-1 box-shadow-custom"
    :ripple="false"
    :rounded="false"
    max-width="240"
  >
    <div class="px-4 py-2">
      <div class="rnewproductalertcard--header d-flex py-1">
        <v-icon class="rnewproductalertcard--icon">
          mdi-new-box
        </v-icon>
        <span class="rnewproductalertcard--title text-truncate text-h6 ml-2">New products</span>
      </div>

      <div class="rnewproductalertcard--text d-flex">
        <span class="rsearchcard--text">{{ textContent }}</span>
      </div>

      <div class="rnewproductalertcard--link mt-2 d-flex">
        <a href="#">{{ $t('alerts.viewResults') }}</a>
      </div>
      <mark-as-seen
        v-if="showHideIcon"
        :show-hide-icon="showHideIcon"
        @on:mark-as-seen="onMarkAsSeen"
      />
      <v-divider class="my-3" />

      <div class="rnewproductalertcard--footer d-flex justify-space-between">
        <span class="date-title">Alert Date</span>
        <span class="date-content">{{ date }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import MarkAsSeen from '@/components/library/molecules/MarkAsSeen.vue'

export default {
  name: 'RNewProductAlertCard',
  components: { MarkAsSeen },
  props: {
    uploadName: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    numProduct: {
      type: Number,
      required: true,
    },
    alertId: {
      type: String,
    },
    showHideIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textContent() {
      const productLabel = this.numProduct > 1 ? 'products' : 'product'

      return `${this.numProduct} ${productLabel} launched in "${this.uploadName}" upload.`
    },
  },
  methods: {
    onMarkAsSeen() {
      const value = { alertId: this.alertId }
      this.$emit('on:mark-as-seen', value)
    },
  },
}
</script>

<style scoped>
.rnewproductalertcard--text {
  color: var(--r-carbon-color);
}
.rnewproductalertcard--link a {
  text-decoration: none;
}

.rnewproductalertcard--title {
  color: #1691CF;
}

.rnewproductalertcard--icon {
  font-size: 1.5rem !important;
  color: #1691CF;
}

</style>
